import { Colors } from "../design/colors";

export const UnsupportedBrowser = () => {
  return (
    <div
      style={{
        color: Colors.WHITE,
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: Colors.RED,
      }}
    >
      Your browser is unsupported as it does not support <a href="https://developer.mozilla.org/en-US/docs/Web/API/WebHID_API#browser_compatibility">WebHID</a>. <br />
      Please use Chrome, Edge, or a Chromium-derived browser.
    </div>
  );
};
