import React, { useState } from 'react'
import './ToggleButton.css' // Assuming you have the above CSS in a Switch.css file

interface ToggleButtonProps {
  value: number
  onChange: (newValue: number) => void
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({ value, onChange }) => {
  const [isActive, setSwitchActive] = useState(value)
  const toggleSwitch = (): void => {
    const newValue = 1 ^ isActive
    setSwitchActive(newValue)
    onChange(newValue)
  }

  return (
    <div className={`switch ${value === 1 ? 'active' : ''}`} onClick={toggleSwitch}>
      <span className="slider"></span>
    </div>
  )
}
