import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { InputMode, serializeSetUsbThrottleControlRequest } from "../../utils/common-types";
import { Button, HotkeyConfig, Intent, NumericInput, Slider, Spinner, useHotkeys } from "@blueprintjs/core";
import { Colors } from "../../design/colors";
import { throttle } from "../../utils/common";
import { useElementSize } from "../../hooks/useElementSize";
import { REFRESH_MS } from "../../hooks/usePlayback";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { hidSend } from "../../connections/hid";
import { useDevices } from "../../hooks/useDevices";

export interface IThrottleWidgetProps {
    close: () => void;
}

export const DeviceUnitsThrottle = ( props: IThrottleWidgetProps ) => {
    const { connectedDevice } = useDevices();
    const latestConfig = connectedDevice?.initialized ? connectedDevice.inputConfig : undefined;
    const latestConfigRef = useRef<typeof latestConfig>( latestConfig );
    const [ throttleValue, _setThrottleValue ] = useState<number | undefined>( latestConfig?.inputLimitCenter );
    const throttleValueRef = useRef<number | undefined>( latestConfig?.inputLimitCenter );
    const releasedValueRef = useRef<number | undefined>();
    const [ spinnerContainerRef, { width: spinnerContainerWidth, height: spinnerContainerHeight } ] = useElementSize();
    const [ manualValue, _setManualValue ] = useState<number>();
    const [ isCtrlKey, setIsCtrlKey ] = useState<boolean>( false );
    const pauseThrottle = useRef<boolean>( false );

    const setThrottleValue = useMemo( () => throttle( _setThrottleValue, REFRESH_MS ), [ _setThrottleValue ] );

    useEffect( () => {
        const onFocus = () => pauseThrottle.current = false;
        const onBlur = () => pauseThrottle.current = true;

        window.addEventListener( "focus", onFocus );
        window.addEventListener( "blur", onBlur );

        return () => {
            window.removeEventListener( "focus", onFocus );
            window.removeEventListener( "blur", onBlur );
        };
    }, [] );

    const hotkeys: HotkeyConfig[] = useMemo( () => {
        if ( latestConfig === undefined ) {
            return [];
        }

        const throttleStep = 1;

        return [
            {
                combo: "w",
                label: "Increase throttle",
                global: true,
                onKeyDown: throttle( () => {
                    releasedValueRef.current = undefined;
                    setThrottleValue( v => v === undefined ? v : Math.min( latestConfig.inputLimitUpper, v + throttleStep ) );
                }, 16 ),
                preventDefault: true,
                stopPropagation: true
            },
            {
                combo: "s",
                label: "Decrease throttle",
                global: true,
                onKeyDown: throttle( () => {
                    releasedValueRef.current = undefined;
                    setThrottleValue( v => v === undefined ? v : Math.max( latestConfig.inputLimitLower, v - throttleStep ) );
                }, 16 ),
                preventDefault: true,
                stopPropagation: true
            },
            {
                combo: "r",
                label: "Zero out throttle",
                global: true,
                onKeyDown: throttle( () => {
                    releasedValueRef.current = throttleValueRef.current;
                    setThrottleValue( latestConfig.inputLimitCenter );
                }, 16 ),
                preventDefault: true,
                stopPropagation: true
            },
        ];
    }, [ latestConfig, setThrottleValue ] );


    useHotkeys( hotkeys );

    useEffect( () => {
        throttleValueRef.current = throttleValue;
    }, [ throttleValue ] );

    const sendPulse = useCallback( () => {
        if ( connectedDevice === undefined || pauseThrottle.current ) {
            return;
        }

        if ( throttleValueRef.current !== undefined ) {
            hidSend( connectedDevice.handle, serializeSetUsbThrottleControlRequest( { value: throttleValueRef.current } ) );
        }
    }, [ connectedDevice ] );

    useEffect( () => {
        sendPulse();
        const interval = setInterval( sendPulse, 50 );
        return () => clearInterval( interval );
    }, [ sendPulse ] );

    useEffect( () => {
        const lastCenter = latestConfigRef.current?.inputLimitCenter;
        const newCenter = latestConfig?.inputLimitCenter;
        latestConfigRef.current = latestConfig;

        if ( lastCenter === newCenter ) {
            return;
        }

        setThrottleValue( newCenter );
    }, [ latestConfig, throttleValue, setThrottleValue ] );

    const onChange = useCallback( ( value: number ) => {
        releasedValueRef.current = undefined;
        setThrottleValue( value );
    }, [ setThrottleValue ] );

    const onRelease = useCallback( () => {
        if ( !isCtrlKey ) {
            releasedValueRef.current = throttleValueRef.current;
            setThrottleValue( latestConfigRef.current?.inputLimitCenter );
        }
    }, [ isCtrlKey, setThrottleValue ] );

    const setManualValue = useCallback( ( value: number, valueStr: string ) => {
        _setManualValue( valueStr === "" ? undefined : value );
    }, [] );

    const updateWithManualValue = useCallback( () => {
        if ( latestConfig === undefined || manualValue === undefined ) {
            return;
        }

        const { inputLimitLower, inputLimitUpper, inputLimitCenter } = latestConfig;

        const value = Math.max( Math.min( manualValue, inputLimitUpper ), inputLimitLower );

        setThrottleValue( value );
        _setManualValue( undefined );
        if ( value === inputLimitCenter ) {
            releasedValueRef.current = throttleValueRef.current;
        } else {
            releasedValueRef.current = undefined;
        }
    }, [ latestConfig, manualValue, setThrottleValue ] );

    if ( throttleValue === undefined || latestConfig === undefined || latestConfig.inputMode !== InputMode.USB ) {
        return null;
    }

    let spinnerValue: number;
    let spinnerIntent: Intent;
    let spinnerScale: string | undefined;
    if ( throttleValue < latestConfig.inputLimitCenter ) {
        const range = Math.max( latestConfig.inputLimitCenter - latestConfig.inputLimitLower, 1e-4 ); // Prevent divide by zero
        spinnerValue = ( latestConfig.inputLimitCenter - throttleValue ) / range;
        spinnerIntent = Intent.DANGER;
        spinnerScale = "-1 1";
    } else {
        const range = Math.max( latestConfig.inputLimitUpper - latestConfig.inputLimitCenter, 1e-4 ); // Prevent divide by zero
        spinnerValue = ( throttleValue - latestConfig.inputLimitCenter ) / range;
        spinnerIntent = Intent.SUCCESS;
    }

    if ( releasedValueRef.current !== undefined ) {
        if ( releasedValueRef.current < latestConfig.inputLimitCenter ) {
            spinnerIntent = Intent.DANGER;
            spinnerScale = "-1 1";
        } else {
            spinnerIntent = Intent.SUCCESS;
        }
    }

    const spinnerSize = Math.min( spinnerContainerHeight, spinnerContainerWidth ) * 0.9;

    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                position: "relative",
                display: "flex",
                alignItems: "center",
                color: Colors.WHITE,
                padding: 16
            }}
        >
            <div style={{ color: Colors.WHITE, position: "absolute", top: 0, left: 0 }}>Throttle</div>
            <FontAwesomeIcon
                icon={ faXmark }
                color={ Colors.WHITE }
                style={{ position: "absolute", top: 0, right: 0, padding: "0px 4px", cursor: "pointer" }}
                onClick={ props.close }
                size="lg"
            />
            <div style={{ height: "100%", display: "flex", alignItems: "center" }} onMouseDown={ e => setIsCtrlKey( e.ctrlKey ) }>
                <Slider
                    className="throttle-slider"
                    min={ latestConfig.inputLimitLower }
                    max={ latestConfig.inputLimitUpper }
                    value={ throttleValue }
                    onChange={ onChange }
                    labelValues={ [ latestConfig.inputLimitLower, latestConfig.inputLimitCenter, latestConfig.inputLimitUpper ] }
                    vertical={ true }
                    onRelease={ onRelease }
                    showTrackFill={ false }
                />
            </div>
            <div ref={ spinnerContainerRef } style={{ flex: 1, display: "flex", height: "100%", alignItems: "center", justifyContent: "center", position: "relative" }}>
                <Spinner value={ spinnerValue } intent={ spinnerIntent } size={ spinnerSize } style={{ transform: `rotate(180deg)`, scale: spinnerScale }} />
                <div style={{ position: "absolute" }}>
                    <NumericInput
                        placeholder={ throttleValue?.toString() }
                        min={ latestConfig.inputLimitLower }
                        max={ latestConfig.inputLimitUpper }
                        style={{ width: spinnerSize * 0.5, borderRadius: 8, backgroundColor: Colors.LIGHT_GREEN, color: Colors.WHITE }}
                        clampValueOnBlur={ true }
                        onValueChange={ setManualValue }
                        value={ manualValue === undefined ? "" : manualValue }
                        rightElement={ <Button icon="arrow-right" minimal={ true } intent="success" onClick={ updateWithManualValue } /> }
                        buttonPosition="none"
                        onKeyDown={ e => e.key === "Enter" ? updateWithManualValue() : undefined }
                    />
                </div>
            </div>
        </div>
    );
};
