// Pulled from https://usehooks-ts.com/react-hook/use-element-size
import { useCallback, useRef, useState } from "react";

import { useEventListener, useIsomorphicLayoutEffect } from "usehooks-ts";
import { nowMs } from "../utils/common";
import { REFRESH_MS } from "./usePlayback";

interface Size {
    width: number;
    height: number;
}

export const useElementSize = <T extends HTMLElement = HTMLDivElement>( throttleMs: number = REFRESH_MS ): [
    ( node: T | null ) => void,
    Size,
] => {
    const [ ref, setRef ] = useState<T | null>( null );
    const [ size, setSize ] = useState<Size>({
        width: 0,
        height: 0,
    });
    const throttleDebounceTimeout = useRef<NodeJS.Timeout>();
    const lastTime = useRef<number | undefined>();

    const handleSize = useCallback( () => setSize({
        width: ref?.offsetWidth || 0,
        height: ref?.offsetHeight || 0,
    }), [ ref?.offsetHeight, ref?.offsetWidth ] );

    const handleSizeChange = useCallback( () => {
        const curMs = nowMs();

         // Throttle resize events to ( 1000 / throttleMs ) per second
        if ( isDefined( lastTime.current ) && curMs - lastTime.current < throttleMs ) {
            // If no other events within throttleMs, use the most recent one
            clearTimeout( throttleDebounceTimeout.current );
            throttleDebounceTimeout.current = setTimeout( () => {
                handleSize();
                lastTime.current = curMs;
            }, 100 );

            return;
        }

        clearTimeout( throttleDebounceTimeout.current );
        handleSize();

        lastTime.current = curMs;
    }, [ throttleMs, handleSize ] );

    useEventListener( "resize", handleSizeChange );

    useIsomorphicLayoutEffect( () => {
        handleSize();
    }, [ ref?.offsetHeight, ref?.offsetWidth ] );

    return [ setRef, size ];
};
