import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IRealtimeDataMessage, PacketId, displayColors, fullDisplayUnits } from "../../utils/common-types";
import { camelCaseToReadable, throttle } from "../../utils/common";
import { packetThreadPoolApi } from "../../utils/packet-workers";
import { REFRESH_MS, TimeWindow, usePlayback } from "../../hooks/usePlayback";
import { usePlotHover } from "../../hooks/usePlotHover";
import { Colors } from "../../design/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { MessageKeySelect } from "../MessageSelect";
import { useElementSize } from "../../hooks/useElementSize";

export enum PlotKeyType {
    Packet,
    Computed,
}

export interface IPlotWidgetProps {
    addKey: ( packetKey: keyof IRealtimeDataMessage ) => void;
    removeKey: ( packetKey: keyof IRealtimeDataMessage ) => void;
}

export interface IPlotProps {
    packetKeys: ( keyof IRealtimeDataMessage )[];
}

export const Plot = ( props: IPlotProps & IPlotWidgetProps ) => {
    const { addKey, removeKey, packetKeys } = props;
    const canvasRef = useRef<HTMLCanvasElement>( null );
    const [ canvasContainerRef, { width, height } ] = useElementSize();
    const { timeWindow } = usePlayback( PacketId.REALTIME_DATA );
    const [ globalEnableHover, globalXHoverValue, setGlobalEnableHover, setGlobalXHoverValue ] = usePlotHover();
    const globalXHoverValueRef = useRef<typeof globalXHoverValue>( globalXHoverValue );
    const [ hoverValues, setHoverValues ] = useState<Map<string, number> | null>( null );

    useEffect( () => {
        globalXHoverValueRef.current = globalXHoverValue;
    }, [ globalXHoverValue ] );

    const _updateGraph = useCallback( async ( timeWindow: TimeWindow, xHoverValue: number | undefined ) => {
        if ( canvasRef.current === null ) {
            return;
        }

        const [ imageBitmap, hoverValues ] = await packetThreadPoolApi.renderBitmap( packetKeys, timeWindow, width, height, xHoverValue );

        if ( imageBitmap !== null ) {
            canvasRef.current?.getContext( "bitmaprenderer" )?.transferFromImageBitmap( imageBitmap );
        }

        setHoverValues( hoverValues );
    }, [ packetKeys, width, height ] );

    const updateGraph = useMemo( () => throttle( _updateGraph, REFRESH_MS, true ), [ _updateGraph ] );

    useEffect( () => {
        if ( timeWindow === undefined ) {
            return;
        }

        updateGraph( timeWindow, globalXHoverValue );
    }, [ updateGraph, timeWindow, globalXHoverValue ] );

    const _onMouseMove = useCallback( ( e: MouseEvent<HTMLCanvasElement> ) => {
        if ( !globalEnableHover ) {
            setGlobalXHoverValue( undefined );
        }

        const target = e.currentTarget;
        if ( target === null ) {
            return;
        }
        const rect = target.getBoundingClientRect();
        setGlobalXHoverValue( ( e.clientX - rect.left - 1 ) / rect.width );
    }, [ setGlobalXHoverValue, globalEnableHover ] );

    const onMouseMove = useMemo( () => throttle( _onMouseMove, REFRESH_MS ), [ _onMouseMove ] );

    const tags = useMemo( () => {
        const tagEls = packetKeys.map( ( k, i ) => {
            // if encountered, change the display name of the servoPulse key to "command"
            const displayName = ( k === 'servoPulse' ? 'Command' : camelCaseToReadable(k) );
            const units = fullDisplayUnits[ PacketId.REALTIME_DATA ][ k ];
            const hoverValue = hoverValues?.get( k );

            return (
                <div key={ `${ packetKeys.join( "" ) }${ k }-tag` } style={{ flex: 1, flexDirection: "column", minWidth: 0 }}>
                    <div
                        style={{ marginRight: i === packetKeys.length - 1 ? undefined : 4, backgroundColor: displayColors[ PacketId.REALTIME_DATA ][ k ], color: "#000000", display: "flex", flexDirection: "row", borderRadius: 4, alignItems: "center" }}

                    >
                        <span style={{ fontSize: 11, padding: "2px 6px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", flex: 1 }}>
                            { displayName }
                        </span>
                        <FontAwesomeIcon
                            icon={ faXmark }
                            style={{ padding: "0px 6px", cursor: "pointer" }}
                            color="#222222"
                            onClick={ () => removeKey( k ) }
                        />
                    </div>
                    <div style={{ color: Colors.WHITE, fontSize: 12 }}>
                        <span style={{ marginRight: units === "" ? 0 : 4 }}>
                            { fullDisplayUnits[ PacketId.REALTIME_DATA ][ k ] }
                        </span>
                        { Number.isInteger( hoverValue ) ? hoverValue : hoverValue?.toFixed( 2 ) }&nbsp;
                    </div>
                </div>
            );
        } );

        const excludedKeys: ( keyof IRealtimeDataMessage )[] = [ ...packetKeys, "controllerState", "faultCodeDeprecated" ];
        let addKeyButton = null;
        if ( excludedKeys.length < Object.keys( displayColors[ PacketId.REALTIME_DATA ] ).length ) {
            addKeyButton = (
                <MessageKeySelect
                    packetId={ PacketId.REALTIME_DATA }
                    onSelect={ addKey }
                    exclude={ excludedKeys }
                >
                    <FontAwesomeIcon
                        icon={ faPlus }
                        style={{ marginLeft: 8, cursor: "pointer" }}
                        color={ Colors.WHITE }
                    />
                </MessageKeySelect>
            );
        }

        return (
            <div style={{ display: "flex", width: "100%", alignItems: "flex-start" }}>
                { tagEls }
                { addKeyButton }
            </div>
        );
    }, [ packetKeys, hoverValues, removeKey, addKey ] );

    return (
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flex: "column" }}>
                {/* Drag and drop drag handle */}
                <FontAwesomeIcon
                    icon={ faGripVertical }
                    color={ Colors.WHITE }
                    style={{ padding: "0px 8px 0px 0px", cursor: "pointer" }}
                    size="lg"
                />
                { tags }
            </div>
            <div style={{ height: 4 }} />
            <div
                ref={ canvasContainerRef }
                style={{ flex: 1 }}
                onMouseEnter={ () => setGlobalEnableHover( true ) }
                onMouseLeave={ () => {
                    setGlobalEnableHover( false );
                    setGlobalXHoverValue( undefined );
                } }
            >
                <canvas
                    ref={ canvasRef }
                    style={{ height: "100%", width: "100%" }}
                    onMouseMove={ onMouseMove }
                />
            </div>
        </div>
    );
};
