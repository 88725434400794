// Import the functions you need from the SDKs you need
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { isProd } from "../utils/common";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig: FirebaseOptions = isProd() ?
{
    apiKey: "AIzaSyAESAnBsojlXBFhCC3w-K5AsdSY31Z-P2Y",
    authDomain: "salient-app-76a0e.firebaseapp.com",
    projectId: "salient-app-76a0e",
    storageBucket: "salient-app-76a0e.appspot.com",
    messagingSenderId: "1001283036555",
    appId: "1:1001283036555:web:3086358b5f7e891ad07b8e",
    measurementId: "G-K0369DTGFX"
}
:
{
    apiKey: "AIzaSyDlmdLJamcTz_tGI7jHL8_5VvIInjcm70A",
    authDomain: "salient-app-development.firebaseapp.com",
    projectId: "salient-app-development",
    storageBucket: "salient-app-development.appspot.com",
    messagingSenderId: "214297191498",
    appId: "1:214297191498:web:d7821e840db7ed1d51f2b3"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth( app );
export const firebaseStore = getFirestore( app );
export const analytics = getAnalytics(app);
