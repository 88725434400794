import React from "react";
import "./setup";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { Root } from "./routes/root/Root";
import { HotkeysProvider } from "@blueprintjs/core";
import { Home } from "./routes/root/home/Home";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <Navigate replace to="/" />,
        children: [
            {
                index: true,
                element: <Home />,
            }
        ]
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <HotkeysProvider>
            <RouterProvider router={ router } />
        </HotkeysProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
