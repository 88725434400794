import {
  DocumentData,
  QuerySnapshot,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { firebaseStore } from "../setup/firebase";
import {
  IStartParamDetectRequest,
  IStartParamDetectResponse,
  IWriteControlConfigRequest,
  IWriteInputConfigRequest,
  IWritePowerConfigRequest,
  IWriteSensorModeRequest,
  IWriteOpenLoopConfigRequest,
  IWritePositionControlSettingsRequest,
} from "./common-types";

export enum CollectionId {
  Users = "users",
  Devices = "devices",
  Configurations = "configurations",
}

export interface IMotorConfigurationDetails {
  name: string;
  motorManufacturer: string;
  motorModel: string;
  notes: string;
}

// NOTE: all of the optional fields below are only optional for backwards compatibility.
export type InputConfig = IWriteInputConfigRequest & {
  rpmStep?: number;
  ampsStep?: number;
  voltsStep?: number;
};

// NOTE: all of the optional fields below are only optional for backwards compatibility.
export type SensorsConfig = IWriteSensorModeRequest & IWriteOpenLoopConfigRequest & {
  accelerationRamp_mkradps2?: number; // mech. rad/s^2; replaces `accelerationRamp` (if this is present, accelerationRamp will be ignored)
};

// NOTE: all of the optional fields below are only optional for backwards
// compatibility. The same applies to the Partial<...>.
export type DatabaseRecord = {
  [CollectionId.Users]: {
    organizationName: string;
    updatedAt?: string;
  };
  [CollectionId.Configurations]: {
    userId: string;
    details: IMotorConfigurationDetails;
    parameters: Partial<IStartParamDetectRequest> & IStartParamDetectResponse;
    inputConfig: InputConfig;
    powerConfig: IWritePowerConfigRequest;
    controlConfig: IWriteControlConfigRequest;
    positionControlSettings: IWritePositionControlSettingsRequest;
    sensorsConfig?: SensorsConfig;
    updatedAt?: string;
  };
  [CollectionId.Devices]: {
    firmwareVersion: string;
    activeConfigurationId: string;
    updatedAt?: string;
  };
};

// updatedAt is a new field added to all created or updated documents going
// forward. It contains the date in ISO 8601 format.
const updatedAtObj = (): { updatedAt: string } => {
  return {
    updatedAt: ( new Date() ).toISOString(),
  };
};

export const setRecord = async <I extends CollectionId>(
  collectionId: I,
  recordId: string,
  value: DatabaseRecord[I]
) => {
  value = {
    ...value,
    ...updatedAtObj(),
  };
  return await setDoc(doc(firebaseStore, collectionId, recordId), value, {
    merge: true,
  });
};

export const addRecord = async <I extends CollectionId>(
  collectionId: I,
  value: DatabaseRecord[I]
): Promise<string> => {
  value = {
    ...value,
    ...updatedAtObj(),
  };
  const record = await addDoc(collection(firebaseStore, collectionId), value);
  return record.id;
};

export const updateRecord = async <I extends CollectionId>(
  collectionId: I,
  recordId: string,
  value: DatabaseRecord[I]
) => {
  value = {
    ...value,
    ...updatedAtObj(),
  };
  return await updateDoc(doc(firebaseStore, collectionId, recordId), value);
};

export const updateRecordFields = async <I extends CollectionId>(
  collectionId: I,
  recordId: string,
  fields: Partial<DatabaseRecord[I]>
) => {
  const updatedFields = {
    ...fields,
    ...updatedAtObj(),
  };
  const docRef = doc(firebaseStore, collectionId, recordId);
  return await updateDoc(docRef, updatedFields);
};

export const deleteRecord = async (
  collectionId: CollectionId,
  recordId: string
) => {
  return await deleteDoc(doc(firebaseStore, collectionId, recordId));
};

export const getRecord = async <I extends CollectionId>(
  collectionId: I,
  recordId: string
): Promise<DatabaseRecord[I] | undefined> => {
  const snapshot = await getDoc(doc(firebaseStore, collectionId, recordId));
  return snapshot.data() as DatabaseRecord[I] | undefined;
};

export const getRecords = async <I extends CollectionId>(
  userId: string,
  collectionId: I
): Promise<QuerySnapshot<DocumentData>> => {
  const q = query(
    collection(firebaseStore, collectionId),
    where("userId", "==", userId)
  );
  return await getDocs(q);
};
