import React from "react";
import {
  type DragEndEvent,
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensors,
  useSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";

import type { Widget } from "../WidgetPanel";

type Props = {
  widgets: Widget[];
  setWidgets: (fn: (widgets: Widget[]) => Widget[]) => void;
  children?: React.ReactNode;
};

export const SortableWidgetsContext: React.FC<Props> = ({
  widgets,
  setWidgets,
  children,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // update the widgets array when a drag ends
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setWidgets((ws) => {
        const oldIndex = ws.findIndex(
          (w) => w === widgets[active.id as number]
        );
        const newIndex = ws.findIndex(
          (w) => w === widgets[(over?.id || active.id) as number]
        );
        return arrayMove(ws, oldIndex, newIndex);
      });
    }
  };

  // sortable context needs an array of unique identifiers - use the index
  const widgetIdentifiers = widgets.map((_, i) => ({
    id: i.toString(),
  }));

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={widgetIdentifiers} strategy={rectSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};
