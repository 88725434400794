import { useCallback, useState } from "react";
import { Colors } from "../design/colors";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export const ClickableIcon = ( props: {
    icon: FontAwesomeIconProps[ "icon" ];
    disabled?: boolean;
    onClick?: () => void;
    size?: FontAwesomeIconProps[ "size" ];
    style?: React.CSSProperties;
    color?: string;
    label?: string;
    title?: string;
} ) => {
    const [ hovering, setHovering ] = useState( false );

    const onMouseOver = useCallback( () => setHovering( true ), [ setHovering ] );
    const onMouseLeave = useCallback( () => setHovering( false ), [ setHovering ] );

    const color = hovering ? Colors.WHITE : props.color;

    let labelEl;
    if ( props.label !== undefined ) {
        labelEl = (
            <span style={{ color, marginTop: 8 }}>
                { props.label }
            </span>
        );
    }

    return (
        <div
            style={{
                ...props.style,
                display: "flex",
                flexDirection: "column",
                cursor: props.disabled ? "not-allowed" : "pointer",
                opacity: props.disabled ? 0.25 : hovering ? 1 : 0.75
            }}
        >
            <FontAwesomeIcon
                icon={ props.icon }
                onClick={ props.disabled ? undefined : props.onClick }
                title={ props.title }
                size={ props.size }
                onMouseOver={ onMouseOver }
                onMouseLeave={ onMouseLeave }
                color={ props.color }
            />
            { labelEl }
        </div>
    );
};