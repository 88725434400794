import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

type Props = {
  id: string | number;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

export const SortableWidgetsItem: React.FC<Props> = ({
  id,
  children,
  style = {},
}) => {
  const [grabbingState, setGrabbingState] = useState<"grab" | "grabbing">(
    "grab"
  );
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const sortableItemStyle: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transform ? transition : undefined,
    position: "relative",
    ...style,
  };

  return (
    <div ref={setNodeRef} style={sortableItemStyle}>
      {/* Drag handle target area. UI for the handle can be found on the throttle, plot, and gauge widgets directly */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "8%",
          height: "14%",
          zIndex: 10,
          outline: "none",
          cursor: grabbingState,
        }}
        {...attributes}
        {...listeners}
        onMouseUp={() => setGrabbingState("grab")}
        onMouseDown={() => setGrabbingState("grabbing")}
      />
      {children}
    </div>
  );
};
