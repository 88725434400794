import { IReceivedPacketTypes, deserialize, inputReportID } from "../utils/common-types";

const HID_REQUEST_DEFAULT_TIMEOUT_MS = 3000;

export const hidSend = async ( device: HIDDevice, buffer: BufferSource ) => {
    await device.sendReport( 0, buffer );
}

export const hidRequest = <I extends keyof IReceivedPacketTypes>( device: HIDDevice, packetId: I, request: BufferSource, timeout?: number ): Promise<IReceivedPacketTypes[ I ]> => {
    return new Promise( ( resolve, reject ) => {
        const timeoutFn = setTimeout( () => {
            device.removeEventListener( "inputreport", listener );
            reject( `[ ${ packetId } ]: Device request timeout` );
        }, timeout ?? HID_REQUEST_DEFAULT_TIMEOUT_MS );
        
        const listener = ( event: HIDInputReportEvent ) => {
            const id = inputReportID( event.data );
            if ( id === packetId ) {
                device.removeEventListener( "inputreport", listener );
                clearTimeout( timeoutFn );
                resolve( deserialize( event.data ) as IReceivedPacketTypes[ I ] );
            }
        };

        device.addEventListener( "inputreport", listener );
        hidSend( device, request ).catch(e => {
            console.warn("hidRequest exception from hidSend:", e);
            device.removeEventListener( "inputreport", listener );
            clearTimeout( timeoutFn );
            reject( e );
        })
    } );
};

// Like hidRequest except there is more than one response. The `timeouts` array
// specifies the timeouts (in milliseconds) for each packet. This is finished
// when either `timeouts.length` packets have been received, or a timeout
// occurs, whichever comes first. The exception type in case of a timeout is
// `HIDRequestMultiTimeout`.
//
// The return value is an array of promises that resolve to the received
// packets. There is one promise per timeout.
export const hidRequestMulti =
    <I extends keyof IReceivedPacketTypes>(
        device: HIDDevice,
        packetId: I,
        request: BufferSource,
        timeouts: number[],
): Promise<IReceivedPacketTypes[ I ]>[] => {

    let current = 0;
    let resolvers: ( ( packet: IReceivedPacketTypes[ I ] ) => void )[] = [];
    let rejecters: ( ( err: unknown ) => void )[] = [];
    let currentTimeout: NodeJS.Timeout | undefined;

    const promises = timeouts.map( () =>
        new Promise( ( resolve, reject ) => {
            resolvers.push( resolve );
            rejecters.push( reject );
        } ) );

    const rejectRemaining = ( err: unknown ) => {
        for ( let i = current; i < timeouts.length; i++ ) {
            rejecters[ i ]( err );
        }
        device.removeEventListener( "inputreport", listener );
    };

    const doTimeout = () => {
        rejectRemaining( new HIDRequestMultiTimeout( `Timeout for packet index ${ current }` ) );
    };

    const resolveOne = ( packet: IReceivedPacketTypes[ I ] ) => {
        clearTimeout( currentTimeout );
        const currentResolver = resolvers[ current ];
        current++;
        if ( current < timeouts.length ) {
            currentTimeout = setTimeout( doTimeout, timeouts[ current ] );
        } else {
            // Done
            device.removeEventListener( "inputreport", listener );
        }
        currentResolver( packet );
    };

    const listener = ( event: HIDInputReportEvent ) => {
        let response;
        try {
            const id = inputReportID( event.data );
            if ( id !== packetId ) {
                return;
            }
            
            response = deserialize( event.data ) as IReceivedPacketTypes[ I ];
        } catch ( e ) {
            clearTimeout( currentTimeout );
            rejectRemaining( e );
            return;
        }
        resolveOne( response );
    };

    device.addEventListener( "inputreport", listener );
    currentTimeout = setTimeout( doTimeout, timeouts[ current ] );
    hidSend( device, request );

    return promises as Promise<IReceivedPacketTypes[ I ]>[];
}

export class HIDRequestMultiTimeout extends Error { }
