import { useCallback, useEffect, useRef, useState } from "react";
import { PacketId } from "../../utils/common-types";
import { Colors } from "../../design/colors";
import { Property } from "csstype";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { throttle } from "../../utils/common";
import { MIN_TIME_WINDOW_SIZE, PlaybackMode, REFRESH_MS, TimeWindow, usePlayback } from "../../hooks/usePlayback";
import { usePacketMetadata } from "../../hooks/usePacketMetadata";
import { ConnectionType, useConnection } from "../../connections/connection";
import { useDevices } from "../../hooks/useDevices";

const RESIZE_GRAB_SIZE = 16;

enum CursorPosition {
    Left,
    Middle,
    Right,
}

interface IGrabPosition {
    sliderBarCursorPosition: CursorPosition;
    valueDistanceFromSliderLeft: number;
}

type Min = number;
type Max = number;

type ValueRange = [ Min, Max ];

const mousePositionXToSliderValue = ( sliderValueRange: ValueRange, sliderPixelRange: ValueRange, positionX: number ) => {
    const [ pixelMin, pixelMax ] = sliderPixelRange;
    let posX = Math.min( Math.max( pixelMin, positionX ), pixelMax );

    const pixelRangePosition = ( posX - pixelMin ) / ( pixelMax - pixelMin );

    const [ valueMin, valueMax ] = sliderValueRange;
    return valueMin + ( valueMax - valueMin ) * pixelRangePosition;
};

const WindowSlider = ( props: {
    valueRange: ValueRange;
    sliderWindow: ValueRange;
    onChange: ( window: ValueRange ) => void;
    minSliderWindowSize?: number;
    onStickToEnd?: () => void;
    isStuckToEnd?: boolean;
    onUnstickFromEnd?: () => void;
} ) => {
    const sliderBarRef = useRef<HTMLDivElement>( null );
    const [ progressBarDiv, setProgressBarDiv ] = useState<HTMLDivElement | null>( null );
    const [ cursorPos, setCursorPos ] = useState<CursorPosition>();
    const [ grabPos, setGrabPos ] = useState<IGrabPosition>();
    useWindowDimensions(); // Forces re-render when browser window size changes
    const progressBarWidth = progressBarDiv?.getBoundingClientRect().width || 1;
    const {
        onChange,
        onStickToEnd,
        isStuckToEnd,
        onUnstickFromEnd,
        sliderWindow: [ sliderLeftValue, sliderRightValue ],
        valueRange: [ valueMin, valueMax ],
        minSliderWindowSize,
    } = props;

    const shouldStickToEnd = useCallback( ( value: number ) => {
        if ( !isDefined( onStickToEnd ) ) {
            return false;
        }

        return ( valueMax - value ) < ( valueMax - valueMin ) * 0.005;
    }, [ valueMin, valueMax, onStickToEnd ] );

    const handleMouseMove = useCallback( ( e: MouseEvent ) => {
        const progressBarRect = progressBarDiv?.getBoundingClientRect();
        const sliderRect = sliderBarRef.current?.getBoundingClientRect();
        if ( !( isDefined( progressBarRect ) && isDefined( sliderRect ) ) ) {
            return;
        }

        const mousePositionValue = mousePositionXToSliderValue( [ valueMin, valueMax ], [ progressBarRect.left, progressBarRect.right ], e.clientX );
        const mouseDistanceFromLeft = e.clientX - sliderRect.left;
        const mouseDistanceFromRight = e.clientX - sliderRect.right;

        switch ( grabPos?.sliderBarCursorPosition ) {
            case CursorPosition.Left: {
                if ( mousePositionValue !== sliderLeftValue ) {
                    let newLeft = mousePositionValue;
                    if ( isDefined( minSliderWindowSize ) ) {
                        newLeft = Math.max( Math.min( newLeft, sliderRightValue - minSliderWindowSize ), valueMin );
                    }

                    onChange( [ newLeft, sliderRightValue ] );
                }
                break;
            }
            case CursorPosition.Right: {
                if ( mousePositionValue !== sliderRightValue ) {
                    let newRight = mousePositionValue;
                    if ( isDefined( minSliderWindowSize ) ) {
                        newRight = Math.min( Math.max( newRight, sliderLeftValue + minSliderWindowSize ), valueMax );
                    }

                    const stickToEnd = shouldStickToEnd( newRight );

                    if ( stickToEnd ) {
                        newRight = valueMax;
                        if ( !isStuckToEnd ) {
                            onStickToEnd?.();
                        }
                    } else if ( isStuckToEnd ) {
                        onUnstickFromEnd?.();
                    }

                    onChange( [ sliderLeftValue, newRight ] );
                }
                break;
            }
            case CursorPosition.Middle: {
                const sliderValueSpread = sliderRightValue - sliderLeftValue;

                let newLeft = Math.min( Math.max( valueMin, mousePositionValue - grabPos.valueDistanceFromSliderLeft ), valueMax - sliderValueSpread );
                let newRight = newLeft + sliderValueSpread;

                const stickToEnd = shouldStickToEnd( newRight );

                if ( stickToEnd ) {
                    newRight = valueMax;
                    newLeft = valueMax - sliderValueSpread;
                    if ( !isStuckToEnd ) {
                        onStickToEnd?.();
                    }
                } else if ( isStuckToEnd ) {
                    onUnstickFromEnd?.();
                }

                if ( newLeft !== sliderLeftValue || newRight !== sliderRightValue ) {
                    onChange( [ newLeft, newRight ] );
                }
                break;
            }
        }

        if ( e.clientY < sliderRect.top || e.clientY > sliderRect.bottom || mouseDistanceFromLeft < -RESIZE_GRAB_SIZE || mouseDistanceFromRight > RESIZE_GRAB_SIZE ) {
            setCursorPos( undefined );
            return;
        }

        if ( mouseDistanceFromLeft < 0 && mouseDistanceFromLeft >= -RESIZE_GRAB_SIZE ) {
            setCursorPos( CursorPosition.Left );
        } else if ( mouseDistanceFromRight > 0 && mouseDistanceFromRight <= RESIZE_GRAB_SIZE ) {
            setCursorPos( CursorPosition.Right );
        } else {
            setCursorPos( CursorPosition.Middle );
        }
    }, [ setCursorPos, grabPos, onChange, minSliderWindowSize, sliderLeftValue, sliderRightValue, valueMin, valueMax, shouldStickToEnd, isStuckToEnd, onStickToEnd, onUnstickFromEnd, progressBarDiv ] );

    const handleMouseDown = useCallback( ( e: MouseEvent ) => {
        const progressBarRect = progressBarDiv?.getBoundingClientRect();
        if ( !isDefined( progressBarRect ) ) {
            return;
        }

        if ( isDefined( cursorPos ) ) {
            e.preventDefault();
            setGrabPos( {
                sliderBarCursorPosition: cursorPos,
                valueDistanceFromSliderLeft: mousePositionXToSliderValue( [ valueMin, valueMax ], [ progressBarRect.left, progressBarRect.right ], e.clientX ) - sliderLeftValue,
            } );
        }
    }, [ setGrabPos, cursorPos, sliderLeftValue, valueMin, valueMax, progressBarDiv ] );

    const handleMouseUp = useCallback( ( e: MouseEvent ) => {
        setCursorPos( undefined );
        setGrabPos( undefined );
    }, [ setGrabPos ] );

    useEffect( () => {
        const _handleMouseMove = throttle( handleMouseMove, REFRESH_MS );
        window.addEventListener( "mousemove", _handleMouseMove );
        window.addEventListener( "mousedown", handleMouseDown );
        window.addEventListener( "mouseup", handleMouseUp );
        window.addEventListener( "mouseleave", handleMouseUp );
        return () => {
            window.removeEventListener( "mousemove", _handleMouseMove );
            window.removeEventListener( "mousedown", handleMouseDown );
            window.removeEventListener( "mouseup", handleMouseUp );
            window.removeEventListener( "mouseleave", handleMouseUp );
        }
    }, [ handleMouseMove, handleMouseDown, handleMouseUp ] );

    let cursor: Property.Cursor | undefined;
    switch ( grabPos?.sliderBarCursorPosition ) {
        case CursorPosition.Left: cursor = "col-resize"; break;
        case CursorPosition.Middle: cursor = "grabbing"; break;
        case CursorPosition.Right: cursor = "col-resize"; break;
        default: switch ( cursorPos ) {
            case CursorPosition.Left: cursor = "col-resize"; break;
            case CursorPosition.Middle: cursor = "grab"; break;
            case CursorPosition.Right: cursor = "col-resize"; break;
            default: cursor = undefined; break;
        }
    }

    useEffect( () => {
        document.getElementsByTagName( "body" )[ 0 ].style.cursor = cursor || "";
    }, [ cursor ] );

    if ( valueMax - valueMin <= 0 ) {
        return null;
    }

    let sliderRightDistanceFromEnd = ( valueMax - sliderRightValue ) / ( valueMax - valueMin ) * progressBarWidth;
    if ( isStuckToEnd ) {
        sliderRightDistanceFromEnd = 0;
    }

    const sliderBarPercent = ( sliderRightValue - sliderLeftValue ) / ( valueMax - valueMin );
    const sliderBarWidth = Math.min( sliderBarPercent * progressBarWidth, progressBarWidth - sliderRightDistanceFromEnd );

    return (
        <div
            style={{ display: "flex", width: "100%", alignItems: "center", position: "relative", cursor }}
        >
            <div
                ref={ sliderBarRef }
                style={{
                    position: "absolute",
                    right: Math.max( sliderRightDistanceFromEnd, 0 ),
                    width: Math.min( sliderBarWidth, progressBarDiv?.getBoundingClientRect()?.width || sliderBarWidth ),
                    height: 16,
                    backgroundColor: "rgba( 255, 255, 255, 0.75 )",
                    borderRadius: 5,
                }}
            />
            <div ref={ setProgressBarDiv } style={{ flex: 1, height: 8, backgroundColor: Colors.LIGHT_GREEN }} />
        </div>
    );
};

export const PlaybackSlider = ( props: {
    packetId: PacketId;
} ) => {
    const { connection } = useConnection();
    const { connectedDevice } = useDevices();
    const { timeWindow, setTimeWindow, playbackMode, pause, live } = usePlayback( props.packetId );

    const packetTimeWindow = usePacketMetadata().timeWindow;

    const onChange = useCallback( ( newTimeWindow: TimeWindow ) => {
        if ( playbackMode === PlaybackMode.Play ) {
            pause();
        }

        setTimeWindow( newTimeWindow );
    }, [ playbackMode, pause, setTimeWindow ] );

    const isStream = connection?.connectionType === ConnectionType.HID || connection?.stream || connectedDevice !== undefined;

    if ( !isDefined( packetTimeWindow ) || !isDefined( timeWindow ) || ( timeWindow[ 1 ] - timeWindow[ 0 ] > packetTimeWindow[ 1 ] - packetTimeWindow[ 0 ] ) ) {
        return null;
    }

    return (
        <WindowSlider
            valueRange={ packetTimeWindow }
            sliderWindow={ timeWindow }
            onChange={ onChange }
            minSliderWindowSize={ MIN_TIME_WINDOW_SIZE }
            isStuckToEnd={ isStream && playbackMode === PlaybackMode.Live }
            onStickToEnd={ isStream ? live : undefined }
            onUnstickFromEnd={ isStream ? pause : undefined }
        />
    );
};
