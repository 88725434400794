import { User, onAuthStateChanged } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import { firebaseAuth } from "../setup/firebase";

type UserObject = { value: User | null | undefined };

export const useAuth = () => {
    const [ userObject, setUserObject ] = useState<UserObject>( { value: undefined } );

    const reload = useCallback( async () => {
        await userObject.value?.reload();
        setUserObject( { ...userObject } );
    }, [ userObject ] );

    useEffect( () => {
        return onAuthStateChanged( firebaseAuth, user => setUserObject( { value: user } ) );
    }, [] );

    return {
        initialized: userObject.value !== undefined,
        authenticated: userObject.value !== null,
        user: userObject.value,
        reload,
    }
};