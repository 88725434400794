import { useCallback, useEffect } from "react";
import { packetThreadPoolApi } from "../utils/packet-workers";
import { clearPlaybackState } from "./usePlayback";
import { useConnection } from "../connections/connection";
import { useDevices } from "./useDevices";

const callbacks: ( () => ( Promise<void> | void ) )[] = [];

export const useReset = ( callback?: () => ( Promise<void> | void ) ): () => Promise<void> => {
    const { disconnect } = useConnection();
    const { disconnect: disconnectHID } = useDevices();

    const reset = useCallback( async () => {
        const promises = [
            disconnect(),
            disconnectHID(),
            packetThreadPoolApi.clear(),
        ];

        callbacks.forEach( cb => {
            const val = cb();
            if ( val instanceof Promise ) {
                promises.push( val );
            }
        } );

        await Promise.all( promises );
        clearPlaybackState();
    }, [ disconnect, disconnectHID ] );

    useEffect( () => {
        if ( callback === undefined ) {
            return;
        }

        callbacks.push( callback );

        return () => {
            const index = callbacks.indexOf( callback );

            if ( index === -1 ) {
                return;
            }

            callbacks.splice( index, 1 );
        };
    }, [ callback ] );

    return reset;
};
