import { useCallback, useEffect, useState } from "react";
import { PacketId, deserialize, inputReportID } from "../utils/common-types";
import { packetThreadPoolApi } from "../utils/packet-workers";
import { IPacket } from "../utils/packet.worker";
import { SALIENT_VENDOR_ID } from "../hooks/useDevices";

export enum ConnectionType {
    HID,
    HIDPlayback,
}

type ConnectionOptions = {
    connectionType: ConnectionType.HID;
    device: HIDDevice;
} | {
    connectionType: ConnectionType.HIDPlayback;
    file: File;
    stream?: boolean;
};

type Connection = {
    connectionType: ConnectionType.HID;
    name: string;
    device: HIDDevice;
    disconnect: () => Promise<void>;
} | {
    connectionType: ConnectionType.HIDPlayback;
    name: string;
    stream: boolean;
}

let playbackStreamInterval: NodeJS.Timer | undefined;

const stopPlaybackStream = () => {
    clearInterval( playbackStreamInterval );
    playbackStreamInterval = undefined;
};

let _connection: Connection | undefined;
const _connectionListeners: ( () => void )[] = [];

const _notify = () => _connectionListeners.forEach( l => l() );

const _disconnect = async () => {
    switch ( _connection?.connectionType ) {
        case ConnectionType.HID: {
            await _connection.disconnect();
            break;
        }
        case ConnectionType.HIDPlayback:
            stopPlaybackStream();
            break;
        case undefined: break;
    }

    _connection = undefined;
};

const _connect = async ( connectionOptions: ConnectionOptions ): Promise<( typeof _connection ) | undefined> => {
    switch ( connectionOptions.connectionType ) {
        case ConnectionType.HID: {
            // ToDo: verify this entire case is dead code, and remove it
            const { device } = connectionOptions;
            if ( device.vendorId !== SALIENT_VENDOR_ID ) {
                return undefined;
            }

            const onInputReport = ( event: HIDInputReportEvent ) => {
                packetThreadPoolApi.handlePackets([{
                    id: inputReportID( event.data ),
                    packet: deserialize( event.data ),
                    timestampMs: event.timeStamp,
                }])
            };

            device.addEventListener( "inputreport", onInputReport );

            return {
                connectionType: ConnectionType.HID,
                name: device.productName,
                device,
                disconnect: async () => {
                    device.removeEventListener( "inputreport", onInputReport );
                    await device.close();
                },
            };
        }
        case ConnectionType.HIDPlayback: {
            if ( connectionOptions.stream ) {
                const packets = ( JSON.parse( await connectionOptions.file.text() ) as IPacket<PacketId>[] ).filter( p => p.id === PacketId.REALTIME_DATA );

                let timestampSum = 0;
                for ( let i = 1; i < packets.length; i++ ) {
                    timestampSum += packets[ i ].timestampMs - packets[ i - 1 ].timestampMs;
                }
                const averageRate = Math.round( timestampSum / ( packets.length - 1 ) );

                stopPlaybackStream();
                let i = 0;
                playbackStreamInterval = setInterval( () => {
                    if ( i >= packets.length ) {
                        clearInterval( playbackStreamInterval );
                        return;
                    }

                    const packet = packets[ i ];
                    packetThreadPoolApi.handlePackets( [ packet ] );
                    i++;
                }, averageRate );
            } else {
                await packetThreadPoolApi.handlePackets( JSON.parse( await connectionOptions.file.text() ) );
            }

            return {
                connectionType: ConnectionType.HIDPlayback,
                name: connectionOptions.file.name,
                stream: !!connectionOptions.stream,
            }
        }
    }
};

export const useConnection = () => {
    const [ connection, setConnection ] = useState<typeof _connection>( _connection );

    useEffect( () => {
        const onConnectionChange = () => setConnection( _connection );
        _connectionListeners.push( onConnectionChange );

        return () => {
            const index = _connectionListeners.indexOf( onConnectionChange );
            if ( index === -1 ) {
                return;
            }

            _connectionListeners.splice( index, 1 );
        };
    }, [] );

    const connect = useCallback( async ( options: ConnectionOptions ) => {
        _connection = await _connect( options );
        _notify();
        return _connection;
    }, [] );

    const disconnect = useCallback( async () => {
        await _disconnect();
        _notify();
    }, [] );

    return {
        connection,
        connect,
        disconnect,
    }
};
