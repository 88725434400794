import { useCallback, useEffect, useState } from "react";
import {
    ControlMode,
    InputMode,
    PositionResolution,
    serializeSetUsbThrottleControlRequest,
    serializeWriteInputConfigRequest,
} from "../../utils/common-types";
import { Button as BPButton, NumericInput, Slider } from "@blueprintjs/core";
import { Button } from '../../components/Button'
import { Colors } from "../../design/colors";
import {
  LabelRendererFn,
  getThrottleLabelRenderer,
} from "../../utils/throttle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faXmark } from "@fortawesome/free-solid-svg-icons";
import { hidSend } from "../../connections/hid";
import { useDevices } from "../../hooks/useDevices";
import {
    useThrottleConfig,
} from "../../hooks/useThrottleConfig";
import { useMotorConfigurations } from "../../hooks/useMotorConfigurations";
import { packetThreadPoolApi } from "../../utils/packet-workers";
import {
    CollectionId,
    updateRecordFields,
} from "../../utils/firestore";

const PULSE_INTERVAL_MS = 50;
export interface IThrottleWidgetProps {
    close: () => void;
}

// Not visible unless input mode is USB
export const Position = ( props: IThrottleWidgetProps ) => {
    const { close } = props;
    const { connectedDevice } = useDevices();
    const { throttleConfig } = useThrottleConfig();
    const { fetchConfigurations, configurations } = useMotorConfigurations(); // ToDo: after new protocol, can remove this hook
    const latestConfig = connectedDevice?.initialized ? connectedDevice.inputConfig : undefined;
    const activeConfigurationId = connectedDevice?.initialized ? connectedDevice.activeConfigurationId : undefined;
    const [ manualValueStr, _setManualValueStr ] = useState<string>("");                        // Physical units
    const [positionValue, _setPositionValue] = useState<number>(0);
    const [positionSensorValue, setPositionSensorValue] = useState<number>(0);
    const [ homePosition, setHomePosition ] = useState<number>(0);
    const [ isPositionValueSet, setIsPositionValueSet ] = useState<boolean>(false);
    const selectedConfiguration = configurations.find( c => c.id === activeConfigurationId );
    const positionResolution = selectedConfiguration?.data.positionControlSettings.positionResolution;

    const getResolution = (resolution?: PositionResolution): number => {
        switch ( resolution) {
          case PositionResolution.VALUE_1E3:
            return 1000;  // Thousandths
          case PositionResolution.VALUE_1E2:
            return 100;  // Hundredths
          case PositionResolution.VALUE_1E1:
            return 10;  // Tenths
          default:
            return 1;  // Ones
        }
    }

    const getStepSize = (resolution?: PositionResolution): number => {
        switch ( resolution) {
          case PositionResolution.VALUE_1E3:
            return 0.0001;  // Thousandths
          case PositionResolution.VALUE_1E2:
            return 0.001;  // Hundredths
          case PositionResolution.VALUE_1E1:
            return 0.01;  // Tenths
          default:
            return 0.1;  // Ones
        }
    }

    let resolution = getResolution(positionResolution);
    let stepSize = getStepSize(positionResolution);
    let minPosition = 0;
    let maxPosition = 0;
    let controlMode: ControlMode | undefined;
    let isInvalid = true;
    if ( throttleConfig && throttleConfig.invalidReason === undefined ) {
        minPosition = throttleConfig.positionControlMinPos/resolution;
        maxPosition = throttleConfig.positionControlMaxPos/resolution;
        controlMode = throttleConfig.controlMode;
        isInvalid = false;
    }

    const getPositionSensorReading = useCallback( () => {
        async function getPositionSensorReading() {
            const positionSensorValue = await packetThreadPoolApi.getPositionSensorReading();
            if ( positionSensorValue !== null) {
                setPositionSensorValue(parseFloat(positionSensorValue.toFixed(positionResolution? positionResolution+1 : 1)));
            }
            return positionSensorValue;
        }
        return getPositionSensorReading();
    }, [ positionResolution ] );

    useEffect( () => {
        if ( throttleConfig && throttleConfig.invalidReason === undefined ) {
            let positionValue = throttleConfig.positionControlHomePos/resolution;
            const parsedVal = parseFloat(positionValue.toFixed(positionResolution? positionResolution+1 : 1));
            setHomePosition(parsedVal);
        }
    }, [ throttleConfig, resolution, positionResolution ] );

    // On initial render fetch the position sensor reading and update the position value on slider closest to the sensor reading
    // When the position sensor reading is out of range, the position value will be set to the min or max position
    useEffect( () => {
        if ( connectedDevice === undefined ) {
            return;
        }
        async function getPositionSensorReading() {
            const positionSensorValue = await packetThreadPoolApi.getPositionSensorReading();
            if ( positionSensorValue === null ) {
                _setPositionValue(0);
                return;
            }
            if ( positionSensorValue != null && positionSensorValue > maxPosition ) {
                _setPositionValue(maxPosition);
            } else if ( positionSensorValue != null && positionSensorValue < minPosition ) {
                _setPositionValue(minPosition);
            } else {
                _setPositionValue(parseFloat(positionSensorValue.toFixed(positionResolution? positionResolution+1 : 1)));
            }
            setIsPositionValueSet(true);
        }
        getPositionSensorReading();
    }, [ connectedDevice, minPosition, maxPosition, positionResolution ] );

    // ToDo: after new protocol, can remove this hook; see related ToDo in useThrottleConfig.ts.
    //
    // There is an eslint rule exception because we really want to run this on
    // initial render and on device connect/disconnect but eslint thinks we
    // want to run it on initial render _and_ when the value of
    // fetchConfigurations changes.
    useEffect( () => {
        // throttleConfig will definitely be unavailable until configurations have been fetched.
        fetchConfigurations();
    }, [ connectedDevice ]); // eslint-disable-line react-hooks/exhaustive-deps

    const sendPulse = useCallback( () => {
        if ( connectedDevice === undefined  || !isPositionValueSet ) {
            return;
        }
        hidSend(
            connectedDevice.handle,
            serializeSetUsbThrottleControlRequest(
                {
                    value: positionValue*resolution,
                }
            ) )
            .catch( e => console.warn( "Failed to send position command:", e ) );
        
    }, [ positionValue, connectedDevice, resolution, isPositionValueSet ] );

    useEffect( () => {
        sendPulse();
        getPositionSensorReading();
        const sendPulseInterval = setInterval( sendPulse, PULSE_INTERVAL_MS );
        const getSensorInterval = setInterval( getPositionSensorReading, PULSE_INTERVAL_MS );
        return () => {
            clearInterval( sendPulseInterval );
            clearInterval( getSensorInterval );
        }
    }, [ sendPulse, getPositionSensorReading ] );

    const onChange = useCallback( ( value: number ) => {
        const parsedVal = parseFloat(value.toFixed(positionResolution? positionResolution+1 : 1));
        _setPositionValue(parsedVal);
        sendPulse();
    }, [ sendPulse, positionResolution ] );

    const setHome = async () => {
        if ( connectedDevice === undefined || latestConfig === undefined || selectedConfiguration === undefined ) {
            return;
        }
        let inputConfig = {
            ...latestConfig,
            inputLimitCenter: positionValue*resolution,
        };
        hidSend(
            connectedDevice.handle,
            serializeWriteInputConfigRequest(inputConfig) )
            .catch( e => console.warn( "Failed to send set home command:", e )
        );
        await updateRecordFields(
            CollectionId.Configurations,
            selectedConfiguration.id,
            {inputConfig: inputConfig},
        );
        setHomePosition(positionValue);
        fetchConfigurations();
    }

    const setManualValue = useCallback( ( value: number, valueStr: string ) => {
        if ( ( maxPosition === undefined || minPosition === undefined ) || ( valueStr !== "" && !isFinite( parseFloat(valueStr)) ) || positionResolution === undefined ) {
            return;
        }
        const parsedValue = parseFloat(valueStr)
        // If the value has more than one decimal place, round it to one decimal place
        let manualValue = valueStr.split('.')[1]?.length > positionResolution+1? parsedValue.toFixed(positionResolution+1) : valueStr;
        _setManualValueStr( manualValue );
    }, [minPosition, maxPosition, positionResolution] );

    const setPositionValue = useCallback( () => {
        if ( manualValueStr !== "" ) {
            const parsedValue = Math.max( Math.min( parseFloat(manualValueStr), maxPosition ), minPosition );        
            _setManualValueStr( "" );
            _setPositionValue( parsedValue );
        }
    }, [manualValueStr, minPosition, maxPosition] );

    if ( latestConfig === undefined || latestConfig.inputMode !== InputMode.USB ) {
        return null;
    }

    let labelRenderer: LabelRendererFn = true;
    if ( controlMode !== undefined && selectedConfiguration !== undefined ) {
        labelRenderer = getThrottleLabelRenderer( controlMode, positionResolution, selectedConfiguration?.data.positionControlSettings.positionUnits );
    }

    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                position: "relative",
                display: "flex",
                alignItems: "center",
                color: Colors.WHITE,
                padding: 16
            }}
        >
            {/* Drag and drop drag handle */}
            <FontAwesomeIcon
                icon={ faGripVertical }
                color={ Colors.WHITE }
                style={{ cursor: "pointer", position: "absolute", top: 0, left: 0}}
                size="lg"
            />
            <div style={{ color: Colors.WHITE, position: "absolute", top: 0, left: 0, padding: "0px 0px 0px 18px" }}>Position</div>
            <FontAwesomeIcon
                icon={ faXmark }
                color={ Colors.WHITE }
                style={{ position: "absolute", top: 0, right: 0, padding: "0px 4px", cursor: "pointer" }}
                onClick={ close }
                size="lg"
            />
            <div style={{
                height: "100%",
                width: "100%",
                position: "relative",
                display: "flex",
                alignItems: "stretch",
                color: Colors.WHITE,
                padding: 16,
                flexDirection: "column",
                justifyContent: "center",
            }}>
                <div style={{display: "flex", justifyContent: "space-between", height: "28em", alignItems: "center"}}>
                    <div style={{width: "20%", display: "flex", justifyContent: "center", alignItems: "stretch"}}>
                        <NumericInput
                            placeholder={ isInvalid ? "–" : positionValue.toString() }
                            min={ minPosition }
                            max={  maxPosition }
                            style={{ borderRadius: 8, backgroundColor: Colors.LIGHT_GREEN, color: Colors.WHITE, display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}
                            clampValueOnBlur={ true }
                            onValueChange={ setManualValue }
                            value={ manualValueStr }
                            rightElement={ <BPButton icon="arrow-right" minimal={ true } intent="success" onClick={ setPositionValue } /> }
                            onKeyDown={ e => e.key === "Enter" ? setPositionValue() : undefined }
                            buttonPosition="none"
                            fill = { true }
                            allowNumericCharactersOnly={ true }
                            minorStepSize={ stepSize }
                            stepSize={ stepSize }
                            majorStepSize={ stepSize }
                        />
                    </div>
                    <div>
                        <h1>
                            {positionSensorValue}
                        </h1>
                    </div>
                    <div style={{width: "20%", display: "flex", justifyContent: "center"}}>
                        <Button
                            backgroundColor={Colors.WHITE}
                            textColor={Colors.BLACK}
                            onClick={setHome}
                        >
                            Set Home
                        </Button>
                    </div>
                </div>
                <div style={{ marginTop: "auto" }}>
                    <div style={{ height: "100%", alignItems: "center", marginTop: "auto" }}>
                        {
                            isInvalid || <Slider
                                className="throttle-slider"
                                min={ minPosition }
                                max={ maxPosition }
                                value={ positionValue }
                                onChange={ onChange }
                                labelValues={ [ minPosition, homePosition, maxPosition ] }
                                vertical={ false }
                                showTrackFill={ false }
                                disabled={ isInvalid }
                                labelRenderer={ labelRenderer }
                                stepSize={ stepSize }
                                initialValue={ homePosition }
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
