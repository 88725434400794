import { useCallback, useEffect, useState } from "react";
import { PacketId } from "../utils/common-types";
import { moveValueRange, nowMs } from "../utils/common";
import { usePacketMetadata } from "./usePacketMetadata";
import { realtimeMetadata } from "../utils/packet-workers";

export type TimeWindow = [ number, number ];

export enum PlaybackMode {
    Play,
    Pause,
    Live
}

export const REFRESH_RATE = 42;
export const REFRESH_MS = 1000 / REFRESH_RATE;
export const MIN_TIME_WINDOW_SIZE = 1000;
const DEFAULT_TIME_WINDOW_SIZE = 10000;

interface IPlaybackState {
    mode: PlaybackMode;
    interval: NodeJS.Timer | undefined;
    timeWindow: TimeWindow | undefined;
}

const defaultState: () => IPlaybackState = () => ({ mode: PlaybackMode.Pause, interval: undefined, timeWindow: undefined });

type PlaybackStateMap = Map<PacketId, IPlaybackState>;
const playbackStateMap: PlaybackStateMap = new Map();
const getPlaybackState = ( packetId: PacketId ): IPlaybackState => {
    if ( !playbackStateMap.has( packetId ) ) {
        playbackStateMap.set( packetId, defaultState() );
    }

    return playbackStateMap.get( packetId )!;
}

type PlaybackModeListenersMap = Map<PacketId, ( () => void )[]>;
const playbackModeListenersMap: PlaybackModeListenersMap = new Map();
const getPlaybackModeListeners = ( packetId: PacketId ) => {
    if ( !playbackModeListenersMap.has( packetId ) ) {
        playbackModeListenersMap.set( packetId, [] );
    }

    return playbackModeListenersMap.get( packetId )!;
}

type TimeWindowListenersMap = Map<PacketId, ( () => void )[]>;
const timeWindowListenersMap: TimeWindowListenersMap = new Map();
const getTimeWindowListeners = ( packetId: PacketId ) => {
    if ( !timeWindowListenersMap.has( packetId ) ) {
        timeWindowListenersMap.set( packetId, [] );
    }

    return timeWindowListenersMap.get( packetId )!;
};

const _setTimeWindow = ( packetId: PacketId, timeWindow: TimeWindow | undefined ) => {
    getPlaybackState( packetId ).timeWindow = timeWindow;
    getTimeWindowListeners( packetId ).forEach( l => l() );
};

const _setPlaybackMode = ( packetId: PacketId, playbackMode: PlaybackMode ) => {
    getPlaybackState( packetId ).mode = playbackMode;
    getPlaybackModeListeners( packetId ).forEach( l => l() );
};

const _setTimeWindowEnd = async ( packetId: PacketId, endTimeMs: number ): Promise<boolean> => {
    let notAtEnd: boolean = true;

    const timeWindow = getPlaybackState( packetId ).timeWindow;
    const packetWindow = realtimeMetadata.timeWindow;

    if ( timeWindow === undefined || packetWindow === undefined ) {
        return notAtEnd;
    }

    if ( packetWindow[ 1 ] <= endTimeMs ) {
        endTimeMs = packetWindow[ 1 ];
        notAtEnd = false;
    }

    const delta = endTimeMs - timeWindow[ 1 ];

    _setTimeWindow( packetId, [ timeWindow[ 0 ] + delta, timeWindow[ 1 ] + delta ] );

    return notAtEnd;
};

const _moveTimeWindow = async ( packetId: PacketId, delta: number ) => {
    const timeWindow = getPlaybackState( packetId ).timeWindow;
    const packetWindow = realtimeMetadata.timeWindow;
    if ( packetWindow === undefined || timeWindow === undefined ) {
        return;
    }

    _setTimeWindow( packetId, moveValueRange( timeWindow, packetWindow, delta ) );
};

const _goToEnd = async ( packetId: PacketId ) => {
    const latestWindow = realtimeMetadata.timeWindow;
    if ( latestWindow === undefined ) {
        return;
    }

    _setTimeWindowEnd( packetId, latestWindow[ 1 ] );
};

const _clearPlaybackInterval = ( packetId: PacketId ) => {
    clearInterval( getPlaybackState( packetId ).interval );
    getPlaybackState( packetId ).interval = undefined;
};

const _pause = ( packetId: PacketId ) => {
    _clearPlaybackInterval( packetId );
    _setPlaybackMode( packetId, PlaybackMode.Pause );
};

const _play = ( packetId: PacketId ): void => {
    _clearPlaybackInterval( packetId );
    const startTimeOffsetMs = getPlaybackState( packetId ).timeWindow?.[ 1 ];
    if ( startTimeOffsetMs === undefined ) {
        return;
    }

    _setPlaybackMode( packetId, PlaybackMode.Play );

    const startTimeMs = nowMs();
    getPlaybackState( packetId ).interval = setInterval( async () => {
        const msElapsed = nowMs() - startTimeMs;
        if ( !( await _setTimeWindowEnd( packetId, startTimeOffsetMs + msElapsed ) ) ) {
            _pause( packetId );
        }
    }, REFRESH_MS );
};

const _live = ( packetId: PacketId ) => {
    _clearPlaybackInterval( packetId );
    _setPlaybackMode( packetId, PlaybackMode.Live );

    getPlaybackState( packetId ).interval = setInterval( () => {
        _goToEnd( packetId );
    }, REFRESH_MS );
};

export const clearPlaybackState = () => {
    const packetIds = [ ...playbackStateMap.keys() ];
    for ( const packetId of packetIds ) {
        _pause( packetId );
    }

    playbackStateMap.clear();

    for ( const packetId of packetIds ) {
        getTimeWindowListeners( packetId ).forEach( l => l() );
    }
};

export const usePlayback = ( packetId: PacketId ): {
    playbackMode: PlaybackMode;
    timeWindow: TimeWindow | undefined;
    setTimeWindow: ( timeWindow: TimeWindow | undefined ) => void;
    moveTimeWindow: ( delta: number ) => void;
    play: () => void;
    pause: () => void;
    live: () => void;
} => {
    const [ timeWindow, setTimeWindow ] = useState<TimeWindow | undefined>( getPlaybackState( packetId ).timeWindow );
    const [ mode, setMode ] = useState<PlaybackMode>( getPlaybackState( packetId ).mode );

    const packetWindow = usePacketMetadata().timeWindow;

    useEffect( () => {
        if ( packetWindow === undefined ) {
            return;
        }

        if ( getPlaybackState( packetId ).timeWindow === undefined ) {
            _setTimeWindow( packetId, [ packetWindow[ 0 ], packetWindow[ 0 ] + DEFAULT_TIME_WINDOW_SIZE ] );
        }
    }, [ packetId, packetWindow ] );

    const pause = useCallback( () => {
        _pause( packetId );
    }, [ packetId ] );

    const play = useCallback( () => {
        _play( packetId );
    }, [ packetId] );

    const live = useCallback( () => {
        _live( packetId );
    }, [ packetId ] );

    const setGlobalTimeWindow = useCallback( ( timeWindow: TimeWindow | undefined ) => {
        _setTimeWindow( packetId, timeWindow );
    }, [ packetId ] );

    const moveGlobalTimeWindow = useCallback( ( delta: number ) => {
        _moveTimeWindow( packetId, delta );
    }, [ packetId ] );

    useEffect( () => {
        const handlePlaybackModeChange = () => {
            setMode( getPlaybackState( packetId ).mode );
        };

        const handleTimeWindowChange = () => {
            setTimeWindow( getPlaybackState( packetId ).timeWindow );
        };

        getPlaybackModeListeners( packetId ).push( handlePlaybackModeChange );
        getTimeWindowListeners( packetId ).push( handleTimeWindowChange );

        return () => {
            let index = getPlaybackModeListeners( packetId ).indexOf( handlePlaybackModeChange );
            if ( index !== -1 ) {
                getPlaybackModeListeners( packetId ).splice( index, 1 );
            }

            index = getTimeWindowListeners( packetId ).indexOf( handleTimeWindowChange );
            if ( index !== -1 ) {
                getTimeWindowListeners( packetId ).splice( index, 1 );
            }
        };
    }, [ packetId, setTimeWindow, setMode ] );

    return {
        playbackMode: mode,
        timeWindow,
        setTimeWindow: setGlobalTimeWindow,
        moveTimeWindow: moveGlobalTimeWindow,
        play,
        pause,
        live
    };
};