import { useEffect, useState } from "react";
import { realtimeMetadata } from "../utils/packet-workers";
import { REFRESH_MS } from "./usePlayback";

export const usePacketMetadata = () => {
    const [ metadata, setMetadata ] = useState<typeof realtimeMetadata>( { ...realtimeMetadata } );

    useEffect( () => {
        const interval = setInterval( () => setMetadata( { ...realtimeMetadata } ), REFRESH_MS );

        return () => clearInterval( interval );
    }, [] );

    return metadata;
};
