import { CSSProperties, PropsWithChildren, forwardRef, useCallback, useState } from "react";

export const Button = forwardRef<HTMLDivElement, PropsWithChildren<{
    backgroundColor: string;
    textColor: string;
    style?: CSSProperties;
    disabled?: boolean;
    onClick?: () => void;
    tooltip?: string;
}>>( ( props, ref ) => {
    const [ hovering, setHovering ] = useState<boolean>( false );

    const onMouseEnter = useCallback( () => {
        setHovering( true );
    }, [] )

    const onMouseLeave = useCallback( () => {
        setHovering( false );
    }, [] )

    return (
        <div
            ref={ ref }
            onMouseEnter={ onMouseEnter }
            onMouseLeave={ onMouseLeave }
            style={{
                ...props.style,
                display: "flex",
                padding: "4px 12px",
                backgroundColor: props.backgroundColor,
                justifyContent: "center",
                borderRadius: 16,
                color: props.textColor,
                cursor: props.disabled ? "not-allowed" : "pointer",
                opacity: props.disabled ? 0.5 : ( hovering ? 0.9 : 1 ),
                fontSize: 14,
            }}
            onClick={ props.disabled ? undefined : props.onClick }
            title={ props.tooltip }
        >
            { props.children }
        </div>
    );
} );
