import { useEffect, useState } from "react";

let _enableHover: boolean = false;
let _hoverPosition: number | undefined; // 0-1

const listeners: ( ( value: typeof _hoverPosition ) => void )[] = [];

const addHoverPositionListener = ( cb: ( value: typeof _hoverPosition ) => void ) => {
    listeners.push( cb );
};

const removeHoverPositionListener = ( cb: ( value: typeof _hoverPosition ) => void ) => {
    const index = listeners.indexOf( cb );
    if ( index === -1 ) {
        return;
    }

    listeners.splice( index, 1 );
};

const setHoverPosition = ( value: typeof _hoverPosition ) => {
    _hoverPosition = value;
    listeners.forEach( cb => cb( _hoverPosition ) );
};

const enableHoverListeners: ( ( value: boolean ) => void )[] = [];

const addEnableHoverListener = ( cb: ( value: boolean ) => void ) => {
    enableHoverListeners.push( cb );
};

const removeEnableHoverListener = ( cb: ( value: boolean ) => void ) => {
    const index = enableHoverListeners.indexOf( cb );
    if ( index === -1 ) {
        return;
    }

    enableHoverListeners.splice( index, 1 );
};

const setEnableHover = ( value: boolean ) => {
    _enableHover = value;
    enableHoverListeners.forEach( cb => cb( _enableHover ) );
};

export const usePlotHover = (): [ boolean, typeof _hoverPosition, ( value: boolean ) => void, ( value: typeof _hoverPosition ) => void ] => {
    const [ hoverPositionMs, _setHoverPosition ] = useState<typeof _hoverPosition>( _hoverPosition );
    const [ enableHover, _setEnableHover ] = useState<boolean>( false );
    
    useEffect( () => {
        const handleHoverChange = ( value: typeof _hoverPosition ) => {
            _setHoverPosition( enableHover ? value : undefined );
        };

        const handleEnableHoverChange = ( value: boolean ) => {
            _setEnableHover( value );
        };

        addHoverPositionListener( handleHoverChange );
        addEnableHoverListener( handleEnableHoverChange );

        return () => {
            removeHoverPositionListener( handleHoverChange );
            removeEnableHoverListener( handleEnableHoverChange );
        };
    }, [ enableHover ] );

    return [ enableHover, hoverPositionMs, setEnableHover, setHoverPosition ];
};
