import "./setup/firebase";

/* Global definitions -- START */

const _global = ( window || global ) as any;

declare global {
    function isDefined<T>( value: T ): value is NonNullable<T>;
}

_global.isDefined = <T>( value: T ): value is NonNullable<T> => {
    return value !== undefined && value !== null;
};

/* Global definitions -- END */

export {};