import { Colors } from "../../design/colors";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { Auth } from "../../components/Auth";
import { Icon } from "@blueprintjs/core";
import { User, sendEmailVerification } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import { appHost, isProd, migrationDate } from "../../utils/common";

const TIME_BETWEEN_EMAILS = 10000;

export const Root = () => {
    const { initialized, authenticated, user, reload } = useAuth();
    const [ sendLinkEnabled, setSendLinkEnabled ] = useState<boolean>( true );

    const resendLink = useCallback( () => {
        if ( !sendLinkEnabled || !user ) {
            return;
        }

        setSendLinkEnabled( false );
        sendEmailVerification( user, { url: appHost } );

        setTimeout( () => {
            setSendLinkEnabled( true );
        }, TIME_BETWEEN_EMAILS );
    }, [ user, sendLinkEnabled ] );

    let content;
    if ( !initialized ) {
        content = null;
    } else if ( !authenticated ) {
        content = <Auth />;
    } else if ( !user ) {
        content = null;
    } else if ( isUserBlocked( user ) ) {
      // Click-Up 86a2ncw0q
      content = <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <p style={{ fontSize: 18, color: Colors.WHITE, fontWeight: "bold" }}>
          Your account has been migrated and is no longer available here.<br />
          Please use our new production instance, <a href="https://app.salientmotion.com">app.salientmotion.com</a>
        </p>
      </div>;
    } else if ( !user.emailVerified ) {
        content = (
            <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <Icon icon="envelope" size={ 64 } intent="warning" color={ Colors.WHITE } />
                <span style={{ marginTop: 16, color: Colors.WHITE }}>
                    To continue, please verify your email address:
                </span>
                <span style={{ marginTop: 8, color: Colors.WHITE, fontWeight: "bold" }}>
                    { user.email }
                </span>
                <span
                    style={{
                        marginTop: 16,
                        color: sendLinkEnabled ? Colors.LIGHT_LIGHT_GREEN : Colors.WHITE,
                        cursor: sendLinkEnabled ? "pointer" : undefined,
                    }}
                    onClick={ sendLinkEnabled ? resendLink : undefined }
                >
                    { sendLinkEnabled ? "Resend link" : "Sent!" }
                </span>
            </div>
        );
    } else {
        content = <Outlet />;
    }

    useEffect( () => {
        window.addEventListener( "focus", reload );

        return () => window.removeEventListener( "focus", reload );
    }, [ reload ] );

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: Colors.LIGHT_GREEN,
                height: "100%",
            }}
        >
            { content }
        </div>
    );
}

const isUserBlocked = ( user: User ): boolean => {
  const isAfterMigration = ( new Date() >= migrationDate );
  const externalEmail = !( user.email?.match(/@salientmotion.com$/i) || user.email?.match(/@singularityus.com$/i) || user.email?.match(/@pivotal.aero$/i) || user.email?.match(/@crflight.com$/i) ); // TODO: remove the singularityus.com, @pivotal.aero and crflight.com
  return isAfterMigration && !isProd() && externalEmail;
};
