import { FwVersion } from "../hooks/useDevices";

type DeviceNameSubstring = "Dust Devil" | "Cyclone" | "Vortex" | "Pulsar" | "Actuator";

export const MIN_FW_FOR_PARAM_DETECT_V2: {
  [key in DeviceNameSubstring]: FwVersion;
} = {
  "Dust Devil": [4, 1, 0],
  Cyclone: [4, 2, 0],
  Vortex: [1, 0, 0],
  Pulsar: [1, 0, 0],
  Actuator: [1, 0, 0],
};

export const MIN_FW_FOR_OPEN_LOOP = MIN_FW_FOR_PARAM_DETECT_V2;

export const MIN_FW_FOR_ENCODER_CALIBRATION: {
  [key in DeviceNameSubstring]?: FwVersion;
} = {
  "Dust Devil": [4, 0, 1],
  Cyclone: [4, 2, 0],
  Vortex: [1, 0, 0],
  Pulsar: [1, 0, 0],
  Actuator: [1, 0, 0],
};
