import React from "react";
import { MenuItem2 } from "@blueprintjs/popover2";
import { ItemRenderer, Select2, Select2Props } from "@blueprintjs/select";
import { camelCaseToReadable } from "../utils/common";
import { PacketId, PacketKeys, displayUnits } from "../utils/common-types";

const renderMessageKey: ItemRenderer<string> = ( key, { handleClick, handleFocus } ) => {
    return (
        <MenuItem2
            key={ key }
            onClick={ handleClick }
            onFocus={ handleFocus }
            text={ camelCaseToReadable( key ) }
        />
    );
};

export const MessageKeySelect = <I extends PacketId>( props: {
    packetId: I;
    onSelect: ( key: PacketKeys<I> ) => void;
    children: React.ReactNode;
    exclude?: PacketKeys<I>[];
    popoverRef?: Select2Props<string>[ "popoverRef" ];
    onClose?: () => void;
    onOpening?: () => void;
} ) => {
    return (
        <Select2<string>
            items={ Object.keys( displayUnits[ props.packetId ] ).filter( k => props.exclude === undefined || !props.exclude.includes( k as PacketKeys<I> ) ) }
            itemRenderer={ renderMessageKey }
            onItemSelect={ k => props.onSelect( k as PacketKeys<I> ) }
            filterable={ false }
            popoverProps={{
                placement: "left",
                onClose: props.onClose,
                onOpening: props.onOpening,
            }}
            popoverRef={ props.popoverRef }
        >
            { props.children }
        </Select2>
    );
};
