import { Button, HotkeyConfig, useHotkeys } from "@blueprintjs/core";
import { PacketId } from "../../utils/common-types";
import { Colors } from "../../design/colors";
import { useCallback, useMemo, useRef } from "react";
import { throttle } from "../../utils/common";
import { PlaybackMode, usePlayback } from "../../hooks/usePlayback";
import { useConnection } from "../../connections/connection";
import { useDevices } from "../../hooks/useDevices";

export const PlaybackButton = () => {
    const packetId: PacketId = PacketId.REALTIME_DATA;
    const { connection } = useConnection();
    const { connectedDevice } = useDevices();
    const { playbackMode, play, pause, timeWindow, moveTimeWindow } = usePlayback( packetId );
    const isPlaying = playbackMode === PlaybackMode.Play || playbackMode === PlaybackMode.Live;
    const isConnected = connection !== undefined || connectedDevice !== undefined;
    const buttonRef = useRef<HTMLButtonElement>( null );

    const toggle = useCallback( () => {
        buttonRef.current?.blur();
        isPlaying ? pause() : play();
    }, [ isPlaying, pause, play ] );

    const hotkeys: HotkeyConfig[] = useMemo( () => {
        if ( !isConnected ) {
            return [];
        }

        return [
            {
                combo: "space",
                label: "Play / Pause",
                global: true,
                onKeyDown: throttle( toggle, 50 ),
                preventDefault: true,
                stopPropagation: true
            },
            {
                combo: "right",
                label: "Move slider right",
                global: true,
                onKeyDown: throttle( () => {
                    if ( timeWindow === undefined ) {
                        return;
                    }
                    pause();
                    moveTimeWindow( 100 );
                }, 20 ),
                preventDefault: true,
                stopPropagation: true
            },
            {
                combo: "left",
                label: "Move slider left",
                global: true,
                onKeyDown: throttle( () => {
                    if ( timeWindow === undefined ) {
                        return;
                    }
                    pause();
                    moveTimeWindow( -100 );
                }, 20 ),
                preventDefault: true,
                stopPropagation: true
            }
        ];
    }, [ isConnected, toggle, timeWindow, moveTimeWindow, pause ] );

    useHotkeys( hotkeys );

    if ( !isConnected ) {
        return null;
    }

    return (
        <Button
            elementRef={ buttonRef }
            icon={ isPlaying ? "pause" : "play" }
            title={ isPlaying ? "Pause" : "Play" }
            minimal={ true }
            intent="primary"
            style={{ color: Colors.WHITE }}
            onClick={ toggle }
        />
    );
};