import { useCallback, useEffect, useRef, useState } from "react";
import { CollectionId, DatabaseRecord, getRecords } from "../utils/firestore";
import { useAuth } from "./useAuth";
import { _updateMotorConfigs } from "./useThrottleConfig";
import {
  electricalToMechanical,
  isPolePairCountValid,
} from "../utils/common";
import {
  ControlMode,
  SensorMode,
} from "../utils/common-types";
import {
  isPositionLockEnabled,
  isSensorlessEnabled,
} from "../utils/feature-flags";

export const DEFAULT_SENSOR_MODE = SensorMode.OPENLOOP;

export const DEFAULT_SPEED_P_GAIN_NMPSPMKRAD = 0.03; // N-m/s/(mech. rad)
export const DEFAULT_SPEED_I_GAIN_NMPMKRAD   = 0.3;  // N-m/(mech. rad)

export const DEFAULT_OPEN_LOOP_ACCEL_MKRADPS2 =   10; // mech. rad/s^2
export const DEFAULT_OPEN_LOOP_ACCEL_ELRADPS2 =   50; // electrical rad/s^2; only used when pole pair count is unknown!
export const MAX_OPEN_LOOP_ACCEL_MKRADPS2     = 2000; // mech. rad/s^2

export const DEFAULT_MOTOR_MAX_SPEED_MKRPM = 500; // mech. RPM

export type Configuration = {
  id: string;
  data: DatabaseRecord[CollectionId.Configurations];
};

let _initialized = false;
let _configurations: Configuration[] = [];
let _selectedConfigurationId = "";
const _listeners: (() => void)[] = [];
const _notify = () => _listeners.forEach((l) => l());

const setConfigurations = (value: typeof _configurations) => {
  _configurations = value;
  _initialized = true;
  _updateMotorConfigs( value );
  _notify();
};

const setSelectedConfigurationId = (value: string) => {
  _selectedConfigurationId = value;
  _notify();
};

// ToDo: config versioning with upgrading to latest (CUID 86a329w7c)
const upgradeConfigToLatest = ( old: Configuration ): Configuration => {
  const { id, data: oldData } = old;

  // Must deep copy the parts that can be modified by logic below
  const data: typeof old.data = {
    ...oldData,
    inputConfig: { ...oldData.inputConfig },
    controlConfig: { ...oldData.controlConfig },
  };

  if ( oldData.sensorsConfig !== undefined ) {
    data.sensorsConfig = { ...oldData.sensorsConfig };
  } else {
    // Populate default
    data.sensorsConfig = {
      sensorMode: DEFAULT_SENSOR_MODE,
      encoderCpr: 0,
      accelerationRamp: 0, // Deprecated
      accelerationRamp_mkradps2: DEFAULT_OPEN_LOOP_ACCEL_MKRADPS2,
    };
  }

  const polePairCount = oldData.controlConfig.polePairCount;
  if ( isPolePairCountValid( polePairCount ) && data.sensorsConfig.accelerationRamp_mkradps2 === undefined ) {
    // Upgrade of accelerationRamp to mech. rad/s^2 is possible (valid pole
    // pair count) and hasn't already been performed.
    data.sensorsConfig.accelerationRamp = 0; // Zero out the deprecated field for safety
    data.sensorsConfig.accelerationRamp_mkradps2 = electricalToMechanical( data.sensorsConfig.accelerationRamp, polePairCount );
  }

  // If sensor mode is Sensorless HFI but it's disabled in the current
  // environment, change it to Open Loop Startup, and also fix acceleration
  // ramp if necessary.
  if ( !isSensorlessEnabled() && data.sensorsConfig.sensorMode === SensorMode.SENSORLESS ) {
    data.sensorsConfig.sensorMode = DEFAULT_SENSOR_MODE;
    const ramp = data.sensorsConfig.accelerationRamp_mkradps2;
    if ( ramp === undefined || !isFinite( ramp ) || ramp < 0 ) {
      data.sensorsConfig.accelerationRamp_mkradps2 = DEFAULT_OPEN_LOOP_ACCEL_MKRADPS2;
    }
  }

  // Disable Voltage control mode by changing to Speed control mode.
  // Max motor speed and I-Gain & P-Gain for speed control are all set to valid defaults if invalid.
  if ( oldData.controlConfig.controlMode === ControlMode.VOLTAGE ) {
    data.controlConfig.controlMode = ControlMode.SPEED;
    const pGain = oldData.controlConfig.speedControlProportionalGain;
    if ( !isFinite( pGain ) || pGain < 0 ) {
      data.controlConfig.speedControlProportionalGain = DEFAULT_SPEED_P_GAIN_NMPSPMKRAD; // N-m/s/(mech. rad)
    }
    const iGain = oldData.controlConfig.speedControlIntegralGain;
    if ( !isFinite( iGain ) || iGain < 0 ) {
      data.controlConfig.speedControlIntegralGain = DEFAULT_SPEED_I_GAIN_NMPMKRAD; // N-m/(mech. rad)
    }
    const motorMaxSpeed = oldData.controlConfig.motorMaxSpeed;
    if ( !isFinite( motorMaxSpeed ) || motorMaxSpeed < 0 ) {
      data.controlConfig.motorMaxSpeed = DEFAULT_MOTOR_MAX_SPEED_MKRPM;
    }
  }

  // Disable Position Lock if it's enabled but unavailable in the current
  // environment.
  if ( !isPositionLockEnabled() && oldData.inputConfig.alwaysOn !== 0 ) {
    data.inputConfig.alwaysOn = 0;
  }

  return { id, data };
};

export const useMotorConfigurations = (): {
  initialized: boolean;
  configurations: typeof _configurations;
  fetchConfigurations: () => Promise<void>;
  selectedConfiguration: Configuration | undefined;
  selectConfiguration: (id: string) => void;
} => {
  const { user } = useAuth();
  const waitingForUserRef = useRef<boolean>( false );
  const [configurations, _setConfigurations] =
    useState<Configuration[]>(_configurations);
  const [selectedConfigurationId, _setSelectedConfigurationId] =
    useState<string>(_selectedConfigurationId);
  const [initialized, _setInitialized] = useState<boolean>(_initialized);

  const fetchConfigurations = useCallback(async () => {
    if (!user) {
      waitingForUserRef.current = true; // User may not yet be available
      return;
    }

    const records = await getRecords(user.uid, CollectionId.Configurations);
    setConfigurations(
      records.docs.map((d) => upgradeConfigToLatest({
        id: d.id,
        data: d.data(),
      } as Configuration))
    );
  }, [user]);

  useEffect(() => {
    if ( waitingForUserRef.current && user ) {
      // Deferred fetch
      waitingForUserRef.current = false;
      fetchConfigurations();
    }
  }, [ user, fetchConfigurations ]);

  useEffect(() => {
    const onChange = () => {
      _setConfigurations(_configurations);
      _setInitialized(_initialized);
      _setSelectedConfigurationId(_selectedConfigurationId);
    };

    _listeners.push(onChange);

    return () => {
      const index = _listeners.indexOf(onChange);
      if (index === -1) {
        return;
      }

      _listeners.splice(index, 1);
    };
  }, []);

  return {
    initialized,
    configurations,
    fetchConfigurations,
    selectedConfiguration:
      selectedConfigurationId === ""
        ? undefined
        : configurations.find((c) => c.id === selectedConfigurationId),
    selectConfiguration: setSelectedConfigurationId,
  };
};
