import { Configuration } from "../hooks/useMotorConfigurations";
import {
  ControlMode,
  InputMode,
  PositionUnits,
} from "./common-types";

export type LabelRendererCallableFn = ( (value: number, opts?: { isHandleTooltip: boolean; } ) => string | JSX.Element );
export type LabelRendererFn = boolean | LabelRendererCallableFn | undefined;

const TORQUE_PRECISION = 2;
const SPEED_PRECISION = 0;
const POSITION_PRECISION = 0;

export function formatValueFromControlMode(
  controlMode: ControlMode | undefined,
  value: number,
  stepSize?: number,
): number {
  if (controlMode === undefined) return value;

  let precision: number;
  switch (controlMode) {
    case ControlMode.TORQUE:
      precision = TORQUE_PRECISION;
      break;
    case ControlMode.SPEED:
      precision = SPEED_PRECISION;
      break;
    case ControlMode.POSITION:
      precision = POSITION_PRECISION;
      break;
    default:
      return value;
  }

  if ( controlMode === ControlMode.POSITION ) {
    if ( stepSize !== undefined && isFinite( stepSize ) && stepSize >= 0 ) {
      precision = stepSize+1;
    }
  } else if ( stepSize !== undefined && isFinite( stepSize ) && stepSize > 0 ) {
    // Increase precision for smaller step sizes
    let n = stepSize;
    while ( n < 1 ) {
      n *= 10;
      precision++;
    }
  }

  return parseFloat(value.toFixed(precision));
}

// Returns a labelRenderer for a slider with correct units, for usage when the input mode is USB.
export function getThrottleLabelRenderer(controlMode: ControlMode, stepSize?: number, positionUnits?: PositionUnits): LabelRendererFn {
  let unitMap: { [key in ControlMode]?: string } = {
    [ControlMode.TORQUE]: 'A',
    [ControlMode.SPEED]: 'RPM',
  };
  switch (positionUnits) {
    case PositionUnits.RADIANS:
      unitMap[ControlMode.POSITION] = 'rad';
      break;
    case PositionUnits.DEGREES:
      unitMap[ControlMode.POSITION] = 'deg';
      break;
    case PositionUnits.MILLIMETERS:
      unitMap[ControlMode.POSITION] = 'mm';
      break;
    case PositionUnits.INCHES:
      unitMap[ControlMode.POSITION] = 'in';
      break;
    default:
      unitMap[ControlMode.POSITION] = '';
  }

  return unitMap[controlMode]
    ? (value) => `${formatValueFromControlMode(controlMode, value, stepSize)} ${unitMap[controlMode]}`
    : true;
}

export const DEFAULT_RPM_STEP = 1;
export const DEFAULT_AMPS_STEP = 0.1;
export const DEFAULT_VOLTS_STEP = 0.1;
export const MIN_STEP = 1e-3;
export const MIN_RPM_STEP = 1;

// Partial<IWriteInputConfigRequest>
export interface IDeviceRange {
  inputLimitLower: number;
  inputLimitCenter: number;
  inputLimitUpper: number;
}

export const getDeviceRangeFromConfig = ( config: Configuration ): IDeviceRange => {
  const controlMode = config.data.controlConfig.controlMode;
  let physicalMax: number | undefined;
  let stepSize: number | undefined;
  switch ( controlMode ) {
    case ControlMode.TORQUE:
      physicalMax = config.data.powerConfig.motorCurrentLimit;
      stepSize = config.data.inputConfig.ampsStep ?? DEFAULT_AMPS_STEP;
      break;
    case ControlMode.SPEED:
      physicalMax = config.data.controlConfig.motorMaxSpeed;
      stepSize = config.data.inputConfig.rpmStep ?? DEFAULT_RPM_STEP;
      break;
    case ControlMode.POSITION:
      return {
        inputLimitLower: 0,
        inputLimitCenter: config.data.inputConfig.inputLimitCenter,
        inputLimitUpper: config.data.inputConfig.inputLimitUpper,
      }
    default:
      throw new Error( `Unexpected control mode: ${controlMode}` );
  }
  return calcDeviceRange( controlMode, physicalMax, stepSize );
}

const calcDeviceRange = ( controlMode: ControlMode, physicalMax: number, physicalStepSize: number ): IDeviceRange => {
  if ( physicalMax < 0 || physicalStepSize <= 0 ) {
    throw new Error( "Cannot calculate device range from invalid physical max and/or step." );
  }

  const inputLimitLower = 0;

  let dw;
  if ( controlMode === ControlMode.SPEED ) {
    // Special case to avoid deadband near 0 with large step sizes
    dw = Math.floor( physicalMax );
  } else {
    dw = Math.floor( physicalMax / physicalStepSize );
  }

  return {
    inputLimitLower,
    inputLimitCenter: dw,
    inputLimitUpper: 2 * dw,
  }
}

export const isDeviceRangeValid = ( deviceRange: IDeviceRange, controlMode?: ControlMode ): boolean => {
  const {
    inputLimitLower: lower,
    inputLimitCenter: center,
    inputLimitUpper: upper,
  } = deviceRange;

  if ( upper <= center || center <= lower ) {
    return false;
  }
  if ( (controlMode !== ControlMode.POSITION) && center !== ( lower + upper ) / 2 ) {
    return false;
  }
  return true;
}

// Throws exception if the range is not balanced (center is not average of lower and upper), or otherwise invalid.
const getDw = ( deviceRange: IDeviceRange, controlMode?: ControlMode ): number => {
  const {
    inputLimitLower: lower,
    inputLimitCenter: center,
    inputLimitUpper: upper,
  } = deviceRange;

  if ( upper <= center || center <= lower ) {
    throw new Error( "Bad device range." );
  }
  if ( (controlMode !== ControlMode.POSITION) && center !== ( lower + upper ) / 2 ) {
    throw new Error( "Device range not balanced: center is not average of lower and upper." );
  }

  return center - lower;
}

// Converting in the reverse of the normal direction here
//
// Throws exception if the range is not balanced (center is not average of lower and upper), or otherwise invalid.
export const stepInPhysicalUnitsFromDeviceRange = ( inputMode: InputMode, deviceRange: IDeviceRange, physicalMax: number ): number => {
  if ( inputMode !== InputMode.USB ) {
    // 1:1 correspondence for PWM & CAN mode.
    return 1;
  }

  const dw = getDw( deviceRange );

  return physicalMax / dw;
}

// Converts a physical position (Amps / Volts / RPM) to the device throttle position to actually send.
//
// Throws exception if physicalMax is negative.
// Throws exception if the range is not balanced (center is not average of lower and upper), or otherwise invalid.
export const physicalToDevice = ( inputMode: InputMode, deviceRange: IDeviceRange, physicalMax: number, physicalPosition: number, controlMode?: ControlMode ): number => {
  if ( inputMode !== InputMode.USB || controlMode === ControlMode.POSITION ) {
    // 1:1 correspondence for PWM & CAN mode.
    return physicalPosition;
  }

  if ( physicalMax <= 0 ) {
    throw new Error( "physicalMax is not positive." );
  }
  const dw = getDw( deviceRange );

  const devicePos = Math.round( deviceRange.inputLimitCenter + ( physicalPosition * ( dw / physicalMax ) ) );
  // Clamp to [lower, upper]
  return Math.min( Math.max( deviceRange.inputLimitLower, devicePos ), deviceRange.inputLimitUpper );
}

// Converts a device position the throttle sends, and that we receive as servoPulse from controller, to a physical position (Amps / Volts / RPM).
//
// Throws exception if the range is not balanced (center is not average of lower and upper), or otherwise invalid.
export const deviceToPhysical = ( inputMode: InputMode, deviceRange: IDeviceRange, physicalMax: number, devicePosition: number, controlMode?: ControlMode ): number => {
  if ( inputMode !== InputMode.USB  || controlMode === ControlMode.POSITION ) {
    // 1:1 correspondence for PWM & CAN mode.
    return devicePosition;
  }

  const dw = getDw( deviceRange );

  return ( devicePosition - deviceRange.inputLimitCenter ) * physicalMax / dw;
}
