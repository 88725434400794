import { ConnectionType, useConnection } from "../../../connections/connection";
import { Ref, useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    IconDefinition,
    faCode,
    faExternalLink,
    faGauge,
    faMicrochip,
    faPlugCircleXmark,
    faSignOut,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import GitInfo from 'react-git-info/macro';
import { Colors } from "../../../design/colors";
import { Dashboard } from "./tabs/dashboard/Dashboard";
import { PacketId } from "../../../utils/common-types";
import { usePlayback } from "../../../hooks/usePlayback";
import { useReset } from "../../../hooks/useReset";
import { signOut } from "firebase/auth";
import { firebaseAuth } from "../../../setup/firebase";
import { Devices } from "./tabs/devices/Devices";
import { MIN_FW_VERSION, useDevices } from "../../../hooks/useDevices";
import { Account } from "./tabs/account/Account";
import { versionLessThan } from "../../../utils/common";
import { Header } from "../../../components/header/Header";

const DOCS_URL = "https://docs.salientmotion.com/";

export enum HomeTabs {
    Dashboard,
    Devices,
    Account,
    // The following are not real tab IDs!
    Disconnect,
    SignOut,
    Docs,
    Version,
}

type TabDef = {
    id: HomeTabs;
    icon: IconDefinition;
    iconColor?: string;
    iconRef?: Ref<SVGSVGElement>;
    title: string;
    titleColor?: string;
    onClick: () => void;
    hidden?: boolean;
};

type TabsDef = {
    top: TabDef[],
    bottom: TabDef[],
};

const Tab = ( props: TabDef & { active: boolean; showTitle: boolean; } ) => {
    const [ hovering, setHovering ] = useState<boolean>( false );

    let backgroundColor: string | undefined;
    if ( props.active ) {
        backgroundColor = `${ Colors.BLACK }33`;
    } else if ( hovering ) {
        backgroundColor = `${ Colors.WHITE }11`;
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor,
            }}
            onClick={ props.onClick }
            onMouseEnter={ () => setHovering( true ) }
            onMouseLeave={ () => setHovering( false ) }
        >
            <FontAwesomeIcon
                ref={ props.iconRef }
                icon={ props.icon }
                color={ props.iconColor || Colors.WHITE }
                size="2x"
                style={{ padding: 16 }}
            />
            {
                props.showTitle ?
                <div style={{ color: props.titleColor || Colors.WHITE, fontSize: 16, fontWeight: "bold", padding: 16 }}>
                    { props.title }
                </div> :
                null
            }
        </div>
    )
};

const Tabs = ( props: {
    tabs: TabsDef;
    currentTab: HomeTabs;
    setCurrentTab: ( tab: HomeTabs ) => void;
    setWidth: ( width: number ) => void;
} ) => {
    const [ hovering, setHovering ] = useState<boolean>( false );
    const { setWidth, tabs, currentTab } = props;

    const topTabs = useMemo( () => {
        return tabs.top.filter( tab => !tab.hidden ).map( ( tab, index ) => {
            return <Tab key={ `home-tab-${ tab.id }` } iconRef={ e => e === null ? undefined : index === 0 ? setWidth( e.clientWidth ) : undefined } { ...tab } active={ currentTab === tab.id } showTitle={ hovering } />;
        } );
    }, [ tabs, currentTab, hovering, setWidth ] );

    const bottomTabs = useMemo( () => {
        return tabs.bottom.filter( tab => !tab.hidden ).map( tab => {
            return <Tab key={ `home-tab-${ tab.id }` } { ...tab } active={ currentTab === tab.id } showTitle={ hovering } />;
        } );
    }, [ tabs, currentTab, hovering ] );

    return (
        <div
            style={{ backgroundColor: Colors.DARK_GREEN, display: "flex", flexDirection: "column", justifyContent: "space-between", width: "fit-content", height: "100%", position: "absolute", boxShadow: "1px 2px 4px #222222", zIndex: 1 }}
            onMouseEnter={ () => setHovering( true ) }
            onMouseLeave={ () => setHovering( false ) }
        >
            <div>
                { topTabs }
            </div>
            <div>
                { bottomTabs }
            </div>
        </div>
    );
};

export const Home = () => {
    const [ currentTab, setCurrentTab ] = useState<HomeTabs>( HomeTabs.Dashboard );
    const [ tabsWidth, setTabsWidth ] = useState<number>( 0 );
    const { devices, connectedDevice, connect, refresh: refreshDeviceList } = useDevices();
    const { connection } = useConnection();
    const wereDevicesConnectedRef = useRef<boolean>( ( devices?.length ?? 0 ) > 0 );
    const isFirstRender = useRef<boolean>( true );
    const reset = useReset();
    const { live } = usePlayback( PacketId.REALTIME_DATA );

    useEffect( () => {
        refreshDeviceList();
    }, [ refreshDeviceList ] );

    // Device connection handlers
    useEffect( () => {
        if ( devices === undefined || devices.some( d => !d.initialized ) ) {
            // Device list not yet populated
            return;
        }

        if ( connection?.connectionType !== ConnectionType.HIDPlayback ) {
            if ( devices.length === 1 ) {

                const noConnection = ( connectedDevice === undefined );
                const isNew = !wereDevicesConnectedRef.current;

                if ( isFirstRender.current || ( noConnection && isNew ) ) {
                    // Auto-connect if one device is plugged in
                    const device = devices[ 0 ];
                    if ( device.initialized && !versionLessThan( device.fwVersion, MIN_FW_VERSION ) ) {
                        connect( devices[ 0 ] );
                        live();
                    }
                }

            } else if ( devices.length > 1 && isFirstRender.current ) {
                // Navigate to devices tab if >1 device is plugged in
                setCurrentTab( HomeTabs.Devices );
            }
        }

        wereDevicesConnectedRef.current = ( devices.length > 0 );
        isFirstRender.current = false;
    }, [ connection, connect, connectedDevice, currentTab, devices, live ] );

    const tabs: TabsDef = useMemo( () => {
        const gitInfo = GitInfo();

        return {
            top: [
                {
                    id: HomeTabs.Dashboard,
                    icon: faGauge,
                    title: "Dashboard",
                    onClick: () => setCurrentTab( HomeTabs.Dashboard ),
                },
                {
                    id: HomeTabs.Devices,
                    icon: faMicrochip,
                    title: "Devices",
                    onClick: () => setCurrentTab( HomeTabs.Devices ),
                },
                {
                    id: HomeTabs.Account,
                    icon: faUser,
                    title: "Account",
                    onClick: () => setCurrentTab( HomeTabs.Account ),
                },
            ],
            bottom: [
                {
                    id: HomeTabs.Disconnect,
                    icon: faPlugCircleXmark,
                    title: "Disconnect",
                    onClick: reset,
                    hidden: connectedDevice === undefined && connection?.connectionType !== ConnectionType.HIDPlayback,
                },
                {
                    id: HomeTabs.Docs,
                    icon: faExternalLink,
                    title: "Docs",
                    onClick: () => window.open(DOCS_URL, '_blank')?.focus(),
                },
                {
                    id: HomeTabs.SignOut,
                    icon: faSignOut,
                    title: "Sign Out",
                    onClick: () => signOut( firebaseAuth ),
                },
                {
                    id: HomeTabs.Version,
                    icon: faCode,
                    title: `v${process.env.REACT_APP_VERSION}-${gitInfo.commit.shortHash}`,
                    onClick: () => {},
                },
            ],
        };
    }, [ connectedDevice, connection, reset ] );

    let content;
    switch ( currentTab ) {
        case HomeTabs.Dashboard: content = <Dashboard manageConfigurations={ () => setCurrentTab( HomeTabs.Devices ) } />; break;
        case HomeTabs.Devices: content = <Devices />; break;
        case HomeTabs.Account: content = <Account />; break;
    }

    return (
        <>
            <Header />
            <div style={{ flex: 1, display: "flex", overflow: "auto" }}>
                <div style={{ width: tabsWidth }} />
                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    { content }
                </div>
                <Tabs tabs={ tabs } currentTab={ currentTab } setCurrentTab={ setCurrentTab } setWidth={ setTabsWidth } />
            </div>
        </>
    );
};
