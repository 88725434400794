import { useState, useEffect, useMemo } from "react";
import { throttle } from "../utils/common";
import { REFRESH_MS } from "./usePlayback";

const getDimensions = () => {
    return {
        width: document.body.clientWidth,
        height: document.body.clientHeight
    };
}

export const useWindowDimensions = () => {
    const [ dimensions, setDimensions ] = useState( getDimensions() );

    const handleResize = useMemo( () => throttle( () => setDimensions( getDimensions() ), REFRESH_MS ), [] )

    useEffect( () => {
        window.addEventListener( "resize", handleResize );
        return () => window.removeEventListener( "resize", handleResize );
    }, [ handleResize ] );

    return dimensions;
};