import {
  Dialog,
  Callout,
  FormGroup,
  H4,
  HTMLSelect,
  InputGroup,
  Intent,
  NumericInput,
  TextArea,
  Icon,
} from "@blueprintjs/core";
import { Colors } from "../../../../../../design/colors";
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ClickableIcon } from "../../../../../../components/ClickableIcon";
import { ThrottleRangeDisplay } from "../../../../../../components/ThrottleRangeDisplay";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  runEncoderCalibration,
  runHallSensorCalibration,
  runParameterDetection,
} from "../../../../../../connections/controller";
import {
  ControlMode,
  EncoderCalibStatus,
  IStartParamDetectResponse,
  IWriteControlConfigRequest,
  IWritePowerConfigResponse,
  IWriteOpenLoopConfigResponse,
  InputMode,
  PacketId,
  SensorMode,
  IWriteSensorModeResponse,
  fullDisplayUnits,
  IStartParamDetectRequest,
  EncoderDirection,
  displayUnits,
  IWritePositionControlSettingsRequest,
  Mtpa,
  FluxWeakening,
  PositionType,
  PositionResolution,
  PositionUnits,
  ISetPositionSensorLimitsRequest,
  serializeSetPositionSensorLimitsRequest,
  HallsensorCalibStatus,
} from "../../../../../../utils/common-types";
import {
  Env,
  Strings,
  encoderCalibrationFailed,
  encoderCalibrationStatusToString,
  strings,
  versionLessThan,
  isPolePairCountValid as _isPolePairCountValid,
  P_GAIN_TEXT,
  I_GAIN_TEXT,
  D_GAIN_TEXT,
  MOTOR_MAX_SPEED_TEXT,
  MOTOR_MAX_ACCELERATION_TEXT,
  DEADBAND_TEXT,
  POSITION_TYPE_TEXT,
  POSITION_UNITS_TEXT,
  RESOLUTION_TEXT,
  TRAVEL_DISTANCE_TEXT,
  hallSensorCalibrationStatusToString,
  hallSensorDirectionToString,
  hallSensorCalibrationFailed,
} from "../../../../../../utils/common";
import {
  CollectionId,
  DatabaseRecord,
  IMotorConfigurationDetails,
  InputConfig,
  SensorsConfig,
  addRecord,
  updateRecord,
} from "../../../../../../utils/firestore";
import {
  DEFAULT_AMPS_STEP,
  DEFAULT_RPM_STEP,
  DEFAULT_VOLTS_STEP,
  MIN_RPM_STEP,
  MIN_STEP,
} from "../../../../../../utils/throttle";
import "./CreateConfiguration.css";
import { useAuth } from "../../../../../../hooks/useAuth";
import {
  Configuration,
  DEFAULT_OPEN_LOOP_ACCEL_MKRADPS2,
  DEFAULT_SENSOR_MODE,
  DEFAULT_SPEED_I_GAIN_NMPMKRAD,
  DEFAULT_SPEED_P_GAIN_NMPSPMKRAD,
  MAX_OPEN_LOOP_ACCEL_MKRADPS2,
  useMotorConfigurations,
} from '../../../../../../hooks/useMotorConfigurations'
import { Device, IEncoderState, useDevices } from '../../../../../../hooks/useDevices'
import { Button } from '../../../../../../components/Button'
import { ToggleButton } from '../../../../../../components/toggleButton/ToggleButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  MIN_FW_FOR_PARAM_DETECT_V2,
} from "../../../../../../utils/firmware-versions";
import {
  isSensorlessEnabled,
  isThrottleUnitsEnabled,
} from "../../../../../../utils/feature-flags";
import { hidSend } from "../../../../../../connections/hid";
import { packetThreadPoolApi } from "../../../../../../utils/packet-workers";
import { Tooltip2 } from "@blueprintjs/popover2";

const FORM_WIDTH = 350;
const PULSE_INTERVAL_MS = 50;

enum ConfigurationStep {
  Details,
  ParamDetect,
  Throttle,
  Power,
  Sensors,
}

const configurationSteps = [
  ConfigurationStep.Details,
  ConfigurationStep.Throttle,
  ConfigurationStep.Power,
  ConfigurationStep.ParamDetect,
  ConfigurationStep.Sensors,
];

type Edit<T> = (key: keyof T, value: string) => void;

const configurationName = (
  userInput: string,
  manufacturer: string,
  model: string
) => {
  if (userInput !== "") {
    return userInput;
  }

  return [manufacturer, model].filter((v) => v !== "").join(" ");
};

const DetailsScreen = (
  props: INavigationProps & {
    details: Strings<IMotorConfigurationDetails>;
    editDetails: Edit<IMotorConfigurationDetails>;
  }
) => {
  const [inputWidth, setInputWidth] = useState<number | undefined>();
  const { details, editDetails, prev, next } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: Colors.WHITE,
      }}
    >
      <FormGroup>
        <InputGroup
          large={true}
          placeholder="Untitled Configuration"
          style={{
            width: inputWidth,
            backgroundColor: Colors.DARK_GREEN,
            color: Colors.WHITE,
            fontWeight: "bold",
            textAlign: "center",
            fontSize: 24,
          }}
          value={configurationName(
            details.name,
            details.motorManufacturer,
            details.motorModel
          )}
          onChange={(e) => editDetails("name", e.target.value)}
        />
      </FormGroup>
      <div style={{ height: 8 }} />
      <div style={{ width: FORM_WIDTH, maxWidth: "100%" }}>
        <FormGroup
          label="Motor Manufacturer"
          labelInfo={<span style={{ color: Colors.RED }}>*</span>}
        >
          <InputGroup
            style={{ backgroundColor: Colors.DARK_GREEN, color: Colors.WHITE }}
            inputRef={(el) => setInputWidth(el?.clientWidth)}
            value={details.motorManufacturer}
            onChange={(e) => editDetails("motorManufacturer", e.target.value)}
          />
        </FormGroup>
        <FormGroup
          label="Motor Model"
          labelInfo={<span style={{ color: Colors.RED }}>*</span>}
        >
          <InputGroup
            style={{ backgroundColor: Colors.DARK_GREEN, color: Colors.WHITE }}
            value={details.motorModel}
            onChange={(e) => editDetails("motorModel", e.target.value)}
          />
        </FormGroup>
        <div style={{ height: 16 }} />
        <FormGroup label="Notes">
          <TextArea
            style={{
              width: inputWidth || "100%",
              minHeight: 128,
              resize: "none",
              backgroundColor: Colors.DARK_GREEN,
              color: Colors.WHITE,
            }}
            value={details.notes}
            onChange={(e) => editDetails("notes", e.target.value)}
          />
        </FormGroup>
      </div>
      <div style={{ display: "flex", width: FORM_WIDTH }}>
        <div style={{ flex: 1 }}>
          <Button
            backgroundColor={Colors.WHITE}
            textColor={Colors.BLACK}
            onClick={prev}
            disabled={prev === undefined}
          >
            Back
          </Button>
        </div>
        <div style={{ width: 8 }} />
        <div style={{ flex: 1 }} />
        <div style={{ width: 8 }} />
        <div style={{ flex: 1 }}>
          <Button
            backgroundColor={Colors.BUTTON_GREEN}
            textColor={Colors.WHITE}
            onClick={next}
            disabled={next === undefined}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

/*
const LoadingDots = () => {
    const [ numDots, setNumDots ] = useState<number>( 0 );

    useEffect( () => {
        const interval = setInterval( () => {
            setNumDots( x => ( x + 1 ) % 4 );
        }, 250 );

        return () => clearInterval( interval );
    }, [ setNumDots ] );

    return <div style={{ color: Colors.WHITE, fontWeight: "bold" }}>{ ".".repeat( numDots ) }</div>;
};
*/

const rowCommonStyle = {
  flex: 1,
  color: Colors.WHITE,
  backgroundColor: Colors.DARK_GREEN,
  margin: 4,
  padding: 8,
  boxShadow: "1px 2px 4px #222222",
};

const labelStyle = {
  ...rowCommonStyle,
  display: "flex",
  justifyContent: "flex-end",
};

const valueStyle = {
  ...rowCommonStyle,
};

const paramIsNumber = ( s: string ): boolean => {
  return isFinite( parseFloat( s ) );
}

const paramIsNonNegativeNumber = ( s: string ): boolean => {
  const n = parseFloat( s );
  return ( isFinite( n ) && n >= 0 );
};

const ParamDetectScreen = (
  props: INavigationProps & {
    paramDetect: IStartParamDetectResponse | undefined;
    setParamDetect: (value: IStartParamDetectResponse) => void;
    manualParamInput: boolean;
    setManualParamInput: (value: boolean) => void;
    manualParamDetect: Strings<IStartParamDetectResponse>;
    editParamConfig: Edit<IStartParamDetectResponse>;
    userSpecifiedParams: Strings<IStartParamDetectRequest>;
    editUserSpecifiedParams: Edit<IStartParamDetectRequest>;
    powerConfig: Strings<IWritePowerConfigResponse>;
    powerConfigValid: boolean;
    polePairCount: number;
  }
) => {
  const { connectedDevice } = useDevices();
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [paramDetectErrorMessage, setParamDetectErrorMessage] = useState<string | undefined>();
  const {
    next,
    prev,
    setEnableNavigation,
    paramDetect,
    setParamDetect,
    manualParamInput,
    setManualParamInput,
    manualParamDetect,
    editParamConfig,
    userSpecifiedParams,
    editUserSpecifiedParams,
    powerConfig: powerConfigStr,
    powerConfigValid,
    polePairCount,
  } = props;

  useEffect(() => {
    setEnableNavigation(!isRunning);
  }, [setEnableNavigation, isRunning]);

  // Clear error message upon exiting this screen
  useEffect(() => {
    return () => {
      setParamDetectErrorMessage( undefined );
    };
  }, []);

  const buttonText = (paramDetect === undefined ? "Start" : "Re-run");
  const buttonBackgroundColor =
    paramDetect === undefined ? Colors.BUTTON_GREEN : Colors.WHITE;
  const buttonTextColor =
    paramDetect === undefined ? Colors.WHITE : Colors.BLACK;

  const productName = connectedDevice?.handle.productName.toLowerCase();
  const minFwForParamDetectV2 = Object.entries(MIN_FW_FOR_PARAM_DETECT_V2).find(
    (entry) => productName?.includes(entry[0].toLowerCase())
  )?.[1];


  const paramDetectV2Available =
    (connectedDevice?.initialized && minFwForParamDetectV2)
      ? !versionLessThan(connectedDevice.fwVersion, minFwForParamDetectV2)
      : false;

  const areUserSpecifiedParamsValid = (
    paramDetectV2Available &&
    paramIsNonNegativeNumber( userSpecifiedParams.paramDetectMaxRpm ) &&
    paramIsNonNegativeNumber( userSpecifiedParams.paramDetectMaxCurrent ) );

  // Keep this in sync with the other place powerConfig is converted to numbers
  const powerConfig: IWritePowerConfigResponse = useMemo(() => {
    const {
      motorCurrentLimit,
      batteryCurrentLimit,
      overtemperatureThreshold,
    } = powerConfigStr;

    return {
      motorCurrentLimit:        parseFloat(motorCurrentLimit),
      batteryCurrentLimit:      parseFloat(batteryCurrentLimit),
      overtemperatureThreshold: parseFloat(overtemperatureThreshold),
      // Unused
      criticalOvervoltageThreshold:     0,
      criticalUndervoltageThreshold:    0,
      overcurrentThresholdDeprecated:     0,
      warningOvervoltageThreshold:      0,
      warningUndervoltageThreshold:     0,
      warningMotorCurrentThreshold:      0,
      criticalMotorCurrentThreshold:     0,
    };
  }, [ powerConfigStr ] );

  const run = useCallback(async () => {
    if (connectedDevice === undefined) {
      return;
    }

    setParamDetectErrorMessage( undefined ); // Clear error message, just in case
    setIsRunning(true);
    try {
      const userSpecifiedParamsBody: IStartParamDetectRequest = {
        paramDetectMaxRpm: parseFloat(userSpecifiedParams.paramDetectMaxRpm),
        paramDetectMaxCurrent: parseFloat(userSpecifiedParams.paramDetectMaxCurrent),
      };

      const polePairCountValue = paramDetectV2Available ? polePairCount : undefined;

      const response = await runParameterDetection(
        connectedDevice.handle,
        powerConfig,
        userSpecifiedParamsBody,
        polePairCountValue,
      );
      const paramDetectResults = {
        ...response,
        ...userSpecifiedParamsBody,
      }

      setParamDetect(paramDetectResults);
    } catch (e) {
      // ToDo: consider showing this to user
      console.warn(e);
      setParamDetectErrorMessage( (e as any).toString() );
    } finally {
      setIsRunning(false);
    }
  }, [
    connectedDevice,
    paramDetectV2Available,
    polePairCount,
    setParamDetect,
    userSpecifiedParams.paramDetectMaxCurrent,
    userSpecifiedParams.paramDetectMaxRpm,
    powerConfig,
  ]);

  const getValue = useCallback(
    (key: keyof NonNullable<typeof paramDetect>) => {
      /* commenting this out so that the values update as they are generated
        if ( isRunning ) {
            return <LoadingDots />;
        }
        */

      return paramDetect?.[key].toExponential(6) || null;
    },
    [paramDetect]
  );

  const handleManualInputToggle = () => {
    if (manualParamInput) {
      setManualParamInput(false);
    } else {
      setShowWarningModal(true);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: Colors.WHITE,
        }}
      >
        <div
          style={{
            display: "flex",
            fontSize: 24,
            color: Colors.WHITE,
            fontWeight: "bold",
            marginBottom: 16,
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {manualParamInput
            ? "Parameter Detection - (Manual Input)"
            : "Automated Parameter Detection"}
        </div>
        {/* Manual entry */}
        {paramDetectV2Available ? (
          <>
            {/* Note label */}
            <div
              style={{
                color: Colors.WHITE,
                marginBottom: 16,
                paddingLeft: 8,
                paddingRight: 8,
                display: "flex",
              }}
            >
              <div style={{ fontWeight: "bold" }}>Note:&nbsp;</div> Please
              provide the following characteristics for the connected motor.
            </div>
            {/* Form area */}
            <div
              style={{
                width: FORM_WIDTH,
                maxWidth: "100%",
                marginBottom: 8,
                display: "flex",
                justifyContent: "start",
                flexDirection: "column"
              }}
            >
              <FormGroup
                label="Max Rated Motor Speed (RPM)"
                labelInfo={<span style={{ color: Colors.RED }}>*</span>}
              >
                <NumericInput
                  fill={true}
                  value={userSpecifiedParams.paramDetectMaxRpm}
                  onValueChange={(_, valStr) =>
                    editUserSpecifiedParams("paramDetectMaxRpm", valStr)
                  }
                  stepSize={ 1e-15 /* accept any floating point */ }
                  minorStepSize={ null }
                  buttonPosition="none"
                  placeholder="3000 RPM"
                  style={{
                    backgroundColor: Colors.DARK_GREEN,
                    color: Colors.WHITE,
                  }}
                />
              </FormGroup>
              <FormGroup
                label="Max Rated Motor Current (A)"
                labelInfo={<span style={{ color: Colors.RED }}>*</span>}
              >
                <NumericInput
                  fill={true}
                  value={userSpecifiedParams.paramDetectMaxCurrent}
                  onValueChange={(_, valStr) =>
                    editUserSpecifiedParams("paramDetectMaxCurrent", valStr)
                  }
                  stepSize={ 1e-15 /* accept any floating point */ }
                  minorStepSize={ null }
                  buttonPosition="none"
                  placeholder="5 A"
                  style={{
                    backgroundColor: Colors.DARK_GREEN,
                    color: Colors.WHITE,
                  }}
                />
              </FormGroup>
            </div>
          </>
        ) : null}
        {manualParamInput ? (
          <div
            style={{
              width: FORM_WIDTH,
              maxWidth: "100%",
              display: "flex",
              flexDirection: "column",
              marginBottom: 16,
            }}
          >
            <div style={{ flex: 1, display: "flex" }}>
              <div style={labelStyle}>
                Resistance ({displayUnits[ PacketId.START_PARAM_DETECT ].resistance}):
              </div>
              <InputGroup
                style={valueStyle}
                value={manualParamDetect.resistance}
                onChange={(e) => editParamConfig("resistance", e.target.value)}
              />
            </div>
            <div style={{ flex: 1, display: "flex" }}>
              <div style={labelStyle}>
                Ld ({displayUnits[ PacketId.START_PARAM_DETECT ].ld}):
              </div>
              <InputGroup
                style={valueStyle}
                value={manualParamDetect.ld}
                onChange={(e) => editParamConfig("ld", e.target.value)}
              />
            </div>
            <div style={{ flex: 1, display: "flex" }}>
              <div style={labelStyle}>
                Lq ({displayUnits[ PacketId.START_PARAM_DETECT ].lq}):
              </div>
              <InputGroup
                style={valueStyle}
                value={manualParamDetect.lq}
                onChange={(e) => editParamConfig("lq", e.target.value)}
              />
            </div>
            <div style={{ flex: 1, display: "flex" }}>
              <div style={labelStyle}>
                Flux Linkage ({displayUnits[ PacketId.START_PARAM_DETECT ].fluxLinkage}):
              </div>
              <InputGroup
                style={valueStyle}
                value={manualParamDetect.fluxLinkage}
                onChange={(e) => editParamConfig("fluxLinkage", e.target.value)}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              width: FORM_WIDTH,
              maxWidth: "100%",
              display: "flex",
              flexDirection: "column",
              marginBottom: 16,
            }}
          >
            <div style={{ flex: 1, display: "flex" }}>
              <div style={labelStyle}>
              Resistance ({displayUnits[ PacketId.START_PARAM_DETECT ].resistance}):
              </div>
              <div style={valueStyle}>
                {getValue("resistance")}
              </div>
            </div>
            <div style={{ flex: 1, display: "flex" }}>
              <div style={labelStyle}>
                Ld ({displayUnits[ PacketId.START_PARAM_DETECT ].ld}):
              </div>
              <div style={valueStyle}>
                {getValue("ld")}
              </div>
            </div>
            <div style={{ flex: 1, display: "flex" }}>
              <div style={labelStyle}>
                Lq ({displayUnits[ PacketId.START_PARAM_DETECT ].lq}):
              </div>
              <div style={valueStyle}>
                {getValue("lq")}
              </div>
            </div>
            <div style={{ flex: 1, display: "flex" }}>
              <div style={labelStyle}>
                Flux Linkage ({displayUnits[ PacketId.START_PARAM_DETECT ].fluxLinkage}):
              </div>
              <div style={valueStyle}>
                {getValue("fluxLinkage")}
              </div>
            </div>
          </div>
        )}
        {/* Warning message about running unloaded and emitting a loud noise */}
        <div
          style={{
            color: Colors.YELLOW,
            paddingLeft: 8,
            paddingRight: 8,
            display: "flex",
          }}
        >
          <div style={{ fontWeight: "bold" }}>Warning:&nbsp;</div> After clicking
          "{buttonText}" below, the connected motor will emit noise and spin.
        </div>
        <div
          style={{
            color: Colors.YELLOW,
            marginBottom: 16,
            paddingLeft: 8,
            paddingRight: 8,
            display: "flex",
          }}
        >
          Please ensure the connected motor is <b>&nbsp;unloaded&nbsp;</b>
          before proceeding.
        </div>
        {/* Back and next buttons */}
        <div style={{ display: "flex", width: FORM_WIDTH }}>
          <div style={{ flex: 1 }}>
            <Button
              backgroundColor={Colors.WHITE}
              textColor={Colors.BLACK}
              onClick={prev}
              disabled={prev === undefined}
            >
              Back
            </Button>
          </div>
          <div style={{ width: 8 }} />
          <div style={{ flex: 1 }}>
            <Button
              backgroundColor={buttonBackgroundColor}
              textColor={buttonTextColor}
              disabled={
                connectedDevice === undefined ||
                isRunning ||
                !powerConfigValid || // Sent to device prior to starting param detect
                (paramDetectV2Available && !areUserSpecifiedParamsValid) // disabled if invalid user-specified params
              }
              onClick={run}
            >
              {buttonText}
            </Button>
          </div>
          <div style={{ width: 8 }} />
          <div style={{ flex: 1 }}>
            <Button
              backgroundColor={Colors.BUTTON_GREEN}
              textColor={Colors.WHITE}
              onClick={next}
              disabled={next === undefined}
            >
              Next
            </Button>
          </div>
        </div>
        {/* Manual input buttons */}
        <div style={{ marginTop: 16, display: "flex", width: FORM_WIDTH }}>
          <div style={{ flex: 1 }}>
            <Button
              backgroundColor={Colors.YELLOW}
              textColor={Colors.BLACK}
              onClick={handleManualInputToggle}
            >
              {manualParamInput
                ? "Disable Manual Input"
                : "Enable Manual Input"}
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        title={"Parameter Detection Failure"}
        isOpen={ Boolean( paramDetectErrorMessage ) }
        onClose={() => setParamDetectErrorMessage( undefined )}
        style={{ backgroundColor: Colors.LIGHT_GREEN }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 30,
            paddingBottom: 10,
            color: Colors.WHITE,
          }}
        >
          <p>
            A problem was encountered during parameter detection:<br />
            { paramDetectErrorMessage }
          </p>
        </div>
      </Dialog>
      <Dialog
        title="Warning"
        isOpen={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        style={{ backgroundColor: Colors.LIGHT_GREEN }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 30,
            paddingBottom: 10,
            color: Colors.WHITE,
          }}
        >
          <p>
            {" "}
            This is not recommended, and incorrect values may result in damage
            to the controller. Please contact Salient Motion for support. Do you
            wish to continue?
          </p>
          <div style={{ alignSelf: "flex-end", display: "flex" }}>
            <Button
              backgroundColor={Colors.RED}
              textColor={Colors.BLACK}
              style={{ marginRight: 10 }}
              onClick={() => {
                setShowWarningModal(false);
                setManualParamInput(false);
              }}
            >
              Cancel
            </Button>
            <Button
              backgroundColor={Colors.WHITE}
              textColor={Colors.BLACK}
              onClick={() => {
                setShowWarningModal(false);
                setManualParamInput(true);
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const sensorsAreValid = ( sensorsConfig: Strings<SensorsConfig> ): boolean => {
  // sensor mode is encoder
  if ( parseInt(sensorsConfig.sensorMode, 10) === SensorMode.ENCODER ) {
    return encoderCprIsValid( sensorsConfig.encoderCpr );
  }

  // sensor mode is open loop
  if ( parseFloat(sensorsConfig.sensorMode) === SensorMode.OPENLOOP ) {
    return openLoopAccelerationIsValid( sensorsConfig.accelerationRamp_mkradps2 );
  }
  return true;
}

const encoderCprIsValid = ( encoderCprStr: string ): boolean => {
  let encoderCpr = parseFloat(encoderCprStr);
  return Number.isInteger(encoderCpr) && ( 0 <= encoderCpr ) && ( encoderCpr <= 8192 );
}

// Input is mech. rad/s^2
const openLoopAccelerationIsValid = ( accelerationRampStr_mkradps2: string | undefined ): boolean => {
  if ( accelerationRampStr_mkradps2 === undefined ) return false; // ToDo: can remove this case once config versioning is setup (see CUID 86a329w7c)
  let ramp = parseFloat(accelerationRampStr_mkradps2);
  return (ramp >= 0) && (ramp <= MAX_OPEN_LOOP_ACCEL_MKRADPS2);
}

const sensorErrors = ( connectedDevice: Device | undefined, sensorsConfig: Strings<SensorsConfig>, encoderState?: IEncoderState ): string[] => {
  let errors = [];

  if ( connectedDevice === undefined ) {
    errors.push("A device must be connected.");
  }

  const sensorModeNeedsUpdate = ( parseInt(sensorsConfig.sensorMode, 10) !== encoderState?.sensorMode );
  const encoderCprNeedsUpdate = ( sensorsConfig.sensorMode === SensorMode.ENCODER.toString() &&
                                 parseInt(sensorsConfig.encoderCpr, 10) !== encoderState?.encoderCpr );
  if ( sensorModeNeedsUpdate || encoderCprNeedsUpdate ) {
    errors.push("The sensor settings need to be saved to the Controller with Apply Sensor Settings button.");
  }

  return errors;
};

// Superset of sensorErrors()
const calibrateErrors = ( connectedDevice: Device | undefined, sensorsConfig: Strings<SensorsConfig>, encoderState?: IEncoderState ): string[] => {
  let errors = [ ...sensorErrors( connectedDevice, sensorsConfig, encoderState ) ];

  // Check for encoder CPR validity
  if ( !encoderCprIsValid( sensorsConfig.encoderCpr ) ) {
    errors.push("Encoder CPR must be an integer from 0 to 8192 inclusive.");
  }

  // Check for open loop acceleration ramp validity
  // ToDo: move this block to sensorErrors as long as it doesn't break anything
  if ( !openLoopAccelerationIsValid( sensorsConfig.accelerationRamp_mkradps2 ) ) {
    errors.push(`Open Loop Acceleration must be a floating point value from 0 to ${MAX_OPEN_LOOP_ACCEL_MKRADPS2} inclusive.`);
  }

  return errors;
};

/*
 * Relevant device commands:
 *
 * Command-response:
 *
 *   S Labs sends START_ENCODER_CAL to start calibration. When completed or failed, controller replies with ENCODER_CAL_RESULT.
 *
 * Asynchronous:
 *
 *   At any time, S Labs may send a GET_ENCODER_CAL_RESULT request to controller. Controller replies with GET_ENCODER_CAL_RESULT response.
 *
 * */
const SensorsScreen = (
  props: INavigationProps & {
    sensorsConfig: Strings<SensorsConfig>,
    editSensorsConfig: Edit<SensorsConfig>,
    saveSensorSettings: () => void,
    saveInProgress: boolean,
    setWarningContents: ( contents: ReactNode ) => void,
  }
) => {
  // Note that encoderState and setEncoderState are for what is in the controller!
  const { connectedDevice, encoderState, setEncoderState, hallSensorState, setHallSensorState } = useDevices();
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const {
    setEnableNavigation,
    next,
    prev,
    sensorsConfig,
    editSensorsConfig,
    saveSensorSettings,
    saveInProgress,
    setWarningContents,
  } = props;
  const [selectedSensorMode, setSelectedSensorMode] = useState(sensorsConfig.sensorMode);
  const [showCalibrationResultMessage, setShowCalibrationResultMessage] = useState<boolean>(false);
  const lastValidEncoderCprRef = useRef<string>("0");
  const lastValidAccelerationRampRef = useRef<string>("0");

  const handleSensorModeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const sensorMode = e.target.value;
    setSelectedSensorMode(sensorMode);
    editSensorsConfig("sensorMode", sensorMode);

    // Ensure there is a valid encoder CPR before switching away from Encoder sensor mode
    if ( Number(sensorMode) !== SensorMode.ENCODER && !encoderCprIsValid( sensorsConfig.encoderCpr ) ) {
      // Revert to most recent valid encoderCpr
      editSensorsConfig("encoderCpr", lastValidEncoderCprRef.current);
    }

    // Ensure there is a valid open loop acceleration ramp before switching away from Open Loop sensor mode
    if ( Number(sensorMode) !== SensorMode.OPENLOOP && !openLoopAccelerationIsValid( sensorsConfig.accelerationRamp_mkradps2 ) ) {
      // Revert to most recent valid encoderCpr
      editSensorsConfig("accelerationRamp_mkradps2", lastValidAccelerationRampRef.current);
    }
  };

  // Wraps editSensorsConfig so that the most recent valid encoderCpr is available to be reverted to later.
  const updateEncoderCpr = useCallback( (newEncoderCpr: string) => {
    if ( encoderCprIsValid( newEncoderCpr ) ) {
      lastValidEncoderCprRef.current = newEncoderCpr;
    }
    editSensorsConfig("encoderCpr", newEncoderCpr);
  }, [ editSensorsConfig ]);

  // Wraps editSensorsConfig so that the most recent valid accelerationRamp_mkradps2 is available to be reverted to later.
  const updateAccelerationRamp = useCallback( (newAccelerationRamp: string) => {
    if ( openLoopAccelerationIsValid( newAccelerationRamp ) ) {
      lastValidAccelerationRampRef.current = newAccelerationRamp;
    }
    editSensorsConfig("accelerationRamp_mkradps2", newAccelerationRamp);
  }, [ editSensorsConfig ]);

  const uiSensorModeLabels = {
    [SensorMode.SENSORLESS]: "Sensorless HFI",
    [SensorMode.ENCODER]: "Encoder",
    [SensorMode.OPENLOOP]: "Open Loop Startup",
    [SensorMode.HALLSENSOR]: "Hall Sensor",
  };
  let sensorModeOptions = Object.keys(SensorMode)
    .filter((k) => isFinite(Number(k)))
    .map((key, _) => {
      let mode: SensorMode = Number(key);
      return {
        value: mode,
        label: (uiSensorModeLabels[mode] ?? SensorMode[mode])
      };
    });
  if ( !isSensorlessEnabled() ) {
    sensorModeOptions = sensorModeOptions.filter((opt) => opt.value !== SensorMode.SENSORLESS );
  }
  const showSensorlessNotice = ( isSensorlessEnabled() && !isSensorlessEnabled( Env.Prod ) &&
                                  selectedSensorMode === SensorMode.SENSORLESS.toString() );

  useEffect(() => {
    setEnableNavigation(!isRunning);
  }, [setEnableNavigation, isRunning]);

  // Calibration
  const calibrate = useCallback(async () => {
    if ( connectedDevice === undefined ) {
      return;
    }
    
    setIsRunning(true);
    if ( selectedSensorMode === SensorMode.ENCODER.toString() && encoderState !== undefined ) {
      try {
        let status = await runEncoderCalibration(connectedDevice.handle, encoderState, setEncoderState);
        if ( status !== EncoderCalibStatus.COMPLETE ) {
          setShowCalibrationResultMessage( true );
        }
      } catch (e) {
        // ToDo: consider showing this to user
        console.warn("Exception from runEncoderCalibration:", e);
      } finally {
        setIsRunning(false);
      }
    }
    if ( selectedSensorMode === SensorMode.HALLSENSOR.toString() && hallSensorState !== undefined ) {
      try {
        let status = await runHallSensorCalibration(connectedDevice.handle, hallSensorState, setHallSensorState);
        if ( status !== HallsensorCalibStatus.COMPLETE ) {
          setShowCalibrationResultMessage( true );
        }
      } catch (e) {
        console.warn("Exception from runHallSensorCalibration:", e);
      } finally {
        setIsRunning(false);
      }
    }
  }, [ connectedDevice, encoderState, setEncoderState, selectedSensorMode, hallSensorState, setHallSensorState ]);

  const encoderSelected = Number(sensorsConfig.sensorMode) === SensorMode.ENCODER;
  const openLoopSelected = Number(sensorsConfig.sensorMode) === SensorMode.OPENLOOP;
  const hallSensorSelected = Number(sensorsConfig.sensorMode) === SensorMode.HALLSENSOR;
  const offsetUnits = fullDisplayUnits[ PacketId.ENCODER_CAL_RESULT ][ 'offset' ];
  const forwardOffsetUnits = fullDisplayUnits[ PacketId.HALL_SENSOR_CAL_RESULT ][ 'forwardOffset' ];
  const reverseOffsetUnits = fullDisplayUnits[ PacketId.HALL_SENSOR_CAL_RESULT ][ 'reverseOffset' ];
  const isTimeout = encoderState?.status === EncoderCalibStatus.FAILURE_TIMEOUT;
  const encoderStatusStr = (encoderState?.status !== undefined) ? encoderCalibrationStatusToString(encoderState.status) : "---";
  const encoderOffsetStr = (encoderState !== undefined && isFinite(encoderState.offset)) ? encoderState.offset.toExponential(6) : undefined;
  const hallSensorStatusStr = (hallSensorState?.status !== undefined) ? hallSensorCalibrationStatusToString(hallSensorState.status) : "---";
  const hallSensorForwardOffsetStr = (hallSensorState !== undefined && isFinite(hallSensorState.forwardOffset)) ? hallSensorState.forwardOffset.toExponential(6) : undefined;
  const hallSensorReverseOffsetStr = (hallSensorState !== undefined && isFinite(hallSensorState.reverseOffset)) ? hallSensorState.reverseOffset.toExponential(6) : undefined;
  const hallSensorDirectionStr = (hallSensorState?.direction !== undefined) ? hallSensorDirectionToString(hallSensorState.direction) : "---";

  let encoderDirectionStr = undefined;
  if (encoderState !== undefined && encoderState.direction) {
    if (encoderState.direction === EncoderDirection.COUNT_UP) {
      encoderDirectionStr = "Forward"
    } else if (encoderState.direction === EncoderDirection.COUNT_DOWN) {
      encoderDirectionStr = "Reversed"
    }
  }

  const calibrateErrorsMemo = useMemo(() => calibrateErrors(connectedDevice, sensorsConfig, encoderState),
                                    [ connectedDevice, sensorsConfig, encoderState ]);
  const sensorErrorsMemo   = useMemo(() => sensorErrors(connectedDevice, sensorsConfig, encoderState),
                                    [ connectedDevice, sensorsConfig, encoderState ]);
  const calibrationBlocked = ( calibrateErrorsMemo.length > 0 );
  useEffect( () => {
    let errorArray = (encoderSelected || openLoopSelected) ? calibrateErrorsMemo : sensorErrorsMemo;
    if ( errorArray.length === 0 ) {
      setWarningContents( null );
      return;
    };

    const sensorsWarning = (
      <>
        <H4>{ encoderSelected ? "Calibration Blocked" : "Changes Not Applied" }</H4>
        <ul style={{ color: Colors.WHITE }} >
        {
          errorArray.map( (error, i) => <li key={ i } >{error}</li> )
        }
        </ul>
      </>
    );
    setWarningContents( sensorsWarning );
  }, [setWarningContents, calibrationBlocked, calibrateErrorsMemo, sensorErrorsMemo, encoderSelected, openLoopSelected] );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: Colors.WHITE,
        }}
      >
        <div
          style={{
            fontSize: 24,
            color: Colors.WHITE,
            fontWeight: "bold",
            marginBottom: 16,
          }}
        >
          Sensors Configuration
        </div>
        <div
          style={{
            width: FORM_WIDTH,
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
            marginBottom: 16,
          }}
        >
          <div style={{ flex: 1, display: "flex" }}>
            <FormGroup
              label="Sensor Mode"
            >
              <HTMLSelect
                style={{ backgroundColor: Colors.DARK_GREEN, color: Colors.WHITE, width: FORM_WIDTH }}
                fill={true}
                value={selectedSensorMode}
                options={sensorModeOptions}
                onChange={
                  handleSensorModeChange
                }
              />
            </FormGroup>
          </div>

          {
            showSensorlessNotice && (
              <div style={{ color: Colors.YELLOW }} >
                <p>Note: Sensorless HFI mode is disabled in production.</p>
              </div>
            )
          }

          {/* Inputs if encoder sensor mode is selected */}
          { encoderSelected &&
            <>
              <FormGroup
                label="Encoder CPR (counts per revolution)"
                labelInfo={<span style={{ color: Colors.RED }}>*</span>}
              >
                <NumericInput
                  fill={true}
                  value={sensorsConfig.encoderCpr}
                  onValueChange={(_, valStr) =>
                    updateEncoderCpr( valStr )
                  }
                  stepSize={ 1e-15 /* accept any floating point for now; integer validation happens later */ }
                  minorStepSize={ null }
                  buttonPosition="none"
                  placeholder="Encoder CPR"
                  style={{
                    backgroundColor: Colors.DARK_GREEN,
                    color: Colors.WHITE,
                  }}
                />
              </FormGroup>
              <FormGroup label="Encoder Status">
                <InputGroup
                  disabled={true}
                  value={encoderStatusStr}
                  placeholder="---"
                  style={{
                    backgroundColor: Colors.DARK_GREEN,
                    color: ((encoderCalibrationFailed(encoderState?.status)) ? Colors.RED : Colors.WHITE),
                  }}
                />
              </FormGroup>
              <FormGroup label={`Encoder Offset (${offsetUnits})`}>
                <NumericInput
                  disabled={true}
                  fill={true}
                  value={encoderOffsetStr}
                  stepSize={ 1e-15 /* accept any floating point */ }
                  minorStepSize={ null }
                  buttonPosition="none"
                  placeholder="---"
                  style={{
                    backgroundColor: Colors.DARK_GREEN,
                    color: Colors.WHITE,
                  }}
                />
              </FormGroup>
              <FormGroup label={`Encoder Direction`}>
                <NumericInput
                  disabled={true}
                  fill={true}
                  value={encoderDirectionStr}
                  buttonPosition="none"
                  placeholder="---"
                  style={{
                    backgroundColor: Colors.DARK_GREEN,
                    color: Colors.WHITE,
                  }}
                />
              </FormGroup>
            </>
          }

          {/* Hall sensor mode is selected */}
          { hallSensorSelected &&
            <>
              <FormGroup label="Hall Sensor Status">
                <InputGroup
                  disabled={true}
                  value={hallSensorStatusStr}
                  placeholder="---"
                  style={{
                    backgroundColor: Colors.DARK_GREEN,
                    color: ((hallSensorCalibrationFailed(hallSensorState?.status)) ? Colors.RED : Colors.WHITE),
                  }}
                />
              </FormGroup>
              <FormGroup label={`Hall Sensor Forward Offset (${forwardOffsetUnits})`}>
                <NumericInput
                  disabled={true}
                  fill={true}
                  value={hallSensorForwardOffsetStr}
                  stepSize={ 1e-15 /* accept any floating point */ }
                  minorStepSize={ null }
                  buttonPosition="none"
                  placeholder="---"
                  style={{
                    backgroundColor: Colors.DARK_GREEN,
                    color: Colors.WHITE,
                  }}
                />
              </FormGroup>
              <FormGroup label={`Hall Sensor Reverse Offset (${reverseOffsetUnits})`}>
                <NumericInput
                  disabled={true}
                  fill={true}
                  value={hallSensorReverseOffsetStr}
                  stepSize={ 1e-15 /* accept any floating point */ }
                  minorStepSize={ null }
                  buttonPosition="none"
                  placeholder="---"
                  style={{
                    backgroundColor: Colors.DARK_GREEN,
                    color: Colors.WHITE,
                  }}
                />
              </FormGroup>
              <FormGroup label={`Hall Sensor Direction`}>
                <NumericInput
                  disabled={true}
                  fill={true}
                  value={hallSensorDirectionStr}
                  buttonPosition="none"
                  placeholder="---"
                  style={{
                    backgroundColor: Colors.DARK_GREEN,
                    color: Colors.WHITE,
                  }}
                />
              </FormGroup>
            </>
          }

          {/* Inputs if open loop sensor mode is selected */}
          {
            openLoopSelected &&
            <>
              <FormGroup
                label="Motor Open Loop Acceleration (mech. rad/s^2)"
                labelInfo={<span style={{ color: Colors.RED }}>*</span>}
              >
                {/* Motor open loop acceleration */}
                <NumericInput
                  fill={true}
                  value={sensorsConfig.accelerationRamp_mkradps2}
                  onValueChange={(_, valStr) =>
                    updateAccelerationRamp( valStr )
                  }
                  stepSize={ 1e-15 /* accept any floating point */ }
                  minorStepSize={ null }
                  buttonPosition="none"
                  placeholder={ DEFAULT_OPEN_LOOP_ACCEL_MKRADPS2.toString() }
                  style={{
                    backgroundColor: Colors.DARK_GREEN,
                    color: Colors.WHITE,
                  }}
                />
              </FormGroup>
            </>
          }
        </div>
        {/* Calibrate button if encoder sensor mode is selected */}
        { (encoderSelected || hallSensorSelected) &&
          <>
            <div
              style={{
                color: Colors.YELLOW,
                paddingLeft: 8,
                paddingRight: 8,
                display: "flex",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Warning:&nbsp;</span> After
              clicking "Calibrate" below and confirming prompt, the connected
              motor will spin.
            </div>
            <div style={{ height: 16 }} />
            <div style={{ flex: 1, width: FORM_WIDTH }}>
              <Button
                backgroundColor={Colors.BUTTON_GREEN}
                textColor={Colors.WHITE}
                disabled={ isRunning || calibrationBlocked }
                onClick={() => setShowWarningModal( true )}
              >
                Calibrate
              </Button>
            </div>
            <div style={{ height: 16 }} />
          </>
        }
        <div style={{ display: "flex", width: FORM_WIDTH }}>
          <div style={{ flex: 1 }}>
            <Button
              backgroundColor={Colors.WHITE}
              textColor={Colors.BLACK}
              onClick={prev}
              disabled={prev === undefined}
            >
              Back
            </Button>
          </div>
          <div style={{ width: 8 }} />
          <div style={{ flex: 1 }} />
          <div style={{ width: 8 }} />
          <div style={{ flex: 1 }}>
            <Button
              backgroundColor={Colors.BUTTON_GREEN}
              textColor={Colors.WHITE}
              onClick={next}
              disabled={next === undefined}
            >
              Next
            </Button>
          </div>
        </div>
        <div style={{ height: 16 }} />
        <div style={{ display: "flex", width: FORM_WIDTH }}>
          <div style={{ flex: 1 }}>
            <Button
              backgroundColor={Colors.BUTTON_GREEN}
              textColor={Colors.WHITE}
              onClick={saveSensorSettings}
              disabled={!sensorsAreValid(sensorsConfig) || saveInProgress}
              tooltip="Save only this tab's settings to the device"
            >
              <b>Apply Sensor Settings</b>
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        title={"Calibration " + (isTimeout ? "Timeout" : "Failure")}
        isOpen={showCalibrationResultMessage}
        onClose={() => setShowCalibrationResultMessage( false )}
        style={{ backgroundColor: Colors.LIGHT_GREEN }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 30,
            paddingBottom: 10,
            color: Colors.WHITE,
          }}
        >
          {selectedSensorMode === SensorMode.ENCODER.toString() ?
          <p>
            A problem was detected during calibration — {encoderState && encoderCalibrationStatusToString(encoderState?.status)}.
          </p>
          
          : selectedSensorMode === SensorMode.HALLSENSOR.toString() ?
          <p>
            A problem was detected during calibration — {hallSensorState && hallSensorCalibrationStatusToString(hallSensorState?.status)}.
          </p>
          : null
        }
        </div>
      </Dialog>
      <Dialog
        title="Warning"
        isOpen={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        style={{ backgroundColor: Colors.LIGHT_GREEN }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 30,
            paddingBottom: 10,
            color: Colors.WHITE,
          }}
        >
          <p>
            Motor will spin if you select Run Calibration, below!<br />
            <span style={{color: Colors.YELLOW}}><b>Motor must run unloaded!</b></span>
          </p>
          <div style={{ alignSelf: "flex-end", display: "flex" }}>
            <Button
              backgroundColor={Colors.RED}
              textColor={Colors.BLACK}
              style={{ marginRight: 10 }}
              onClick={() => {
                setShowWarningModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              backgroundColor={Colors.WHITE}
              textColor={Colors.BLACK}
              onClick={() => {
                setShowWarningModal(false);
                calibrate();
              }}
            >
              Run Calibration
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export const isPolePairCountValid = ( polePairCount: string | number ): boolean => {
  const ppc = ( typeof polePairCount === 'string' ) ? parseFloat( polePairCount ) : polePairCount;
  return _isPolePairCountValid( ppc );
};

const throttleErrors = ( inputConfig: Strings<InputConfig>, controlConfig: Strings<IWriteControlConfigRequest> ): string[] => {
  type StepSizeInfo = {
    label: string,
    step: number,
    stepStr: string | undefined,
    units: string,
    minStep: number,
  };
  const stepSizes: StepSizeInfo[] = [
    {
      label: 'torque',
      step: parseFloat( inputConfig.ampsStep ?? "" ),
      stepStr: inputConfig.ampsStep,
      units: 'A',
      minStep: MIN_STEP,
    },
    {
      label: 'speed',
      step: parseFloat( inputConfig.rpmStep ?? "" ),
      stepStr: inputConfig.rpmStep,
      units: 'RPM',
      minStep: MIN_RPM_STEP,
    },
    {
      label: 'voltage',
      step: parseFloat( inputConfig.voltsStep ?? "" ),
      stepStr: inputConfig.voltsStep,
      units: 'V',
      minStep: MIN_STEP,
    },
  ];

  const createStepSizeErrorStr = ( info: StepSizeInfo ) => {
    const prefix = `Throttle step size for ${info.label} control mode`;
    if ( info.stepStr === "" ) {
      return `${prefix} must be supplied.`
    } else if ( !isFinite( info.step ) ) {
      return `${prefix} is ${info.stepStr} ${info.units} which is invalid.`
    } else {
      return `${prefix} is ${info.stepStr} ${info.units} which is less than minimum of ${info.minStep} ${info.units}.`;
    }
  };

  const isInputModeUSB = ( inputConfig.inputMode === InputMode.USB.toString() );
  let errors;
  const areStepSizesValidated = ( isInputModeUSB && isThrottleUnitsEnabled() );
  if ( areStepSizesValidated ) {
    errors = (stepSizes
                    .filter(( info: StepSizeInfo ) =>
                      !isFinite( info.step ) || info.step < info.minStep
                    )
                    .map(createStepSizeErrorStr));

    const controlMode: ControlMode = parseInt(controlConfig.controlMode, 10);
    const motorMaxSpeed = parseFloat(controlConfig.motorMaxSpeed); // Call this "Max Throttle Speed" in the UI

    if ( controlMode === ControlMode.SPEED ) {
      if ( !isFinite( motorMaxSpeed ) ) {
        errors.push( "Max Throttle Speed is an invalid value." );
      } else if ( inputConfig.rpmStep !== undefined ) {
        const step = parseFloat( inputConfig.rpmStep );
        if ( isFinite( step ) && motorMaxSpeed < step ) {
          errors.push( "Throttle Step Size cannot exceed Max Throttle Speed" );
        }
      }
    }
  } else {
    errors = [];

    // ToDo: improve errors below
    const { inputMode, inputLimitLower, inputLimitCenter, inputLimitUpper } =
      inputConfig;
    if (
      [inputMode, inputLimitLower, inputLimitCenter, inputLimitUpper].some(
        (v) => v === ""
      )
    ) {
      errors.push("One or more of these are blank: input mode, lower, center, and/or upper input limit.");
    } else if (
      parseInt(inputLimitLower, 10) > parseInt(inputLimitCenter, 10) ||
      parseInt(inputLimitCenter, 10) > parseInt(inputLimitUpper, 10) ||
      parseInt(inputLimitLower, 10) < 0
    ) {
      errors.push("Input limits must be as follows: 0 <= lower <= center <= upper.");
    }
  }

  if ( !isPolePairCountValid( controlConfig.polePairCount ) ) {
    errors.push("Pole pair count must be a positive integer.");
  }

  return errors;
}

const InputScreen = (
  props: INavigationProps & {
    inputConfig: Strings<InputConfig>;
    powerConfig: Strings<IWritePowerConfigResponse>;
    controlConfig: Strings<IWriteControlConfigRequest>;
    positionControlSettings: Strings<IWritePositionControlSettingsRequest>;
    editInputConfig: Edit<InputConfig>;
    editControlConfig: Edit<IWriteControlConfigRequest>;
    editPositionControlSettings: Edit<IWritePositionControlSettingsRequest>;
    setWarningContents: ( contents: ReactNode ) => void,
    savePositionControlSettings: () => void,
  }
) => {
  const {
    inputConfig,
    powerConfig,
    controlConfig,
    positionControlSettings,
    editInputConfig,
    editControlConfig,
    editPositionControlSettings,
    setWarningContents,
    prev,
    next,
    savePositionControlSettings,
  } = props;

  const lowerGreaterThanCenter =
    inputConfig.inputLimitLower !== "" &&
    inputConfig.inputLimitCenter !== "" &&
    parseInt(inputConfig.inputLimitLower, 10) >
      parseInt(inputConfig.inputLimitCenter, 10);
  const centerGreaterThanUpper =
    inputConfig.inputLimitCenter !== "" &&
    inputConfig.inputLimitUpper !== "" &&
    parseInt(inputConfig.inputLimitCenter, 10) >
      parseInt(inputConfig.inputLimitUpper, 10);

  const [selectedControlMode, setSelectedControlMode] = useState(controlConfig.controlMode);
  const [selectedPositionType, setSelectedPositionType] = useState(positionControlSettings.positionControlType);
  const [selectedPostitionUnits, setSelectedPositionUnits] = useState(positionControlSettings.positionUnits);
  const [selectedPositionResolution, setSelectedPositionResolution] = useState(positionControlSettings.positionResolution);
  const [showMinPositionModal, setShowMinPositionModal] = useState<boolean>(false);
  const [showMaxPositionModal, setShowMaxPositionModal] = useState<boolean>(false);
  const [showHomePositionModal, setShowHomePositionModal] = useState<boolean>(false);
  const [positionSensorValue, setPositionSensorValue] = useState<number>(0.0);
  const { connectedDevice } = useDevices();

  const isInputModeUSB = ( inputConfig.inputMode === InputMode.USB.toString() );
  const isDisplayingPhysicalUnits = ( isInputModeUSB && isThrottleUnitsEnabled() );
  const isControlModeSpeed = ( selectedControlMode === ControlMode.SPEED.toString() );
  const isControlModePosition = ( selectedControlMode === ControlMode.POSITION.toString() );
  const handleControlModeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const controlMode = e.target.value;
    setSelectedControlMode(controlMode);
    editControlConfig("controlMode", controlMode);
  }

  const handlePositionTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const positionType = e.target.value;
    setSelectedPositionType(positionType);
    editPositionControlSettings("positionControlType", positionType);
  }

  const handlePositionUnitsChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const positionUnits = e.target.value;
    setSelectedPositionUnits(positionUnits);
    editPositionControlSettings("positionUnits", positionUnits);
  }

  const handlePositionResolutionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const positionResolution = e.target.value;
    setSelectedPositionResolution(positionResolution);
    editPositionControlSettings("positionResolution", positionResolution);
    editInputConfig("inputLimitUpper", calculateInputResolution(parseInt(positionResolution), parseInt(positionControlSettings.travelDistance)));
  }

  const handleTravelDistanceChange = (value: number, valueStr: string) => {
    // setSelectedTravelDistance(valueStr);
    editPositionControlSettings("travelDistance", valueStr);
    editInputConfig("inputLimitUpper", calculateInputResolution(parseInt(selectedPositionResolution), value));
  }

  const calculateInputResolution = (resolution: number, travelDistance: number): string => {
    switch ( resolution) {
      case PositionResolution.VALUE_1E3:
        travelDistance *= 1000;  // Thousandths
        break;
      case PositionResolution.VALUE_1E2:
        travelDistance *= 100;  // Hundredths
        break;
      case PositionResolution.VALUE_1E1:
        travelDistance *= 10;  // Tenths
        break;
      default:
        travelDistance *= 1; // Ones
        break;
    }
    return travelDistance.toString();
  }

  let stepFieldLabel: string | undefined;
  let stepField: keyof InputConfig | undefined;

  switch ( selectedControlMode ) {
    case ControlMode.SPEED.toString():
      stepFieldLabel = "Throttle Step Size (RPM)"
      stepField = "rpmStep";
      break;
    case ControlMode.TORQUE.toString():
      stepFieldLabel = "Throttle Step Size (Amps)"
      stepField = "ampsStep";
      break;
    case ControlMode.VOLTAGE.toString():
      stepFieldLabel = "Throttle Step Size (Volts)"
      stepField = "voltsStep";
      break;
  }

  const throttleErrorsMemo = useMemo(
    () => throttleErrors( inputConfig, controlConfig ),
    [ inputConfig, controlConfig ],
  );

  useEffect( () => {
    if ( throttleErrorsMemo.length === 0 ) {
      setWarningContents( null );
      return;
    };

    const inputWarning = (
      <>
        <H4>Invalid Values</H4>
        <ul style={{ color: Colors.WHITE }} >
        {
          throttleErrorsMemo.map( (error, i) => <li key={ i } >{error}</li> )
        }
        </ul>
      </>
    );
    setWarningContents( inputWarning );
  }, [ setWarningContents, throttleErrorsMemo ] );

  const calibrateLimits = (limits: ISetPositionSensorLimitsRequest) => {
    if (connectedDevice === undefined) {
      return;
    }
    hidSend(
      connectedDevice.handle,
      serializeSetPositionSensorLimitsRequest(limits) )
      .catch( e => console.warn( "Failed to send sensor limits command:", e )
    );
  }

  const getPositionSensorReading = useCallback( () => {
    async function getPositionSensorReading() {
      const positionSensorValue = await packetThreadPoolApi.getPositionSensorReading();
      if ( positionSensorValue !== null) {
        setPositionSensorValue(parseFloat(positionSensorValue.toFixed(1)));
      }
    }
    getPositionSensorReading();
  }, [] );

  const setHomePosition = () => {
    setShowHomePositionModal(false);
    editInputConfig("inputLimitLower", calculateInputResolution(parseInt(selectedPositionResolution), 0));
    editInputConfig("inputLimitCenter", calculateInputResolution(parseInt(selectedPositionResolution), positionSensorValue));
  }

  useEffect( () => {
    getPositionSensorReading();
    const getSensorReadingInterval = setInterval( getPositionSensorReading, PULSE_INTERVAL_MS );
    return () => {
        clearInterval( getSensorReadingInterval );
    }
}, [ getPositionSensorReading ] );

  const controlModeOptions = [
              { value: ControlMode.TORQUE, label: "Torque" },
              // { value: ControlMode.VOLTAGE, label: "Voltage" }, // Disabled everywhere now.
              { value: ControlMode.SPEED, label: "Speed" },
              { value: ControlMode.POSITION, label: "Position" },
            ];

  const positionTypeOptions = [
    { value: PositionType.UNKNOWN, label: "Select" },
    { value: PositionType.LINEAR, label: "Linear" },
    { value: PositionType.ROTATIONAL, label: "Rotational" },
  ];

  const positionUnitsOptions = selectedPositionType === PositionType.LINEAR.toString() ? [
    { value: PositionUnits.UNKNOWN, label: "Select" },
    { value: PositionUnits.MILLIMETERS, label: "Millimeters" },
    { value: PositionUnits.INCHES, label: "Inches" },
  ] : selectedPositionType === PositionType.ROTATIONAL.toString() ? [
    { value: PositionUnits.UNKNOWN, label: "Select" },
    { value: PositionUnits.RADIANS, label: "Radians" },
    { value: PositionUnits.DEGREES, label: "Degrees" },
  ] : [
    { value: PositionUnits.UNKNOWN, label: "Select Position Type First" }
  ];

  const positionResolutionOptions = [
    { value: PositionResolution.UNKNOWN, label: "Select" },
    { value: PositionResolution.VALUE_1E0, label: "Ones" },
    { value: PositionResolution.VALUE_1E1, label: "Tenths" },
    { value: PositionResolution.VALUE_1E2, label: "Hundredths" },
    { value: PositionResolution.VALUE_1E3, label: "Thousandths" },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: Colors.WHITE,
      }}
    >
      <div
        style={{
          display: "flex",
          fontSize: 24,
          color: Colors.WHITE,
          fontWeight: "bold",
          marginBottom: 16,
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        Throttle Settings
      </div>
      <div style={{ width: FORM_WIDTH, maxWidth: "100%", marginBottom: 8 }}>
        {/* INPUT CONFIG OPTIONS */}
        {/* INPUT MODE */}
        <FormGroup
          label="Input Mode"
          labelInfo={<span style={{ color: Colors.RED }}>*</span>}
        >
          <HTMLSelect
            style={{ backgroundColor: Colors.DARK_GREEN, color: Colors.WHITE }}
            fill={true}
            value={inputConfig.inputMode}
            options={[
              { value: InputMode.NONE, label: "None"},
              { value: InputMode.CAN, label: "CAN" },
              { value: InputMode.SERVO, label: "Servo" },
              { value: InputMode.USB, label: "USB" },
            ]}
            onChange={(e) => editInputConfig("inputMode", e.target.value)}
          />
        </FormGroup>

        {/* CONTROL MODE CONFIGURATION OPTIONS */}

        {/* POLE PAIR COUNT */}
        <FormGroup
          label="Pole Pair Count"
          labelInfo={<span style={{ color: Colors.RED }}>*</span>}
        >
          <NumericInput
            fill={true}
            value={controlConfig.polePairCount}
            onValueChange={(_, valStr) =>
              editControlConfig("polePairCount", valStr)
            }
            stepSize={ 1e-15 /* accept any floating point for now; integer validation happens later */ }
            minorStepSize={ null }
            buttonPosition="none"
            placeholder="Pole Pair Count"
            style={{
              backgroundColor: Colors.DARK_GREEN,
              color: Colors.WHITE,
            }}
          />
        </FormGroup>

        {/* CONTROL MODE */}
        <FormGroup
          label="Control Mode"
          labelInfo={<span style={{ color: Colors.RED }}>*</span>}
        >
          <HTMLSelect
            style={{ backgroundColor: Colors.DARK_GREEN, color: Colors.WHITE }}
            fill={true}
            value={selectedControlMode}
            options={controlModeOptions}
            onChange={
              handleControlModeChange
            }
          />
        </FormGroup>

        {/* MAX THROTTLE SPEED */}
        {isControlModeSpeed && (
          <FormGroup
            label="Max Throttle Speed (RPM)"
            labelInfo={<span style={{ color: Colors.RED }}>*</span>}
          >
            <NumericInput
              fill={true}
              value={controlConfig.motorMaxSpeed}
              onValueChange={(_, valStr) =>
                editControlConfig("motorMaxSpeed", valStr)
              }
              stepSize={ 1e-15 /* accept any floating point */ }
              minorStepSize={ null }
              buttonPosition="none"
              placeholder="Max Throttle Speed"
              style={{
                backgroundColor: Colors.DARK_GREEN,
                color: Colors.WHITE,
              }}
            />
          </FormGroup>
        )}

        {/* SPEED CONTROL PROPORTIONAL GAIN */}
        {
          isControlModeSpeed &&
          <FormGroup
          label="Speed Control Proportional Gain (N-m/s/(mech. rad))"
          labelInfo={<span style={{ color: Colors.RED }}>*</span>}
        >
          <NumericInput
            fill={true}
            value={controlConfig.speedControlProportionalGain}
            onValueChange={(_, valStr) =>
              editControlConfig("speedControlProportionalGain", valStr)
            }
            stepSize={ 1e-15 /* accept any floating point */ }
            minorStepSize={ null }
            buttonPosition="none"
            placeholder="Speed Control Proportional Gain"
            style={{
              backgroundColor: Colors.DARK_GREEN,
              color: Colors.WHITE,
            }}
          />
        </FormGroup>
        }

        {/* SPEED CONTROL INTEGRAL GAIN */}
        {
          isControlModeSpeed &&
          <FormGroup
          label="Speed Control Integral Gain (N-m/(mech. rad))"
          labelInfo={<span style={{ color: Colors.RED }}>*</span>}
        >
          <NumericInput
            fill={true}
            value={controlConfig.speedControlIntegralGain}
            onValueChange={(_, valStr) =>
              editControlConfig("speedControlIntegralGain", valStr)
            }
            stepSize={ 1e-15 /* accept any floating point */ }
            minorStepSize={ null }
            buttonPosition="none"
            placeholder="Speed Control Integral Gain"
            style={{
              backgroundColor: Colors.DARK_GREEN,
              color: Colors.WHITE,
            }}
          />
        </FormGroup>
        }

        {/* Position P Gain (float: 0-10.0) */}
        {isControlModePosition && (
          <FormGroup
            label={<>Position P Gain <span style={{ color: Colors.RED }}>*</span></>}
            labelInfo={<div style={{ display: "inline-flex", alignItems: "center" }}><Tooltip2 content={P_GAIN_TEXT}><Icon icon="help" style={{ color: Colors.WHITE }}></Icon></Tooltip2></div>}
          >
            <NumericInput
              fill={true}
              value={positionControlSettings.positionControlProportionalGain}
              onValueChange={(_, valStr) =>
                editPositionControlSettings("positionControlProportionalGain", valStr)
              }
              stepSize={ 1e-15 /* accept any floating point */ }
              minorStepSize={ null }
              buttonPosition="none"
              placeholder="Position Control Proportional Gain"
              style={{
                backgroundColor: Colors.DARK_GREEN,
                color: Colors.WHITE,
              }}
            />
          </FormGroup>
        )}

        {/* Position I Gain (float: 0-10.0) */}
        {isControlModePosition && (
          <FormGroup
            label={<>Position I Gain <span style={{ color: Colors.RED }}>*</span></>}
            labelInfo={<div style={{ display: "inline-flex", alignItems: "center" }}><Tooltip2 content={I_GAIN_TEXT}><Icon icon="help" style={{ color: Colors.WHITE }}></Icon></Tooltip2></div>}
          >
            <NumericInput
              fill={true}
              value={positionControlSettings.positionControlIntegralGain}
              onValueChange={(_, valStr) =>
                editPositionControlSettings("positionControlIntegralGain", valStr)
              }
              stepSize={ 1e-15 /* accept any floating point */ }
              minorStepSize={ null }
              buttonPosition="none"
              placeholder="Position Control Integral Gain"
              style={{
                backgroundColor: Colors.DARK_GREEN,
                color: Colors.WHITE,
              }}
            />
          </FormGroup>
        )}

        {/* Position D Gain (float: 0-10.0) */}
        {isControlModePosition && (
          <FormGroup
            label={<>Position D Gain <span style={{ color: Colors.RED }}>*</span></>}
            labelInfo={<div style={{ display: "inline-flex", alignItems: "center" }}><Tooltip2 content={D_GAIN_TEXT}><Icon icon="help" style={{ color: Colors.WHITE }}></Icon></Tooltip2></div>}
          >
            <NumericInput
              fill={true}
              value={positionControlSettings.positionControlDerivGain}
              onValueChange={(_, valStr) =>
                editPositionControlSettings("positionControlDerivGain", valStr)
              }
              stepSize={ 1e-15 /* accept any floating point */ }
              minorStepSize={ null }
              buttonPosition="none"
              placeholder="Position Control Derivative Gain"
              style={{
                backgroundColor: Colors.DARK_GREEN,
                color: Colors.WHITE,
              }}
            />
          </FormGroup>
        )}

        {/* Max Speed (rad/s) (float: 0-3000.0) */}
        {isControlModePosition && (
          <FormGroup
            label={<>Max Speed <span style={{ color: Colors.RED }}>*</span></>}
            labelInfo={<div style={{ display: "inline-flex", alignItems: "center" }}><Tooltip2 content={MOTOR_MAX_SPEED_TEXT}><Icon icon="help" style={{ color: Colors.WHITE }}></Icon></Tooltip2></div>}
          >
            <NumericInput
              fill={true}
              value={positionControlSettings.positionControlMaxSpeed}
              onValueChange={(_, valStr) =>
                editPositionControlSettings("positionControlMaxSpeed", valStr)
              }
              stepSize={ 1e-15 /* accept any floating point */ }
              minorStepSize={ null }
              buttonPosition="none"
              placeholder="Max Speed"
              style={{
                backgroundColor: Colors.DARK_GREEN,
                color: Colors.WHITE,
              }}
            />
          </FormGroup>
        )}

        {/* Max Acceleration (rad/s^2) (float: 0-30000.0) */}
        {isControlModePosition && (
          <FormGroup
            label={<>Max Acceleration <span style={{ color: Colors.RED }}>*</span></>}
            labelInfo={<div style={{ display: "inline-flex", alignItems: "center" }}><Tooltip2 content={MOTOR_MAX_ACCELERATION_TEXT}><Icon icon="help" style={{ color: Colors.WHITE }}></Icon></Tooltip2></div>}
          >
            <NumericInput
              fill={true}
              value={positionControlSettings.positionControlMaxAccel}
              onValueChange={(_, valStr) =>
                editPositionControlSettings("positionControlMaxAccel", valStr)
              }
              stepSize={ 1e-15 /* accept any floating point */ }
              minorStepSize={ null }
              buttonPosition="none"
              placeholder="Max Acceleration"
              style={{
                backgroundColor: Colors.DARK_GREEN,
                color: Colors.WHITE,
              }}
            />
          </FormGroup>
        )}

        {/* Deadband (rad) (float: 0-10.0) */}
        {isControlModePosition && (
          <FormGroup
            label={<>Deadband <span style={{ color: Colors.RED }}>*</span></>}
            labelInfo={<div style={{ display: "inline-flex", alignItems: "center" }}><Tooltip2 content={DEADBAND_TEXT}><Icon icon="help" style={{ color: Colors.WHITE }}></Icon></Tooltip2></div>}
          >
            <NumericInput
              fill={true}
              value={positionControlSettings.positionControlDeadband}
              onValueChange={(_, valStr) =>
                editPositionControlSettings("positionControlDeadband", valStr)
              }
              stepSize={ 1e-15 /* accept any floating point */ }
              minorStepSize={ null }
              buttonPosition="none"
              placeholder="Deadband"
              style={{
                backgroundColor: Colors.DARK_GREEN,
                color: Colors.WHITE,
              }}
            />
          </FormGroup>
        )}

        {/* POSITION TYPE */}
        {isControlModePosition && (
        <FormGroup
          label={<>Position Type <span style={{ color: Colors.RED }}>*</span></>}
          labelInfo={<div style={{ display: "inline-flex", alignItems: "center" }}><Tooltip2 content={POSITION_TYPE_TEXT}><Icon icon="help" style={{ color: Colors.WHITE }}></Icon></Tooltip2></div>}
        >
          <HTMLSelect
            style={{ backgroundColor: Colors.DARK_GREEN, color: Colors.WHITE }}
            fill={true}
            value={selectedPositionType}
            options={positionTypeOptions}
            onChange={
              handlePositionTypeChange
            }
          />
        </FormGroup>
        )}

        {/* POSITION UNITS */}
        {isControlModePosition && (
        <FormGroup
          label={<>Position Units <span style={{ color: Colors.RED }}>*</span></>}
          labelInfo={<div style={{ display: "inline-flex", alignItems: "center" }}><Tooltip2 content={POSITION_UNITS_TEXT}><Icon icon="help" style={{ color: Colors.WHITE }}></Icon></Tooltip2></div>}
        >
          <HTMLSelect
            style={{ backgroundColor: Colors.DARK_GREEN, color: Colors.WHITE }}
            fill={true}
            value={selectedPostitionUnits}
            options={positionUnitsOptions}
            onChange={
              handlePositionUnitsChange
            }
          />
        </FormGroup>
        )}

        {/* RESOLUTION */}
        {isControlModePosition && (
        <FormGroup
          label={<>Resolution <span style={{ color: Colors.RED }}>*</span></>}
          labelInfo={<div style={{ display: "inline-flex", alignItems: "center" }}><Tooltip2 content={RESOLUTION_TEXT}><Icon icon="help" style={{ color: Colors.WHITE }}></Icon></Tooltip2></div>}
        >
          <HTMLSelect
            style={{ backgroundColor: Colors.DARK_GREEN, color: Colors.WHITE }}
            fill={true}
            value={selectedPositionResolution}
            options={positionResolutionOptions}
            onChange={
              handlePositionResolutionChange
            }
          />
        </FormGroup>
        )}

        {/* Travel Distance */}
        {isControlModePosition && (
          <FormGroup
            label={<>Travel Distance <span style={{ color: Colors.RED }}>*</span></>}
            labelInfo={<div style={{ display: "inline-flex", alignItems: "center" }}><Tooltip2 content={TRAVEL_DISTANCE_TEXT}><Icon icon="help" style={{ color: Colors.WHITE }}></Icon></Tooltip2></div>}
          >
            <NumericInput
              fill={true}
              value={positionControlSettings.travelDistance}
              onValueChange={handleTravelDistanceChange}
              stepSize={ 1e-15 /* accept any floating point */ }
              minorStepSize={ null }
              buttonPosition="none"
              placeholder="Travel Distance"
              style={{
                backgroundColor: Colors.DARK_GREEN,
                color: Colors.WHITE,
              }}
            />
          </FormGroup>
        )}

        {/* Save Position Control Settings */}
        {isControlModePosition && (
        <div style={{ display: "flex", width: FORM_WIDTH, marginBottom: "0.7em" }}>
          <div style={{ flex: 1 }}>
            <Button
              backgroundColor={Colors.WHITE}
              textColor={Colors.BLACK}
              onClick={savePositionControlSettings}
            >
              Save Position Control Settings
            </Button>
          </div>
        </div>
        )}

        {/* Calibrate Min position */}
        {isControlModePosition && (
        <div style={{ display: "flex", width: FORM_WIDTH, marginBottom: "0.7em" }}>
          <div style={{ flex: 1 }}>
            <Button
              backgroundColor={Colors.WHITE}
              textColor={Colors.BLACK}
              onClick={() => setShowMinPositionModal(true)}
            >
              Calibrate Min position
            </Button>
          </div>
        </div>
        )}

        {isControlModePosition && (
        <Dialog
          title="Calibrate Min Position"
          isOpen={showMinPositionModal}
          onClose={() => setShowMinPositionModal(false)}
          style={{ backgroundColor: Colors.LIGHT_GREEN }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 30,
              paddingBottom: 10,
              color: Colors.WHITE,
            }}
          >
            <div
              style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}
            >
            <p>
              <h1>
              Move the motor to the minimum position and click Calibrate.
              </h1>
            </p>
            </div>
            <div style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
              <Button
                backgroundColor={Colors.WHITE}
                textColor={Colors.BLACK}
                onClick={() => {
                  setShowMinPositionModal(false);
                  calibrateLimits({setCurrentPosToMin: 1, setCurrentPosToMax: 0});
                }}
              >
                Calibrate
              </Button>
            </div>
          </div>
        </Dialog>
        )}

        {/* Calibrate Max position */}
        {isControlModePosition && (
        <div style={{ display: "flex", width: FORM_WIDTH, marginBottom: "0.7em" }}>
          <div style={{ flex: 1 }}>
            <Button
              backgroundColor={Colors.WHITE}
              textColor={Colors.BLACK}
              onClick={() => setShowMaxPositionModal(true)}
            >
              Calibrate Max position
            </Button>
          </div>
        </div>
        )}

        {isControlModePosition && (
        <Dialog
          title="Calibrate Max Position"
          isOpen={showMaxPositionModal}
          onClose={() => setShowMaxPositionModal(false)}
          style={{ backgroundColor: Colors.LIGHT_GREEN }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 30,
              paddingBottom: 10,
              color: Colors.WHITE,
            }}
          >
            <div
              style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}
            >
            <p>
              <h1>
                Move the motor to the maximum position and click Calibrate.
              </h1>
            </p>
            </div>
            <div style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
              <Button
                backgroundColor={Colors.WHITE}
                textColor={Colors.BLACK}
                onClick={() => {
                  setShowMaxPositionModal(false);
                  calibrateLimits({setCurrentPosToMin: 0, setCurrentPosToMax: 1});
                }}
              >
                Calibrate
              </Button>
            </div>
          </div>
        </Dialog>
        )}

        {/* Calibrate Home position */}
        {isControlModePosition && (
        <div style={{ display: "flex", width: FORM_WIDTH, marginBottom: "0.7em" }}>
          <div style={{ flex: 1 }}>
            <Button
              backgroundColor={Colors.WHITE}
              textColor={Colors.BLACK}
              onClick={() => setShowHomePositionModal(true)}
            >
              Calibrate Home position
            </Button>
          </div>
        </div>
        )}

        {isControlModePosition && (
        <Dialog
          title="Calibrate Home Position"
          isOpen={showHomePositionModal}
          onClose={() => setShowHomePositionModal(false)}
          style={{ backgroundColor: Colors.LIGHT_GREEN }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 30,
              paddingBottom: 10,
              color: Colors.WHITE,
            }}
          >
            <div
              style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}
            >
            <p>
              <h1>
                {positionSensorValue}
              </h1>
            </p>
            </div>
            <div style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
              <Button
                backgroundColor={Colors.WHITE}
                textColor={Colors.BLACK}
                onClick={() => {
                  setHomePosition();
                }}
              >
                Calibrate
              </Button>
            </div>
          </div>
        </Dialog>
        )}

        {/* MTPA */}
        <FormGroup
            label="MTPA"
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span style={{ fontWeight: 'bold', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)' }}>{parseInt(controlConfig.mtpa) === 1 ? 'ENABLED' : 'DISABLED'}</span>
          <ToggleButton
              value={parseInt(controlConfig.mtpa)}
              onChange={(value: number) => { editControlConfig('mtpa', String(value)) }}
          />
          </div>
        </FormGroup>

        {/* Flux Weakening */}
        <FormGroup
            label="Flux Weakening"
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span style={{ fontWeight: 'bold', textShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)' }}>{parseInt(controlConfig.fluxWeakening) === 1 ? 'ENABLED' : 'DISABLED'}</span>
          <ToggleButton
              value={parseInt(controlConfig.fluxWeakening)}
              onChange={(value: number) => { editControlConfig('fluxWeakening', String(value)) }}
          />
          </div>
        </FormGroup>

        {/* INPUT STEPS OR MAPPING */}
        { !isControlModePosition? (isDisplayingPhysicalUnits ?
          <FormGroup
            label={ stepFieldLabel }
          >
            <NumericInput
              fill={true}
              value={ inputConfig[stepField!] }
              onValueChange={(_, valStr) =>
                editInputConfig(stepField!, valStr)
              }
              stepSize={ 1e-15 /* accept any floating point */ }
              minorStepSize={ null }
              buttonPosition="none"
              placeholder={ stepFieldLabel }
              style={{
                backgroundColor: Colors.DARK_GREEN,
                color: Colors.WHITE,
              }}
              disabled={ stepField === undefined }
            />
          </FormGroup>
          :
          <FormGroup
            label="Input Mapping"
            labelInfo={<span style={{ color: Colors.RED }}>*</span>}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ flex: 1, marginRight: 8 }}>
                <NumericInput
                  fill={true}
                  value={inputConfig.inputLimitLower}
                  onValueChange={(_, valStr) =>
                    editInputConfig(
                      "inputLimitLower",
                      valStr.replaceAll(/[.-]/g, "")
                    )
                  }
                  stepSize={ 1e-15 /* accept any floating point for now; integer validation happens later */ }
                  minorStepSize={ null }
                  buttonPosition="none"
                  placeholder="Lower"
                  style={{
                    backgroundColor: Colors.DARK_GREEN,
                    color: Colors.WHITE,
                  }}
                />
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 16,
                  color: lowerGreaterThanCenter ? Colors.RED : undefined,
                }}
              >
                {"<="}
              </div>
              <div style={{ flex: 1, marginLeft: 8, marginRight: 8 }}>
                <NumericInput
                  fill={true}
                  value={inputConfig.inputLimitCenter}
                  onValueChange={(_, valStr) =>
                    editInputConfig(
                      "inputLimitCenter",
                      valStr.replaceAll(/[.-]/g, "")
                    )
                  }
                  stepSize={ 1e-15 /* accept any floating point for now; integer validation happens later */ }
                  minorStepSize={ null }
                  buttonPosition="none"
                  placeholder="Center"
                  style={{
                    backgroundColor: Colors.DARK_GREEN,
                    color: Colors.WHITE,
                  }}
                />
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 16,
                  color: centerGreaterThanUpper ? Colors.RED : undefined,
                }}
              >
                {"<="}
              </div>
              <div style={{ flex: 1, marginLeft: 8 }}>
                <NumericInput
                  fill={true}
                  value={inputConfig.inputLimitUpper}
                  onValueChange={(_, valStr) =>
                    editInputConfig(
                      "inputLimitUpper",
                      valStr.replaceAll(/[.-]/g, "")
                    )
                  }
                  stepSize={ 1e-15 /* accept any floating point for now; integer validation happens later */ }
                  minorStepSize={ null }
                  buttonPosition="none"
                  placeholder="Upper"
                  style={{
                    backgroundColor: Colors.DARK_GREEN,
                    color: Colors.WHITE,
                  }}
                />
              </div>
            </div>
          </FormGroup>):null
        }
        { selectedControlMode !== ControlMode.POSITION.toString() ?
          <ThrottleRangeDisplay
            powerConfig={ powerConfig }
            controlConfig={ controlConfig }
            inputConfig={ inputConfig }
          /> : null
        }
      </div>
      <div style={{ display: "flex", width: FORM_WIDTH }}>
        <div style={{ flex: 1 }}>
          <Button
            backgroundColor={Colors.WHITE}
            textColor={Colors.BLACK}
            onClick={prev}
            disabled={prev === undefined}
          >
            Back
          </Button>
        </div>
        <div style={{ width: 8 }} />
        <div style={{ flex: 1 }} />
        <div style={{ width: 8 }} />
        <div style={{ flex: 1 }}>
          <Button
            backgroundColor={Colors.BUTTON_GREEN}
            textColor={Colors.WHITE}
            onClick={next}
            disabled={next === undefined}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

const PowerScreen = (
  props: INavigationProps & {
    powerConfig: Strings<IWritePowerConfigResponse>;
    editPowerConfig: Edit<IWritePowerConfigResponse>;
  }
) => {
  const { powerConfig, editPowerConfig, prev, next } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: Colors.WHITE,
      }}
    >
      <div
        style={{
          display: "flex",
          fontSize: 24,
          color: Colors.WHITE,
          fontWeight: "bold",
          marginBottom: 16,
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        Hardware Limits
      </div>
      <div style={{ width: FORM_WIDTH, maxWidth: "100%" }}>
        <FormGroup
          label={
            <span>
              Battery Current Limit{" "}
              <span style={{ opacity: 0.6, fontSize: 12 }}>(amps)</span>
            </span>
          }
          labelInfo={<span style={{ color: Colors.RED }}>*</span>}
        >
          <NumericInput
            fill={true}
            value={powerConfig.batteryCurrentLimit}
            onValueChange={(_, valStr) =>
              editPowerConfig("batteryCurrentLimit", valStr)
            }
            stepSize={ 1e-15 /* accept any floating point */ }
            minorStepSize={ null }
            buttonPosition="none"
            style={{ backgroundColor: Colors.DARK_GREEN, color: Colors.WHITE }}
          />
        </FormGroup>
        <FormGroup
          label={
            <span>
              Motor Current Limit{" "}
              <span style={{ opacity: 0.6, fontSize: 12 }}>(amps)</span>
            </span>
          }
          labelInfo={<span style={{ color: Colors.RED }}>*</span>}
        >
          <NumericInput
            fill={true}
            value={powerConfig.motorCurrentLimit}
            onValueChange={(_, valStr) =>
              editPowerConfig("motorCurrentLimit", valStr)
            }
            stepSize={ 1e-15 /* accept any floating point */ }
            minorStepSize={ null }
            buttonPosition="none"
            style={{ backgroundColor: Colors.DARK_GREEN, color: Colors.WHITE }}
          />
        </FormGroup>
        <FormGroup
          label={
            <span>
              Overtemperature Threshold{" "}
              <span style={{ opacity: 0.6, fontSize: 12 }}>(celsius)</span>
            </span>
          }
          labelInfo={<span style={{ color: Colors.RED }}>*</span>}
        >
          <NumericInput
            fill={true}
            value={powerConfig.overtemperatureThreshold}
            onValueChange={(_, valStr) =>
              editPowerConfig("overtemperatureThreshold", valStr)
            }
            stepSize={ 1e-15 /* accept any floating point */ }
            minorStepSize={ null }
            buttonPosition="none"
            style={{ backgroundColor: Colors.DARK_GREEN, color: Colors.WHITE }}
          />
        </FormGroup>
      </div>
      <div style={{ display: "flex", width: FORM_WIDTH }}>
        <div style={{ flex: 1 }}>
          <Button
            backgroundColor={Colors.WHITE}
            textColor={Colors.BLACK}
            onClick={prev}
            disabled={prev === undefined}
          >
            Back
          </Button>
        </div>
        <div style={{ width: 8 }} />
        <div style={{ flex: 1 }} />
        <div style={{ width: 8 }} />
        <div style={{ flex: 1 }}>
          <Button
            backgroundColor={Colors.BUTTON_GREEN}
            textColor={Colors.WHITE}
            onClick={next}
            disabled={next === undefined}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

interface INavigationProps {
  setEnableNavigation: (enable: boolean) => void;
  next: (() => void) | undefined;
  prev: (() => void) | undefined;
}

export const CreateConfiguration = (props: {
  configuration?: Configuration;
  exit: () => void;
}) => {
  const { fetchConfigurations, selectConfiguration } = useMotorConfigurations();
  const { configuration: selectedConfiguration, exit } = props;
  const { user } = useAuth();
  const [navigationDisabled, setNavigationDisabled] = useState<boolean>(false);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const { connectedDevice, applyConfiguration } = useDevices();
  const currentStep = configurationSteps[currentStepIndex]; // The current tab we are on
  const [ warningContents, setWarningContents ] = useState<Map<ConfigurationStep, ReactNode>>( new Map() ); // HACK: this isn't very Reacty

  const [details, setDetails] = useState<Strings<IMotorConfigurationDetails>>(
    strings(
      selectedConfiguration?.data.details || {
        name: "",
        motorManufacturer: "",
        motorModel: "",
        notes: "",
      }
    )
  );

  // For param detection
  const [manualParamInput, setManualParamInput] = useState<boolean>(false);
  const [paramDetect, setParamDetect] = useState<
    | Partial<IStartParamDetectRequest> & IStartParamDetectResponse
    | undefined
  >(selectedConfiguration?.data.parameters);
  const [manualParamDetect, setManualParamDetect] = useState<
    Strings<IStartParamDetectResponse>
  >(
    strings({
      resistance: 0,
      ld: 0,
      lq: 0,
      fluxLinkage: 0,
    })
  );
  const initialUserSpecifiedParams: Strings<IStartParamDetectRequest> = strings(
    selectedConfiguration?.data.parameters
      ? {
        paramDetectMaxCurrent:
          selectedConfiguration.data.parameters.paramDetectMaxCurrent ?? "",
        paramDetectMaxRpm:
          selectedConfiguration.data.parameters.paramDetectMaxRpm ?? "",
        }
      : { paramDetectMaxCurrent: "", paramDetectMaxRpm: "" }
  );
  const [userSpecifiedParams, setUserSpecifiedParams] = useState<Strings<IStartParamDetectRequest>>(initialUserSpecifiedParams);

  const upgradedInputConfig = selectedConfiguration?.data.inputConfig && {
    rpmStep: DEFAULT_RPM_STEP,
    ampsStep: DEFAULT_AMPS_STEP,
    voltsStep: DEFAULT_VOLTS_STEP,
    ...selectedConfiguration.data.inputConfig,
  };
  let inputConfigStr = strings(
      upgradedInputConfig || {
        inputMode: "",
        inputLimitLower: "",
        inputLimitCenter: "",
        inputLimitUpper: "",
        alwaysOn: "0",
        rpmStep: DEFAULT_RPM_STEP.toString(),
        ampsStep: DEFAULT_AMPS_STEP.toString(),
        voltsStep: DEFAULT_VOLTS_STEP.toString(),
      }
    );

  // It is necessary to convert manual input limits from NaN to empty string in case input mode was originally USB.
  if ( !isFinite( parseInt( inputConfigStr.inputLimitLower, 10) ) ) {
    inputConfigStr.inputLimitLower = "";
  }
  if ( !isFinite( parseInt( inputConfigStr.inputLimitCenter, 10) ) ) {
    inputConfigStr.inputLimitCenter = "";
  }
  if ( !isFinite( parseInt( inputConfigStr.inputLimitUpper, 10) ) ) {
    inputConfigStr.inputLimitUpper = "";
  }

  const [inputConfig, setInputConfig] = useState<Strings<InputConfig>>( inputConfigStr );
  const [controlConfig, setControlConfig] = useState<
    Strings<IWriteControlConfigRequest>
  >(
    strings(
      selectedConfiguration?.data.controlConfig || {
        motorMaxSpeed: "",
        polePairCount: "",
        speedControlProportionalGain: DEFAULT_SPEED_P_GAIN_NMPSPMKRAD.toString(),
        speedControlIntegralGain: DEFAULT_SPEED_I_GAIN_NMPMKRAD.toString(),
        deprecatedPositionControlProportionalGain: "0",
        controlMode: ControlMode.TORQUE.toString(), // "0"
        mtpa: Mtpa.DISABLE.toString(), // "0"
        fluxWeakening: FluxWeakening.DISABLE.toString(), // "0" 
      }
    )
  );
  const [positionControlSettings, setPositionControlSettings] = useState<
    Strings<IWritePositionControlSettingsRequest>
  >(
    strings(
      selectedConfiguration?.data.positionControlSettings || {
        positionControlProportionalGain: "0",
        positionControlIntegralGain: "0",
        positionControlDerivGain: "0",
        positionControlMaxSpeed: "0",
        positionControlMaxAccel: "0",
        positionControlDeadband: "0",
        positionControlType: PositionType.UNKNOWN,
        positionUnits: PositionUnits.UNKNOWN,
        positionResolution: PositionResolution.UNKNOWN,
        travelDistance: "0",
      }
    )
  );
  const [powerConfig, setPowerConfig] = useState<
    Strings<IWritePowerConfigResponse>
  >(
    strings(
      selectedConfiguration?.data.powerConfig || {
        batteryCurrentLimit: "",
        motorCurrentLimit: "",
        overtemperatureThreshold: "",
        // Unused
        criticalOvervoltageThreshold: "",
        criticalUndervoltageThreshold: "",
        overcurrentThresholdDeprecated: "",
        warningOvervoltageThreshold: "",
        warningUndervoltageThreshold: "",
        warningMotorCurrentThreshold: "",
        criticalMotorCurrentThreshold: "",
      }
    )
  );
  const [sensorsConfig, setSensorsConfig] = useState<
    Strings<SensorsConfig>
  >(
      strings(
        selectedConfiguration?.data.sensorsConfig || {
          sensorMode: DEFAULT_SENSOR_MODE.toString(), // "2"
          encoderCpr: "0",
          accelerationRamp: "0", // Deprecated; elrad/s^2
          accelerationRamp_mkradps2: DEFAULT_OPEN_LOOP_ACCEL_MKRADPS2.toString(), // mech. rad/s^2
        }
      )
  );

  const detailsValid = useMemo(() => {
    return details.motorManufacturer !== "" && details.motorModel !== "";
  }, [details]);
  const manualParamDetectValid = useMemo(() => {
    const { resistance, ld, lq, fluxLinkage } = manualParamDetect;
    if ([resistance, ld, lq, fluxLinkage].some((v) => v === "0")) {
      return false;
    }

    return true;
  }, [manualParamDetect]);
  const paramDetectValid = paramDetect !== undefined || manualParamDetectValid;
  const sensorsValid = useMemo(() => {
    return sensorsAreValid( sensorsConfig );
  }, [sensorsConfig]);

  const inputConfigValid = useMemo(() => {
    return (
      throttleErrors( inputConfig, controlConfig ).length === 0
    );
  }, [ inputConfig, controlConfig ]);

  const powerConfigValid = useMemo(() => {
    const { batteryCurrentLimit, motorCurrentLimit, overtemperatureThreshold } =
      powerConfig;
    if ( [batteryCurrentLimit, motorCurrentLimit].some( (v) => !paramIsNonNegativeNumber( v ) ) ) {
      return false;
    }
    if ( !paramIsNumber( overtemperatureThreshold ) ) {
      return false;
    }

    return true;
  }, [powerConfig]);

  const tabs = useMemo(() => {
    return configurationSteps.map((step, index) => {
      let isValid: boolean, title: string;
      switch (step) {
        case ConfigurationStep.Details:
          isValid = detailsValid;
          title = "Details";
          break;
        case ConfigurationStep.Throttle:
          isValid = inputConfigValid;
          title = "Throttle";
          break;
        case ConfigurationStep.Power:
          isValid = powerConfigValid;
          title = "Limits";
          break;
        case ConfigurationStep.ParamDetect:
          isValid = paramDetectValid;
          title = "Parameters";
          break;
        case ConfigurationStep.Sensors:
          isValid = sensorsValid;
          title = "Sensors";
          break;
      }

      return (
        <Fragment key={"step-" + step.toString() + "-tab"}>
          <div
            style={{
              flex: 1,
              display: "flex",
              fontSize: 16,
              color: Colors.WHITE,
              backgroundColor: isValid
                ? Colors.BUTTON_GREEN
                : Colors.DARK_GREEN,
              opacity: step === currentStep ? 1 : 0.5,
              justifyContent: "center",
              alignItems: "center",
              padding: 8,
              cursor: (saveInProgress || navigationDisabled) ? "not-allowed" : "pointer",
            }}
            onClick={
              (saveInProgress || navigationDisabled) ? undefined : () => setCurrentStepIndex(index)
            }
          >
            {title}
            {isValid ? (
              <FontAwesomeIcon
                style={{ marginLeft: 4 }}
                icon={faCheck}
                color={Colors.WHITE}
              />
            ) : null}
          </div>
          {index === configurationSteps.length - 1 ? null : (
            <div
              style={{ height: "100%", width: 1, backgroundColor: "#000000" }}
            />
          )}
        </Fragment>
      );
    });
  }, [
    detailsValid,
    inputConfigValid,
    powerConfigValid,
    currentStep,
    saveInProgress,
    navigationDisabled,
    paramDetectValid,
    sensorsValid,
  ]);

  const lastIndex = configurationSteps.length - 1;
  const next =
    saveInProgress || navigationDisabled || currentStepIndex === lastIndex
      ? undefined
      : () => setCurrentStepIndex((v) => v + 1);
  const prev =
    saveInProgress || navigationDisabled || currentStepIndex === 0
      ? undefined
      : () => setCurrentStepIndex((v) => v - 1);

  const navigationProps: INavigationProps = {
    setEnableNavigation: (v: boolean) => setNavigationDisabled(!v),
    next,
    prev,
  };

  const editDetails: Edit<IMotorConfigurationDetails> = (key, value) =>
    setDetails((d) => ({ ...d, [key]: value }));
  const editInputConfig: Edit<InputConfig> = (key, value) =>
    setInputConfig((c) => ({ ...c, [key]: value }));
  const editControlConfig: Edit<IWriteControlConfigRequest> = (key, value) =>
    setControlConfig((c) => ({ ...c, [key]: value }));
  const editPositionControlSettings: Edit<IWritePositionControlSettingsRequest> = (key, value) =>
    setPositionControlSettings((c) => ({ ...c, [key]: value }));
  const editPowerConfig: Edit<IWritePowerConfigResponse> = (key, value) =>
    setPowerConfig((c) => ({ ...c, [key]: value }));
  const editParamConfig: Edit<IStartParamDetectResponse> = (key, value) =>
    setManualParamDetect((c) => ({ ...c, [key]: value }));
  const editUserSpecifiedParams: Edit<IStartParamDetectRequest> = (key, value) =>
    setUserSpecifiedParams((c) => ({ ...c, [key]: value }));
  const editSensorsConfig: Edit<IWriteSensorModeResponse & IWriteOpenLoopConfigResponse> = (key, value) =>
    setSensorsConfig((c) => ({ ...c, [key]: value }));

  const configuration: DatabaseRecord[CollectionId.Configurations] | undefined = useMemo(() => {
    if ( !user || !(manualParamInput || paramDetect) ) {
      return;
    }

    return {
      userId: user.uid,
      details: {
        name: configurationName(
          details.name,
          details.motorManufacturer,
          details.motorModel
        ),
        motorManufacturer: details.motorManufacturer,
        motorModel: details.motorModel,
        notes: details.notes,
      },
      parameters: manualParamInput
        ? {
            resistance: parseFloat(manualParamDetect.resistance),
            ld: parseFloat(manualParamDetect.ld),
            lq: parseFloat(manualParamDetect.lq),
            fluxLinkage: parseFloat(manualParamDetect.fluxLinkage),
            paramDetectMaxCurrent: parseFloat(
              userSpecifiedParams.paramDetectMaxCurrent
            ),
            paramDetectMaxRpm: parseFloat(
              userSpecifiedParams.paramDetectMaxRpm
            ),
          }
        : {
            ...paramDetect!,
            paramDetectMaxCurrent: parseFloat(
              userSpecifiedParams.paramDetectMaxCurrent
            ),
            paramDetectMaxRpm: parseFloat(
              userSpecifiedParams.paramDetectMaxRpm
            ),
          },
      inputConfig: {
        inputMode: parseInt(inputConfig.inputMode, 10),
        inputLimitLower: parseInt(inputConfig.inputLimitLower, 10), // May be NaN. Handled elsewhere
        inputLimitCenter: parseInt(inputConfig.inputLimitCenter, 10), // May be NaN. Handled elsewhere
        inputLimitUpper: parseInt(inputConfig.inputLimitUpper, 10), // May be NaN. Handled elsewhere
        alwaysOn: parseInt(inputConfig.alwaysOn, 10),
        // ToDo: better way of doing the following -- defaults are specified twice (see useState for inputConfig, above)
        rpmStep: ( inputConfig.rpmStep !== undefined ) ? parseFloat( inputConfig.rpmStep ) : DEFAULT_RPM_STEP,
        ampsStep: ( inputConfig.ampsStep !== undefined ) ? parseFloat( inputConfig.ampsStep ) : DEFAULT_AMPS_STEP,
        voltsStep: ( inputConfig.voltsStep !== undefined ) ? parseFloat( inputConfig.voltsStep ) : DEFAULT_VOLTS_STEP,
      },
      controlConfig: {
        motorMaxSpeed: parseFloat(controlConfig.motorMaxSpeed),
        polePairCount: parseFloat(controlConfig.polePairCount),
        speedControlProportionalGain: parseFloat(
          controlConfig.speedControlProportionalGain
        ),
        speedControlIntegralGain: parseFloat(
          controlConfig.speedControlIntegralGain
        ),
        deprecatedPositionControlProportionalGain: parseFloat(
          controlConfig.deprecatedPositionControlProportionalGain
        ),
        controlMode: parseInt(controlConfig.controlMode, 10),
        mtpa: parseInt(controlConfig.mtpa, 10),
        fluxWeakening: parseInt(controlConfig.fluxWeakening, 10),
      },
      positionControlSettings: {
        positionControlProportionalGain: parseFloat(positionControlSettings.positionControlProportionalGain),
        positionControlIntegralGain: parseFloat(positionControlSettings.positionControlIntegralGain),
        positionControlDerivGain: parseFloat(positionControlSettings.positionControlDerivGain),
        positionControlMaxSpeed: parseFloat(positionControlSettings.positionControlMaxSpeed),
        positionControlMaxAccel: parseFloat(positionControlSettings.positionControlMaxAccel),
        positionControlDeadband: parseFloat(positionControlSettings.positionControlDeadband),
        positionControlType: parseInt(positionControlSettings.positionControlType, 10),
        positionUnits: parseInt(positionControlSettings.positionUnits, 10),
        positionResolution: parseInt(positionControlSettings.positionResolution, 10),
        travelDistance: parseFloat(positionControlSettings.travelDistance),
      },
      powerConfig: {
        // Keep in sync with param detect
        batteryCurrentLimit: parseFloat(powerConfig.batteryCurrentLimit),
        motorCurrentLimit: parseFloat(powerConfig.motorCurrentLimit),
        overtemperatureThreshold: parseFloat(
          powerConfig.overtemperatureThreshold
        ),
        criticalOvervoltageThreshold: 0,
        criticalUndervoltageThreshold: 0,
        overcurrentThresholdDeprecated: 0,
        warningOvervoltageThreshold: 0,
        warningUndervoltageThreshold: 0,
        warningMotorCurrentThreshold: 0,
        criticalMotorCurrentThreshold: 0,
      },
      sensorsConfig: {
        sensorMode: parseInt(sensorsConfig.sensorMode, 10),
        encoderCpr: parseInt(sensorsConfig.encoderCpr, 10),
        accelerationRamp: parseFloat(sensorsConfig.accelerationRamp), // Deprecated; elrad/s^2
        accelerationRamp_mkradps2: ( sensorsConfig.accelerationRamp_mkradps2 !== undefined ) ?
                                      parseFloat(sensorsConfig.accelerationRamp_mkradps2) :
                                      DEFAULT_OPEN_LOOP_ACCEL_MKRADPS2, // mech. rad/s^2; replaces `accelerationRamp`
      }
    }
  }, [
    user,
    controlConfig,
    positionControlSettings,
    details,
    inputConfig,
    manualParamDetect,
    paramDetect,
    powerConfig,
    sensorsConfig,
    manualParamInput,
    userSpecifiedParams,
  ]);

  // Accepts an exit callback, and an optional array of keys to do partial Controller updates.
  const save = useCallback(async (exit: () => void, partial?: Array<keyof DatabaseRecord[CollectionId.Configurations]>) => {
    if (!user) {
      return;
    }

    let id: string | undefined; // New configuration ID, if one was created
    setSaveInProgress(true);
    try {
      // ToDo: rework the special cases for `partial`
      try {
        if (selectedConfiguration === undefined) {
          id = await addRecord(CollectionId.Configurations, configuration!);
          if ( connectedDevice?.initialized && partial !== undefined ) {
            await applyConfiguration?.({
              id,
              data: configuration!,
            }, partial);
          }
        } else {
          await updateRecord(
            CollectionId.Configurations,
            selectedConfiguration.id,
            configuration!
          );
          if (
            connectedDevice?.initialized &&
            (
              connectedDevice.activeConfigurationId === selectedConfiguration.id ||
                partial !== undefined
            )
          ) {
            await applyConfiguration?.({
              id: selectedConfiguration.id,
              data: configuration!,
            }, partial);
          }
        }
      } finally {
        await fetchConfigurations();
        if ( id !== undefined ) {
          // A configuration was created in Firestore. Select it
          selectConfiguration( id );
        }
      }
      setSaveInProgress(false);
      exit();
    } catch (e) {
      console.warn("Config Save", e);
      setSaveInProgress(false);
    }
  }, [
    user,
    selectedConfiguration,
    selectConfiguration,
    fetchConfigurations,
    connectedDevice,
    applyConfiguration,
    configuration,
  ]);

  const saveSensorSettings = useCallback(() => {
    let noExit = (() => null);
    save(noExit, ['sensorsConfig'])
  }, [save]);

  const savePositionControlSettings = useCallback(() => {
    let noExit = (() => null);
    save(noExit, ['positionControlSettings'])
  }, [save]);

  const setWarningContentsCurrentStep = useCallback(( contents: ReactNode ) => {
    setWarningContents( ( oldContentsMap: Map<ConfigurationStep, ReactNode> ) => {
      if ( oldContentsMap.get( currentStep ) === contents ) {
        // Unchanged, exit early
        return oldContentsMap;
      }
      const newContentsMap = new Map( oldContentsMap ); // Clone map
      newContentsMap.set( currentStep, contents );
      return newContentsMap;
    } );
  }, [ currentStep, setWarningContents ]);

  let content;
  switch (currentStep) {
    case ConfigurationStep.Details:
      content = (
        <DetailsScreen
          {...navigationProps}
          details={details}
          editDetails={editDetails}
        />
      );
      break;
    case ConfigurationStep.Throttle:
      content = (
        <InputScreen
          {...navigationProps}
          inputConfig={inputConfig}
          powerConfig={powerConfig}
          editInputConfig={editInputConfig}
          controlConfig={controlConfig}
          positionControlSettings={positionControlSettings}
          editControlConfig={editControlConfig}
          editPositionControlSettings={editPositionControlSettings}
          setWarningContents={setWarningContentsCurrentStep}
          savePositionControlSettings={savePositionControlSettings}
        />
      );
      break;
    case ConfigurationStep.Power:
      content = (
        <PowerScreen
          {...navigationProps}
          powerConfig={powerConfig}
          editPowerConfig={editPowerConfig}
        />
      );
      break;
    case ConfigurationStep.ParamDetect:
      content = (
        <ParamDetectScreen
          {...navigationProps}
          paramDetect={paramDetect}
          setParamDetect={setParamDetect}
          setManualParamInput={setManualParamInput}
          manualParamInput={manualParamInput}
          manualParamDetect={manualParamDetect}
          editParamConfig={editParamConfig}
          userSpecifiedParams={userSpecifiedParams}
          editUserSpecifiedParams={editUserSpecifiedParams}
          powerConfig={powerConfig}
          powerConfigValid={powerConfigValid}
          polePairCount={parseFloat(controlConfig.polePairCount)}
        />
      );
      break;
    case ConfigurationStep.Sensors:
      content = (
        <SensorsScreen
          {...navigationProps}
          sensorsConfig={sensorsConfig}
          editSensorsConfig={editSensorsConfig}
          saveSensorSettings={saveSensorSettings}
          saveInProgress={saveInProgress}
          setWarningContents={setWarningContentsCurrentStep}
        />
      );
      break;
  }

  let warningContentsThisStep = warningContents.get( currentStep );

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <div style={{ display: "flex", marginBottom: 32 }}>{tabs}</div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", top: -16, left: 32 }}>
          <ClickableIcon
            icon={faXmark}
            color={Colors.WHITE}
            onClick={exit}
            size="2x"
            style={{ padding: 8 }}
            disabled={saveInProgress || navigationDisabled}
          />
        </div>
        {content}
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 16 }}
        >
          <Button
            style={{ width: FORM_WIDTH }}
            backgroundColor={Colors.BUTTON_GREEN}
            textColor={Colors.WHITE}
            onClick={() => save(exit)}
            disabled={
              [
                detailsValid,
                paramDetectValid,
                inputConfigValid,
                powerConfigValid,
                sensorsValid,
              ].some((v) => !v) || saveInProgress || navigationDisabled
            }
          >
            Save Configuration
          </Button>
        </div>
        <div
          style= {{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            marginTop: 16,
            visibility: warningContentsThisStep ? "visible" : "hidden",
          }}
        >
          <div style={{ flex: 1, width: 1.35 * FORM_WIDTH }}>
            <Callout intent={ Intent.WARNING } >
              {warningContentsThisStep}
            </Callout>
          </div>
        </div>
      </div>
    </div>
  );
};
